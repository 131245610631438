import styled from '@emotion/styled';
import CarCard from '@/components/ui/card/CarCard';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useFetchSearch } from '@/hooks/useCarData';
import { SelectBox } from '../selectbox';
import { useState } from 'react';
import { useAccordionData } from '@/hooks/useAccordionData';
import { MainPagenation } from '../pagination';
import { LoadingComponent, NoResultsComponent } from '@/components/shared/status';

const CardContainer = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const selections = Object.fromEntries(searchParams); //   //[key, value] 객체로 변환, selections 현재선택된 키와 값들임
  const { data: cars, isLoading } = useFetchSearch(selections); // cars안에 curPage, list, totPage가 있음.

  const { updateSelection } = useAccordionData();
  const [page, setPage] = useState(1);
  const location = useLocation();

  const navigateToDetailHandler = (inboundNo) => {
    navigate(`/car/${inboundNo}`, { state: { from: `${location.pathname}${location.search}` } });
  };
  return (
    <Container>
      <SelectBox />
      {isLoading ? (
        <LoadingComponent />
      ) : cars?.list?.length === 0 ? (
        <NoResultsComponent />
      ) : (
        <>
          <Wrapper>
            {cars.list.map((car) => (
              <CarCard
                key={car.inboundNo}
                car={car}
                onClick={() => navigateToDetailHandler(car.inboundNo)}
              />
            ))}
          </Wrapper>

          <MainPagenation
            arr={cars}
            page={page}
            setPage={setPage}
            updateSelection={updateSelection}
          />
        </>
      )}
    </Container>
  );
};

export default CardContainer;

const Container = styled.div(() => ({
  width: '100%',
  maxWidth: '104rem',
  margin: '0 auto',
}));

const Wrapper = styled.div(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: '2rem',
  padding: '2.2rem 2rem',
}));
