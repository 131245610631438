import styled from '@emotion/styled';
import React from 'react';
import arrowTop from '@/assets/icons/arrow-top.svg';
import { useNavigate } from 'react-router-dom';
import { AlertComponent } from '../alert';
import useAlert from '@/hooks/useAlert';
import { TranslatedTexts } from '../texts';
import { useUserContext } from '@/context/AuthContext';

const SidebarContainer = styled.div(({ theme }) => ({
  position: 'fixed',
  top: '100px',
  right: '0',
  width: '90px',
  height: 'calc(100vh - 50px);',
  backgroundColor: theme.color.grey08,
  borderLeft: `1px solid ${theme.color.grey04}`,
  zIndex: '2',
}));

const Nav = styled.nav(({ theme }) => ({
  marginTop: '80px',
  display: 'flex',
  flexDirection: 'column',
  height: '320px',
  '> div:first-of-type': {
    borderTop: `1px solid ${theme.color.grey04}`,
  },
}));

const Item = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  fontSize: '11px',
  height: '80px',
  borderBottom: `1px solid ${theme.color.grey04}`,
  padding: '26px 18px 26px 18px',
  lineHeight: '1.41',
}));

const TopBtn = styled.button(({ theme }) => ({
  backgroundColor: theme.color.grey04,
  width: '36px',
  height: '36px',
  borderRadius: '30px',
  padding: '10px',
  marginBottom: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const RightSidebar = () => {
  const { showAlert, handleShowAlert, alertMessage, handleCloseAlert } = useAlert();

  const { isAuthenticated } = useUserContext();

  const navigate = useNavigate();

  const handlePurchaseQuoteClick = () => {
    if (isAuthenticated) {
      navigate('/mypage');
    } else {
      handleShowAlert('login_required');
      setTimeout(() => navigate('/login'), 1000);
    }
  };

  const handlePartOrderClick = () => {
    handleShowAlert('alert_preparing');
  };

  const handleAlertConfirm = () => {
    handleCloseAlert();
    if (!isAuthenticated) {
      navigate('/login');
    }
  };

  const scrollToTopHandler = () => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <SidebarContainer>
      <Nav>
        <Item>
          <button onClick={() => navigate('/vehicle-request-list')}>
            <TranslatedTexts id="requested_vehicle" />
          </button>
        </Item>
        <Item>
          <button onClick={handlePurchaseQuoteClick}>
            <TranslatedTexts id="purchase" defaultMessage="Purchase" />
            <p>
              <TranslatedTexts id="quote" defaultMessage="Quote" />
            </p>
          </button>
        </Item>

        <Item>
          <button onClick={handlePartOrderClick}>
            <TranslatedTexts id="part_order" />
          </button>
        </Item>
        <Item>
          <TopBtn onClick={scrollToTopHandler}>
            <img src={arrowTop} alt="arrowTop" />
          </TopBtn>
          <TranslatedTexts id="top" />
        </Item>
      </Nav>
      <AlertComponent
        open={showAlert}
        message={alertMessage}
        severity="info"
        onClose={handleCloseAlert}
        onConfirm={handleAlertConfirm}
      />
    </SidebarContainer>
  );
};

export default RightSidebar;
