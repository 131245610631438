import React from 'react';
import styled from '@emotion/styled';

const AgreementPage = () => {
  return (
    <Container>
      <Content>
        <h1>PRIVACY</h1>
        <br />

        <p>
          Korea-Carmarket Service Terms of Use
          <br /> Korea-Carmarket Co., Ltd.'s Terms of Use contains the following contents. Article 1
          [Purpose)
        </p>
        <p>
          <br />
          Article 2 (Definition)
          <br /> Article 3 (Specification, explanation and revision of terms and conditions, etc.)
          <br /> Article 4 (Provision and change of services)
          <br /> Article 5 (Service interruption)
          <br /> Article 6 (Membership Registration)
          <br /> Article 7 (Withdrawal of membership, loss of qualification, etc.)
          <br /> Article 8 (Notification to members)
          <br /> Article 9 (Service usage fees, etc.)
          <br /> Article 10 (&ldquo;Member&rsquo;s&rdquo; individual service contract)
          <br /> Article 11 (Consent to protection of personal information and provision of personal
          information)
          <br /> Article 12 (Obligations of &ldquo;Company&rdquo;)
          <br /> Article 13 (Obligations of &ldquo;Members&rdquo;)
          <br /> Article 14 (Obligations regarding member ID and password)
          <br /> Article 15 (Obligations of &ldquo;General Members&rdquo; and &ldquo;Dealer
          Members&rdquo;)
          <br /> Article 16 (Withdrawal of subscription, etc.)
          <br /> Article 17 (Relationship with linked websites)
          <br /> Article 18 (Copyright attribution and use restrictions)
          <br /> Article 19 (Indemnification of &ldquo;Company&rdquo;, etc.)
          <br /> Article 20 (Dispute Resolution)
          <br /> Article 21 (Jurisdiction and Governing Law)
          <br />
          <br /> Supplementary provisions
          <br />
          <br /> Article 1 [Purpose)
          <br /> These Terms and Conditions refer to the Korea-Carmarket website (regardless of
          whether it is wired or wireless and the type of terminal that can be accessed) operated by
          Korea-Carmarket Co., Ltd. (hereinafter referred to as the &ldquo;Company&rdquo;), and any
          Internet-related services provided by it
          <br /> (hereinafter referred to as &ldquo;the Company&rdquo;). Defines the rights,
          obligations and responsibilities necessary for using the service (referred to as
          &ldquo;service&rdquo;)
          <br /> The purpose is to do this. Article 2<br /> (Definition)
          <br /> ÿ &ldquo;Korea-Carmarket&rdquo; refers to the listing and price information
          exchange platform service provided by the &ldquo;Company&rdquo; (online car sales
          information service)
          <br /> (including e-commerce)
          <br /> ÿ &ldquo;User&rdquo; means a person who accesses &ldquo;Korea-Carmarket&rdquo; and
          receives services provided by &ldquo;Korea-Carmarket&rdquo; in accordance with these Terms
          and Conditions.
          <br /> Won&rdquo; (&ldquo;General Member&rdquo; and &ldquo;Dealer Member&rdquo;)
          <br /> ÿ &ldquo;General member&rdquo; refers to a person who is not a &ldquo;dealer
          member&rdquo; among &ldquo;users&rdquo; and wishes to use services such as vehicle sales
          and vehicle-related information provision through &ldquo;Korea-Carmarket.&rdquo; ÿ
          &ldquo;Dealer member&rdquo; refers to a person who has gone through a separate
          registration
          <br /> process for dealers among &ldquo;users&rdquo; and wishes to use services such as
          purchasing used cars and obtaining used car information through
          &ldquo;Korea-Carmarket&rdquo;.
        </p>
        <br />
        <p>
          Article 3 (Specification, explanation and revision of terms and conditions, etc.)
          <br /> ÿ &ldquo;Company&rdquo; refers to the contents of these terms and conditions,
          company name and representative name, address of business location (including the address
          of the place where consumer complaints can
          <br /> be handled), telephone number, facsimile number, e-mail address, business
          registration number, and communication. The sales business report number, personal
          information manager, etc. are posted on
          <br /> the initial service screen (front) of &ldquo;Korea-Carmarket&rdquo; so that users
          can easily see them. However, the contents of the terms and conditions
          <br /> can be viewed by users through the connection screen. ÿ &ldquo;Company&rdquo;
          refers to the Act on Consumer Protection in Electronic Commerce,
          <br /> etc., the Act on the Regulation of Terms and Conditions, and the Electronic
          Documents and Electronic Transactions Basics Act.
          <br /> Act, ÿElectronic Financial Transactions Actÿ, ÿElectronic Signature Actÿ, ÿAct on
          Promotion of Information and Communications Network Utilization and Information
          Protection, etc.ÿ, ÿAct on Door-to-Door Sales, etc. These terms and conditions may be
          revised to the extent that they do not violate relevant laws such as the Act, the
          Framework Act on Consumers, etc. ÿ
          <br /> When revising the terms and conditions, the &ldquo;Company&rdquo; shall specify the
          date of application and reason for revision and provide the initial date of
          &ldquo;Korea-Carmarket&rdquo; along with the current terms and conditions. Notices will be
          displayed on the screen from 7 days prior to the application date to the day before the
          application date. However, if the terms and conditions are changed to the disadvantage of
          the user,
          <br /> In such cases, notice will be provided with a grace period of at least 30 days. In
          this case, the &ldquo;Company&rdquo; clarifies the contents before and after revision.
        </p>

        <p>
          Comparisons are made so that users can easily understand them. ÿ If the
          <br /> &ldquo;Company&rdquo; revises the terms and conditions, the revised terms and
          conditions will only apply to contracts concluded after the date of application, and the
          terms and conditions before revision will remain applicable to contracts already concluded
          before that date. However, users who have already entered into a contract may
          <br /> If the intention to be subject to the application is sent to the
          &ldquo;Company&rdquo; within the notice period of the revised terms and conditions under
          Paragraph 3 and the consent of the &ldquo;Company&rdquo; is received. The revised terms
          and conditions apply. ÿ Regarding
          <br /> matters not specified in these Terms and Conditions and the interpretation of these
          Terms and Conditions, the Act on Consumer Protection in Electronic Commerce, etc., Terms
          and Conditions
          <br /> We comply with the Act on Regulations, Etc., consumer protection guidelines for
          e-commerce, etc. set by the Fair Trade Commission, and related laws or commercial
          practices. Article 4 (Provision and change of
          <br /> services)
          <br /> ÿ The &ldquo;Company&rdquo; performs the following tasks.
        </p>
        <p>
          1. Information that &ldquo;general members&rdquo; and &ldquo;dealer members&rdquo; who
          wish to buy and sell vehicles through &ldquo;Korea-Carmarket&rdquo; wish to convey (sales
          unit
          <br /> Provides a platform to exchange information (vehicle information, price
          information, etc.)
          <br /> 2. Other tasks determined by the &ldquo;Company&rdquo;
          <br /> ÿ The &ldquo;Company&rdquo; may change the details of the goods or services to be
          provided under a contract to be concluded in the future in the event that the goods or
          services are sold out or the technical specifications are changed. In this case, the
          contents of the changed goods or services and the date of provision will be specified and
          the contents of the current goods or services will be immediately announced where they are
          posted. ÿ Reasons such as out of stock of goods
        </p>
        <p>
          or changes in technical specifications of the service that the &ldquo;Company&rdquo; has
          entered into a contract with the user to provide
          <br /> In case of change, the reason will be immediately notified to the user in any
          possible way. ÿ In the case of the preceding
          <br /> paragraph, the &ldquo;Company&rdquo; will compensate the user for any damages
          suffered thereby. However, this does not apply if it is proven that there was no intention
          or negligence on the part of the &ldquo;Company.&rdquo; Article 5 (Service Suspension)
        </p>

        <br />
        <p>
          ÿ The &ldquo;Company&rdquo; shall provide service in the event of maintenance, inspection,
          replacement, breakdown, or interruption of communication of information and communication
          equipment such as computers and servers. may be temporarily suspended. Article 6
          (Membership Registration)
        </p>
        <br />
        <p>
          ÿ Users become members by filling out their membership information according to the
          registration form set by the &ldquo;Company&rdquo; and expressing their intention to agree
          to these terms and conditions. Apply for membership. ÿ The
          <br /> &ldquo;Company&rdquo; registers users who have applied to become members as
          described in Paragraph 1 as members unless they fall under any of the following items.
          <br /> all.
        </p>
        <p>
          1. If the applicant has previously lost his or her membership pursuant to Article 7,
          Paragraph 3 of these Terms and Conditions, however, if 3 years have passed since the loss
          of membership pursuant to Article 7, Paragraph 3, the &ldquo;Company&rdquo; approves
          membership re-registration. An exception is made if obtained. 2. If there are false
          information,
          <br /> omissions, or errors in the registration information.
          <br /> 3. If it is judged that registering as another member will significantly impede the
          technology of the &ldquo;Company&rdquo;
        </p>
        <p>
          ÿ The time of establishment of the membership contract is when the approval of the
          &ldquo;Company&rdquo; reaches the member. ÿ If there is a<br /> change in the information
          registered at the time of membership registration, the member must notify
          &ldquo;Korea-Carmarket&rdquo; of the change within a reasonable period of time through
          methods such as editing member information. Article 7 (Withdrawal of membership, loss of
          <br /> qualification, etc.)
          <br /> ÿ Members may request withdrawal from the &ldquo;Company&rdquo; at any time, and
          the &ldquo;Company&rdquo; will immediately process membership withdrawal. ÿ If a member
          <br /> falls under any of the following reasons, the &ldquo;Company&rdquo; may restrict or
          suspend membership. However, if a &ldquo;dealer member&rdquo; violates the dealer member
          usage guide, a separate dealer member warning system policy, not this section, will apply.
          1. If false information is registered when applying for membership
        </p>

        <br />
        <p>
          2. If the price of goods purchased using &ldquo;Korea-Carmarket&rdquo; or other debts
          borne by the &ldquo;Member&rdquo; are not paid on the due date.
          <br /> right
          <br /> 3. In case of threatening the order of e-commerce, such as interfering with
          others&rsquo; use of &ldquo;Korea-Carmarket&rdquo; or stealing information.
          <br /> 4. When using &ldquo;Korea-Carmarket&rdquo; to commit an act prohibited by the law
          or these Terms and Conditions or contrary to public order and morals.
          <br /> ÿ After the &ldquo;Company&rdquo; restricts or suspends membership, the same act is
          repeated more than twice or the reason is not corrected within 30 days.
          <br /> If you do so, the &ldquo;Company&rdquo; may revoke your membership. ÿ If the
          &ldquo;Company&rdquo; revokes
          <br /> membership, membership registration will be canceled. In this case, the member will
          be notified of this and given an opportunity to explain within a period of at least 30
          days before cancellation of membership registration. Article 8 (Notification to members)
        </p>

        <p>
          ÿ When the &ldquo;Company&rdquo; notifies a member, it may do so to the e-mail address
          designated by the member in advance by agreement with the &ldquo;Company.&rdquo; ÿ In the
          case of notification to an unspecified number of members, the &ldquo;Company&rdquo; will
          notify them individually by posting it as a &ldquo;Korea-Carmarket&rdquo; notice for more
          than one week.
          <br /> It can be replaced with J. However, individual notification will be provided
          regarding matters that have a significant impact on the member's transactions.
          <br /> all.
        </p>
        <p>
          Article 9 (Service usage fees, etc.)
          <br /> ÿ Service usage fees are in accordance with the information posted by the
          &ldquo;Company&rdquo; in the &ldquo;Korea-Carmarket&rdquo; notice or presented in the
          relevant service usage guide. Services
        </p>
        <p>
          provided by the &ldquo;Company&rdquo; are free unless the usage fee is separately posted
          or presented. Let's do it. ÿ If the &ldquo;Company&rdquo; is unable to deliver the goods
          or
          <br /> provide the service due to reasons such as out of stock of the goods or services
          requested for purchase,
          <br /> The reason is notified to the &ldquo;member&rdquo; without notice, and if payment
          for goods or services is received in advance, contract termination and refund procedures
          will be carried out within 3<br /> days from the date of occurrence of the reason. ÿ In
          the case of &ldquo;dealer
          <br /> members,&rdquo; membership is suspended in accordance with Article 7, Paragraph 2
          for reasons such as non-compliance with business principles, presentation of false
          quotations, unfair price reduction, etc In this case, the amount already paid will not be
          refunded. Article 10
          <br /> (&ldquo;Member&rsquo;s&rdquo; individual service contract)
          <br /> ÿ The &ldquo;Company&rdquo; may not accept a &ldquo;Member&rsquo;s&rdquo;
          application for an individual service contract, such as a purchase request, if it falls
          under any of the following items.
          <br /> all. However, when concluding a contract with a minor, it must be notified that the
          minor or the legal representative may cancel the contract if the consent of the legal
          representative
          <br /> is not obtained. 1. If there are false information, omissions, or errors in the
        </p>
        <p>
          application details.
          <br /> 2. When a minor purchases goods or services prohibited by the Youth Protection Act,
          such as cigarettes or alcohol.
          <br /> 3. When it is judged that approving other purchase requests will significantly
          impede the &ldquo;Company&rsquo;s&rdquo; technology.
          <br /> ÿ The contract is deemed to have been established when the
          &ldquo;Company&rdquo;&rsquo;s approval of the &ldquo;Member&rsquo;s&rdquo; application
          reaches the &ldquo;Member&rdquo; in accordance with Article 8, Paragraph 1. is. ÿ
          <br /> The &ldquo;Company&rsquo;s&rdquo; expression of intent includes confirmation of the
          &ldquo;Member&rsquo;s&rdquo; purchase application, availability of sales, correction or
          cancellation of the purchase application, etc. It must include certain information, etc.
          Article 11
          <br /> (Consent to protection of personal information and provision of personal
          information)
          <br /> ÿ When collecting information from &ldquo;members,&rdquo; the &ldquo;Company&rdquo;
          collects the minimum amount of personal information necessary to provide services. The
          following
          <br /> information is required to be collected, and the rest is subject to optional
          collection. 1. &ldquo;General member&rdquo;
        </p>
        <br />

        <p>- ID</p>
        <p>
          - password
          <br /> - name
          <br /> - Title
          <br /> - Contact information (mobile phone number) 2. &ldquo;Dealer Member&rdquo;
        </p>
        <p>
          - ID
          <br /> - password
          <br /> - name
          <br /> - Title
          <br /> - Company name (agency) and related information
          <br /> - Contact information (mobile phone
          <br /> number) - Email address
          <br /> - Information for issuing cash receipts or tax invoices
          <br /> ÿ In order to protect the personal information of &ldquo;members,&rdquo; the
          &ldquo;Company&rdquo; complies with the Act on Promotion of Information and Communications
          Network Utilization and Information Protection, etc., and personal information protection.
          Comply with relevant laws and regulations. ÿ In
          <br /> order to protect the personal information of &ldquo;members,&rdquo; the
          &ldquo;Company&rdquo; establishes a personal information handling policy and designates a
          personal information protection manager.
          <br /> City and operation. ÿ
          <br /> The &ldquo;Company&rsquo;s&rdquo; personal information handling policy does not
          apply to linked websites other than the &ldquo;Company&rsquo;s&rdquo; official website.
          also
          <br /> The &ldquo;Company&rdquo; is responsible for the handling of personal information
          that occurs in relation to a linked website or purchased products or services by a third
          party.
          <br /> does not bear any burden. ÿ The
          <br /> &ldquo;Company&rdquo; may use the personal information provided by the
          &ldquo;Member&rdquo; for purposes other than the service purpose or use the personal
          information provided by the &ldquo;Member&rdquo; for purposes other than the
          &ldquo;Member&rsquo;s&rdquo; consent.
        </p>

        <p>
          This is not provided to third parties. However, an exception is made in any of the
          following cases. 1. In cases where the use of member information and provision of
          information to third parties is permitted in accordance with laws and regulations
          <br /> 2. When providing the minimum member information required for delivery,
          consignment, cancellation, service reservation, etc.
          <br /> 3. When the user's consent is obtained in accordance with other company terms and
          policies
        </p>
        <p>
          Article 12 (Obligations of &ldquo;Company&rdquo;)
          <br /> ÿ The &ldquo;Company&rdquo; does not engage in any acts prohibited by the law or
          these Terms and Conditions or against public order and morals, and continues to provide
          services in accordance with the provisions of these Terms and Conditions. And we must do
          our best to provide goods and services in a stable manner. ÿ The &ldquo;Company&rdquo;
          provides
          <br /> security to protect users&rsquo; personal information (including credit
          information) so that users can use Internet services safely.
          <br /> You must have a security system in place. ÿ The
          <br /> &ldquo;Company&rdquo; does not send advertising emails for commercial purposes that
          users do not want. Article 13 (Obligations of &ldquo;Members&rdquo;)
        </p>
        <p>
          ÿ &ldquo;Members&rdquo; must not engage in any of the following acts. 1. Application for
          service use,
          <br /> change of information, registration and provision of false information related to
          use of other services
          <br /> 2. Stealing other people&rsquo;s information
          <br /> 3. The act of changing or deleting information written and posted by the
          &ldquo;Company&rdquo; or a third party on &ldquo;Korea-Carmarket&rdquo;
          <br /> 4. Transmission or posting of information (computer programs, etc.) other than that
          specified by the &ldquo;Company&rdquo;
          <br /> 5. Acts that infringe upon the &ldquo;Company&rsquo;s&rdquo; or other third
          parties&rsquo; intellectual property rights such as copyrights, trademark rights, patent
          rights, privacy, other laws, and contractual rights.
        </p>
        <br />
        <p>
          6. Any act that damages the reputation of the &ldquo;Company&rdquo; or a third party or
          interferes with its business.
          <br /> 7. Disclosing, posting or writing on &ldquo;Korea-Carmarket&rdquo; obscene or
          violent words, images, voices or other information that is against public order and
          morals.
        </p>
        <br />
        <p>
          8. Any act that violates laws, court rulings, decisions or orders, or administrative
          measures with binding force under the law, or is likely to undermine public order or
          morals.
        </p>

        <br />
        <p>
          9. Other acts determined by the &ldquo;Company&rdquo; to be inappropriate and announced or
          posted in the form of policies, notices, etc. Article 14 (Obligations regarding member ID
          and password)
          <br /> ÿ Responsibility for managing ID and password lies with the &ldquo;member.&rdquo; ÿ
          &ldquo;Members&rdquo; must not
          <br /> allow third parties to use their ID and password. ÿ If a &ldquo;Member&rdquo;
          becomes aware that his/her ID and password have been stolen or are being used by a third
          party, he/she must immediately notify the &ldquo;Company&rdquo;.
        </p>
        <p>
          If there is any guidance from the &ldquo;Company,&rdquo; you must follow it. Article 15
          (Obligations of
          <br /> &ldquo;General Members&rdquo; and &ldquo;Dealer Members&rdquo;)
          <br />
          <br /> ÿ &ldquo;General members&rdquo; have the obligation to check whether the vehicle
          information uploaded to &ldquo;Korea-Carmarket&rdquo; corresponds to the facts. ÿ My phone
          is being used by the
          <br /> &ldquo;Company.&rdquo; There may be errors in vehicle information entered through
          an external computer or through the &ldquo;Korea-Carmarket&rdquo; manager, and
          &ldquo;general members&rdquo; must check whether the vehicle information matches the facts
          during the quotation process. ÿ &ldquo;Dealer members&rdquo; have the obligation to check
          on site whether the vehicle information uploaded to &ldquo;Korea-
          <br /> Carmarket&rdquo; is the same as the actual vehicle before acquiring the vehicle. If
          the uploaded vehicle information and the actual vehicle are not the same, the
          &ldquo;Dealer Member&rdquo; has no obligation to take over the vehicle. However, in case
          of problems arising from the &ldquo;Dealer Member&rdquo; failing to inspect the vehicle
          on-site or neglecting the inspection prior to taking over the vehicle,
        </p>

        <br />
        <p>
          You are responsible for this. ÿ In
          <br /> response to the &ldquo;Company&rsquo;s&rdquo; judgment of unfair depreciation, the
          &ldquo;Dealer Member&rdquo; is obligated to refund the unfair depreciation to the
          customer. If there is an objection to the judgment of unfair depreciation, the objection
          can be raised via email to the &ldquo;Company&rdquo; within 2 weeks, and the
          &ldquo;Company&rdquo; must reconsider and respond to the &ldquo;Dealer Member&rdquo;
          within 8 weeks of receipt. Article 16 (Withdrawal of subscription, etc.)
        </p>

        <br />
        <p>
          ÿ Users who have entered into a contract with the &ldquo;Company&rdquo; for individual
          services such as goods may withdraw their subscription within 7 days from the date of
          receiving a written letter regarding the contents of the contract. ÿ If a
          &ldquo;member&rdquo;
          <br /> does not use the service at all and requests cancellation within 7 days, the full
          amount paid will be refunded. However, it is required for refund
          <br /> The costs are borne by the user. ÿ When a &ldquo;member&rdquo;
        </p>
        <p>
          partially uses the service and requests cancellation of subscription, the amount
          corresponding to the number of days of use and a penalty fee of up to 10% of the total
          usage amount are deducted from the payment amount and then refunded. However, if you
          request cancellation of subscription within 7 days after using part of the service, the
          decision will be made without penalty.
        </p>
        <p>
          Only the amount corresponding to the number of days of discharge will be deducted and
          refunded.
          <br /> ÿ The services provided by the &ldquo;Company&rdquo; are subject to
          non-cancellation of subscription as stipulated in the Content Industry Promotion Act and
          the Content User Protection Guidelines.
        </p>

        <p>
          May contain bis. In this case, the &ldquo;Company&rdquo; uses the service for services for
          which cancellation of subscription is not possible according to the same law. This fact
          may be stated in the information and the &ldquo;member&rsquo;s&rdquo; right to cancel the
          subscription may be partially restricted.
          <br /> Article 17 (Relationship with linked websites)
          <br /> ÿ Hyperlinks (targets include text, images, videos, etc.) provided by other
          websites in the &ldquo;Korea-Carmarket&rdquo; of the &ldquo;Company&rdquo;
        </p>
        <p>
          When connected through a formula, etc., the &ldquo;Company&rdquo; is responsible for
          guaranteeing transactions of goods, etc. that the connected website independently conducts
          with the &ldquo;Member.&rdquo; You can specify this on the initial screen or pop-up screen
          of &ldquo;Korea-Carmarket&rdquo;. In this case, &ldquo;Company&rdquo; refers to
          <br /> We are not responsible for guaranteeing this transaction. Article 18
          <br /> (Copyright attribution and use restrictions)
        </p>
        <p>
          ÿ Copyrights and other intellectual property rights for works created by the
          &ldquo;Company&rdquo; belong to the &ldquo;Company&rdquo;. ÿ Among the
          <br /> information obtained by using &ldquo;Korea-Carmarket,&rdquo; the user may use the
          information whose intellectual property rights belong to the &ldquo;Company&rdquo; to the
          &ldquo;Company&rsquo;s&rdquo;
          <br /> Use it for commercial purposes or allow a third party to use it for commercial
          purposes by copying, transmitting, publishing, distributing, broadcasting or other methods
          without prior consent. should not be done.
          <br /> ÿ If the &ldquo;Company&rdquo; uses the copyright belonging to a user in accordance
          with the agreement, it must notify the user. Article 19 (Indemnification of
          &ldquo;Company&rdquo;, etc.)
        </p>
        <br />
        <p>
          ÿ The &ldquo;Company&rdquo; is an online automobile sales information provider, and its
          role is to provide sales information to ensure smooth transactions between &ldquo;general
          members&rdquo; and &ldquo;dealer members.&rdquo; Therefore, any inconsistencies in the
          uploaded information, incorrect entry of the estimate amount, or Stealing of vehicle
          information by a third party, consignment
          <br /> The &ldquo;Company&rdquo; is not responsible for disputes between
          &ldquo;Members&rdquo; that arise due to reasons attributable to the &ldquo;Members,&rdquo;
          such as an incident or accident. ÿ In the event of
          <br /> a dispute between &ldquo;members,&rdquo; regardless of whether the
          &ldquo;Company&rdquo; is legally responsible, the &ldquo;Company&rdquo; will resolve the
          dispute amicably from an objective and neutral standpoint. We will do our best to resolve
          the issue. Article 20
        </p>
        <p>
          (Dispute Resolution)
          <br /> ÿ The &ldquo;Company&rdquo; establishes and operates a damage compensation
          processing organization to reflect legitimate opinions or complaints raised by users and
          to compensate for the damage.
          <br /> ÿ The &ldquo;Company&rdquo; gives
          <br /> priority to complaints and opinions submitted by users. However, it is difficult to
          process quickly
          <br /> In this case, the user will be notified immediately of the reason and processing
          schedule. ÿ In the event that a<br /> user applies for damage relief in connection with an
          e-commerce dispute that arises between the &ldquo;Company&rdquo; and the user, the dispute
          may be subject to mediation by a dispute mediation agency requested by the Fair Trade
          Commission or the city/provincial governor. Article 21 (Jurisdiction and
          <br /> Governing Law)
          <br />
          <br /> ÿ Litigation related to e-commerce disputes that arise between the
          &ldquo;Company&rdquo; and the user will be determined by the user&rsquo;s address at the
          time of filing, and if there is no address, it will be under the exclusive jurisdiction of
          the district court with jurisdiction over the user&rsquo;s place of residence. However, if
          the user's address or residence is not clear at the time of filing the complaint, In the
          case of foreign residents, the Seoul Central District Court has exclusive jurisdiction. ÿ
          The laws of
          <br /> the Republic of Korea apply to e-commerce lawsuits filed between the
          &ldquo;Company&rdquo; and users. Supplementary provisions
        </p>
        <br />
        <p>go . These terms and conditions apply from July 1, 2024.</p>
      </Content>
    </Container>
  );
};

export default AgreementPage;

export const Container = styled.div`
  max-width: 800px;
  margin: 10rem auto;
  padding: 20px;
`;

export const Content = styled.div`
  p {
    line-height: 2.5;
  }
`;
