import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, Outlet, redirect, RouterProvider } from 'react-router-dom';
import { QueryProvider } from '@/lib/react-query/QueryProvider';
import { ReactQueryDevtools } from 'react-query/devtools';
import GlobalStyles from '@/assets/styles/GlobalStyles';
import App from './App';

// Import and verify all route components
import { Login, Membership } from './_root/pages/user';
import { MyPage } from './_auth/pages/user/mypage';
import { CarDetailPage, VehicleRequestListPage } from './_root/pages/vehiclepage';
import { EstimateRequestPage, VehicleRequestPage } from './_root/pages/request';
import { AgreementPage, PrivacyPage } from './_root/pages/agreements';
import VehicleRequestDetail from './_root/pages/vehiclepage/VehicleRequestDetailPage';
import NotFound from './_root/pages/NotFound';
import RootLayout from '@/_root/RootLayout';
import AuthLayout from '@/_auth/AuthLayout';
import AuthAfterLayout from '@/_auth/AuthAfterLayout';
import { Home } from './_root/pages/home';
import CertifiedCarPage from './_root/pages/certifiedcar/CertifiedCarHome';
import CertifiedCarDetail from './_root/pages/certifiedcar/CertifiedCarDetail';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,

    children: [
      { index: true, element: <Home /> }, // /경로
      {
        element: <RootLayout />,

        children: [
          { path: 'certified-car', element: <CertifiedCarPage /> },
          { path: 'certified-car-detail/:suchullo_car_key', element: <CertifiedCarDetail /> },

          { path: 'membership', element: <Membership /> },
          { path: 'car/:inboundNo', element: <CarDetailPage /> },
          { path: 'vehicle-request', element: <VehicleRequestPage /> },
          { path: 'vehicle-request-list', element: <VehicleRequestListPage /> },
          { path: 'vehicle-detail_request/:requestKey', element: <VehicleRequestDetail /> },
          { path: 'agreement', element: <AgreementPage /> },
          { path: 'privacy', element: <PrivacyPage /> },
        ],
      },
      {
        element: <AuthAfterLayout />,

        children: [{ path: 'login', element: <Login /> }],
      },
      {
        element: <AuthLayout />,

        children: [
          { path: 'mypage', element: <MyPage /> },
          { path: 'estimate-request', element: <EstimateRequestPage /> },
        ],
      },
      { path: '*', element: <NotFound /> },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <QueryProvider>
      <GlobalStyles />
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      <RouterProvider router={router} />
    </QueryProvider>
  </React.StrictMode>
);
