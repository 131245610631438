import React from 'react';

const NotFound = () => {
  return (
    <div>
      찾을 수 없는 페이지입니다.
    </div>
  );
};

export default NotFound;