import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { SmallButton } from '@/components/ui/buttons';
import { PurchaseTable } from '@/_auth/pages/user/mypage/index';
import { TranslatedTexts } from '@/components/ui/texts';
import { useCarFilterData } from '@/hooks/useCarData';
import { useForm, useWatch } from 'react-hook-form';
import { CustomMiniSelect, CustomMiniYearSelect } from '@/components/ui/input/CustomMiniSelect';
import { useTranslateObjectText } from '@/utils/CustomFunctions';

const Container = styled.div(() => ({
  marginTop: '4rem',
}));

const Title = styled.div(({ theme }) => ({
  marginBottom: '2rem',
  fontSize: '2.4rem',
  fontWeight: theme.fontWeight.bold,
}));

const FilterContainer = styled.form(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  padding: '2rem 3rem',
  background: theme.color.white02,
}));

const TabContainer = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  margin: '2rem 0',
}));
const TabItem = styled.div(({ theme, active }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '1.6rem 0',
  fontSize: '1.6rem',
  fontWeight: theme.fontWeight.bold,
  borderBottom: active ? `2px solid ${theme.color.blue01}` : `2px solid ${theme.color.grey03}`,
  cursor: 'pointer',
  color: active ? theme.color.black01 : theme.color.grey03,

  ':hover': {
    color: theme.color.black01,
    borderBottom: `2px solid ${theme.color.blue01}`,
    cursor: 'pointer',
  },
}));

const YearSelectContainer = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  '&>span': {
    width: '2rem',
    textAlign: 'center',
  },
}));

const Purchase = () => {
  const [filter, setFilter] = useState({ brandCode: '', modelCode: '', minYear: '', maxYear: '' });
  const [tabActive, setTabActive] = useState(1);
  const [statusCode, setStatusCode] = useState('');

  const PURCHASE_TAB_ARR = [
    { id: 1, name: <TranslatedTexts id="total_estimate" />, code: '' },
    { id: 2, name: <TranslatedTexts id="transaction" />, code: 0 },
    { id: 3, name: <TranslatedTexts id="completed" />, code: 2 },
    { id: 4, name: <TranslatedTexts id="vehicle_receivable" />, code: 1 },
  ];

  const handleTabChange = useCallback((id, code) => {
    setTabActive(id);
    setStatusCode(code);
  }, []);

  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues: {
      brandCode: '',
      modelCode: '',
      minYear: '',
      maxYear: '',
    },
  });

  const { apiData } = useCarFilterData(watch());

  const handleFilterSubmit = useCallback((data) => {
    setFilter(data);
  }, []);

  const brandCode = useWatch({
    control,
    name: 'brandCode', // 감시할 필드 이름
  });

  useEffect(() => {
    if (brandCode) {
      setValue('modelCode', ''); // modelCode 필드를 빈 문자열로 설정
    }
  }, [brandCode, setValue]);

  // 브랜드 코드 선택 후 모델 목록 가져오기
  const modelNames = brandCode ? apiData?.modelNames || [] : [];

  // 모델이 있는지 확인
  const isModelAvailable = modelNames.length > 0;

  // 모델 드롭다운의 emptyLabel을 설정
  const modelEmptyLabel = isModelAvailable ? (
    <TranslatedTexts id="model" />
  ) : brandCode && brandCode !== 'manufacturer' ? (
    <TranslatedTexts id="no_models_available" />
  ) : (
    <TranslatedTexts id="model" />
  );

  return (
    <Container>
      <Title>
        <TranslatedTexts id="my_vehicle_status" />
      </Title>

      <FilterContainer onSubmit={handleSubmit(handleFilterSubmit)}>
        <CustomMiniSelect
          arr={apiData?.brandNames}
          control={control}
          name="brandCode"
          emptyLabel={<TranslatedTexts id="manufacturer" />}
          keyText="brandCode"
          valueText="brandName"
        />
        <CustomMiniSelect
          // arr={apiData?.modelNames}
          arr={isModelAvailable ? apiData?.modelNames : []}
          control={control}
          name="modelCode"
          // emptyLabel={<TranslatedTexts id="model" />}
          emptyLabel={modelEmptyLabel}
          keyText="modelCode"
          valueText="modelName"
        />
        <YearSelectContainer>
          <CustomMiniYearSelect
            control={control}
            name="minYear"
            placeholder={useTranslateObjectText('min_year')}
            maxDate={watch('maxYear') ? new Date(Number(watch('maxYear')), 0, 1) : new Date()}
          />

          <span>~</span>

          <CustomMiniYearSelect
            control={control}
            name="maxYear"
            placeholder={useTranslateObjectText('max_year')}
            minDate={watch('minYear') ? new Date(Number(watch('minYear')), 0, 1) : undefined}
          />
        </YearSelectContainer>
        <SmallButton type="submit">
          <TranslatedTexts id="search" />
        </SmallButton>
      </FilterContainer>

      <TabContainer>
        {PURCHASE_TAB_ARR.map((tab) => {
          return (
            <TabItem
              key={tab.id}
              active={tabActive === tab.id}
              onClick={() => handleTabChange(tab.id, tab.code)}
            >
              {tab.name}
            </TabItem>
          );
        })}
      </TabContainer>

      <PurchaseTable statusCode={statusCode} filter={filter} />
    </Container>
  );
};

export default Purchase;
