import React from 'react';
import { CountryDropdown } from 'react-country-region-selector';
import styled from '@emotion/styled';
import Dropdown from '@/assets/icons/dropdown.png';
// import { TranslatedTexts } from '../texts';
import { useIntl } from 'react-intl';

const CountrySelector = (props) => {
  const { onBlur, onChange, value } = props;
  const intl = useIntl();
  const translatedLabel = intl.formatMessage({ id: 'select_country' });

  return (
    <div>
      <StyledCountryDropdown
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        // ref={selectref}
        valueType="full"
        defaultOptionLabel={translatedLabel}
        // defaultOptionLabel={<TranslatedTexts id="select_country" />}
      />
    </div>
  );
};

export default CountrySelector;

const StyledCountryDropdown = styled(CountryDropdown)(({ theme }) => ({
  width: '28.5rem',
  padding: '2rem',
  borderRadius: '1.2rem',
  backgroundColor: theme.color.white02,
  color: theme.color.grey01,
  fontSize: '1.6rem',
  appearance: 'none',
  backgroundImage: `url(${Dropdown})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 2rem center',
}));
