import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';

import { useFetchCertifiedCarsDetail, useFetchDetail } from '@/hooks/useCarData';
import { addCommaToNumber, displayValueOrNA, formatDate } from '@/utils/CustomFunctions';
import thumbnailImage from '@/assets/images/thumbnail.png';
import { RequestButton } from '../request/EstimateRequestPage';
import { Button } from '@mui/material';
import whatsappIcon from '@/assets/icons/whatsapp.png';
import { AlertComponent } from '@/components/ui/alert';
import useAlert from '@/hooks/useAlert';
import playIcon from '@/assets/icons/paly-btn.png';
import { RightSidebar } from '@/components/ui/sidebar';
import { useUserContext } from '@/context/AuthContext';
import { NA } from '@/constants/Constants';
import { TranslatedTexts } from '@/components/ui/texts';
import { LoadingComponent, NoResultsComponent } from '@/components/shared/status';
import { useCertifiedCarEstimateStatus, usePostCertifiedEstimate } from '@/hooks/useApiHandlers';

const CertifiedCarDetail = () => {
  const params = useParams();
  const { isAuthenticated } = useUserContext();
  const suchullo_car_key = Number(params.suchullo_car_key);

  const { data: car, isLoading } = useFetchCertifiedCarsDetail(suchullo_car_key);

  // const { monthName } = formatDate(car?.age);
  // const year = car?.age && car.age.length === 6 ? car.age.substring(0, 4) : car?.age || NA;

  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const navigate = useNavigate();

  const { showAlert, alertMessage, handleShowAlert, handleCloseAlert } = useAlert();
  const [isPlaying, setIsPlaying] = useState(false);
  const location = useLocation();

  const handleGoToList = () => {
    if (location.state && location.state.from) {
      // location.state.from에서 쿼리 파라미터만 추출
      const searchParams = location.state.from.split('?')[1] || '';
      // 홈으로 이동하면서 쿼리 파라미터 유지
      navigate(`/certified-car?${searchParams}`);
    } else {
      // 상태가 없으면 그냥 홈으로 이동
      navigate('/certified-car');
    }
  };

  const handleVideoClick = () => {
    setIsPlaying(true);
  };

  const imageUrls = car?.data?.img_list?.map((img) => img) || [];
  const mediaUrls = car?.data?.engine_video ? [car?.data?.engine_video, ...imageUrls] : imageUrls;
  const { data: estimateStatus } = useCertifiedCarEstimateStatus(suchullo_car_key);

  const handleRequestClick = () => {
    if (isAuthenticated) {
      if (estimateStatus && estimateStatus?.data?.suchullo_car_key === suchullo_car_key) {
        handleShowAlert('already_requested_vehicle');
      } else {
        const params = new URLSearchParams({
          type: 'certified',
          inbound_no: suchullo_car_key,
          brand_code: car?.data?.brand_name,
          model_year: car?.data?.age,
          model: car?.data?.model_dtl_name,
          car_no: car?.data?.car_no,
          car_fuel: car?.data?.fuel_nm,
          car_km: car?.data?.km,
          transmission: car?.data?.transmission_nm,
          vin: car?.data?.vin,
        }).toString();

        navigate(`/estimate-request?${params}`);
      }
    } else {
      sessionStorage.setItem('redirectAfterLogin', location.pathname);
      handleShowAlert('login_required');
      setTimeout(() => navigate('/login'), 1000);
    }
  };

  const mainMediaUrl = mediaUrls[currentMediaIndex] || thumbnailImage;

  const clickThumbnailHandler = (index) => {
    setCurrentMediaIndex(index);
  };

  return (
    <CarDetailContainer>
      {isLoading ? (
        <LoadingComponent />
      ) : car?.data?.length === 0 ? (
        <NoResultsComponent />
      ) : (
        <>
          <TopInfoBar>
            <RightSidebar />

            <CarInfo>
              <CarTitle>{`${displayValueOrNA(car?.data?.brand_name)}  ${displayValueOrNA(
                car?.data?.model_dtl_name
              )}`}</CarTitle>

              <CarSpecs>
                {`${displayValueOrNA(car?.data?.car_no)} · `}
                {`${displayValueOrNA(car?.data?.age)} · `}
                {addCommaToNumber(displayValueOrNA(car?.data?.km))}Km ·{' '}
                {displayValueOrNA(car?.data?.fuel_nm)} ·{' '}
                {displayValueOrNA(car?.data?.transmission_nm)}
              </CarSpecs>
            </CarInfo>

            <BtnBox>
              <a
                href="https://api.whatsapp.com/send?phone=821091602804"
                target="_blank"
                rel="noreferrer"
              >
                <img src={whatsappIcon} alt="whatsappIcon" />
              </a>
            </BtnBox>
          </TopInfoBar>

          <MediaSection>
            <MainMediaContainer>
              {mainMediaUrl === car?.data?.engine_video ? (
                <VideoWrapper onClick={handleVideoClick}>
                  <ReactPlayer
                    url={mainMediaUrl}
                    width="100%"
                    height="100%"
                    playing={isPlaying}
                    light={true}
                    playIcon={<PlayButton src={playIcon} alt="Play" />}
                    controls={true}
                  />
                </VideoWrapper>
              ) : (
                <MainImage
                  src={mainMediaUrl}
                  alt={`${car?.data?.brand_name} ${car?.data?.model_name}`}
                />
              )}
            </MainMediaContainer>
            <ThumbnailContainer>
              {mediaUrls.map((url, index) => (
                <ThumbnailWrapper key={index} onClick={() => clickThumbnailHandler(index)}>
                  {url === car?.data?.engine_video ? (
                    <ThumbnailVideo>
                      <ReactPlayer
                        light={true}
                        url={url}
                        width="100%"
                        height="100%"
                        playIcon={<PlayIconOverlay src={playIcon} alt="Play" />}
                      />
                    </ThumbnailVideo>
                  ) : (
                    <Thumbnail src={url} alt={`Thumbnail ${index + 1}`} />
                  )}
                </ThumbnailWrapper>
              ))}
            </ThumbnailContainer>
          </MediaSection>

          <RequestSection>
            <Message>
              <TranslatedTexts id="quote_message" />
            </Message>

            <ButtonBox>
              {/* 문의버튼 보류 */}
              {/* <InquiryButton onClick={() => handleShowAlert('준비중입니다.')}>
            <TranslatedTexts id="inquiry" defaultMessage="Inquiry" />
          </InquiryButton>

          <AlertComponent open={showAlert} severity="info" onClose={handleCloseAlert} /> */}
              <RequestButton variant="contained" onClick={() => handleRequestClick()}>
                <TranslatedTexts id="request_button_uppercase" />
              </RequestButton>

              <AlertComponent
                open={showAlert}
                message={alertMessage}
                severity="info"
                onClose={handleCloseAlert}
              />
            </ButtonBox>
          </RequestSection>

          <InfoSection>
            <Column>
              <InformationTitle>
                <TranslatedTexts id="basic_information" />
              </InformationTitle>

              <InfoBox>
                <InfoRow>
                  <InfoLabel>
                    <TranslatedTexts id="manufacturer" />
                  </InfoLabel>

                  <InfoValue>{displayValueOrNA(car?.data?.brand_name)}</InfoValue>
                </InfoRow>

                <InfoRow>
                  <InfoLabel>
                    <TranslatedTexts id="model" />
                  </InfoLabel>

                  <InfoValue>{displayValueOrNA(car?.data?.model_name)}</InfoValue>
                </InfoRow>

                <InfoRow>
                  <InfoLabel>
                    <TranslatedTexts id="detailed_model" />
                  </InfoLabel>

                  <InfoValue>{displayValueOrNA(car?.data?.model_dtl_name)}</InfoValue>
                </InfoRow>

                <InfoRow>
                  <InfoLabel>
                    <TranslatedTexts id="carVin" />
                  </InfoLabel>

                  <InfoValue>{displayValueOrNA(car?.data?.vin)}</InfoValue>
                </InfoRow>
              </InfoBox>

              <InfoBox>
                <InfoRow>
                  <InfoLabel>
                    <TranslatedTexts id="year" />
                  </InfoLabel>

                  <InfoValue>
                    {/* <TranslatedTexts id={monthName} /> */}
                    {/* {` ${year}`} */}
                    {/* {monthName !== 'blank' && <TranslatedTexts id={monthName} />} */}
                    {/* {monthName !== 'blank' ? ` ${year}` : year} */}
                    {displayValueOrNA(car?.data?.age)}
                  </InfoValue>
                </InfoRow>

                <InfoRow>
                  <InfoLabel>
                    <TranslatedTexts id="mileage" />
                  </InfoLabel>
                  <InfoValue>
                    {/* {displayValueOrNA(car?.km)}km */}
                    {addCommaToNumber(displayValueOrNA(car?.data?.km))}km
                  </InfoValue>
                </InfoRow>

                <InfoRow>
                  <InfoLabel>
                    <TranslatedTexts id="fuel" />
                  </InfoLabel>
                  <InfoValue>{displayValueOrNA(car?.data?.fuel_nm)}</InfoValue>
                </InfoRow>

                {/* api 데이터 추가필요 */}
                {/* <InfoRow>
              <InfoLabel>
                <TranslatedTexts id="displacement" defaultMessage="Displacement" />
              </InfoLabel>
              <InfoValue>{}</InfoValue>
            </InfoRow> */}
              </InfoBox>
            </Column>

            <Column>
              <InformationTitle>
                <TranslatedTexts id="options_information" />
              </InformationTitle>
              <InfoBox>
                <InfoRow>
                  <InfoLabel>
                    <TranslatedTexts id="transmission" />
                  </InfoLabel>
                  <InfoValue>{displayValueOrNA(car?.data?.transmission_nm)}</InfoValue>
                </InfoRow>

                <InfoRow>
                  <InfoLabel>
                    <TranslatedTexts id="engine_type" />
                  </InfoLabel>
                  <InfoValue>{displayValueOrNA(car?.data?.motor_type)}</InfoValue>
                </InfoRow>

                <InfoRow>
                  <InfoLabel>
                    <TranslatedTexts id="smart_key" />
                  </InfoLabel>
                  <InfoValue>{displayValueOrNA(car?.data?.smartkey)}</InfoValue>
                </InfoRow>

                <InfoRow>
                  <InfoLabel>
                    <TranslatedTexts id="sunroof" />
                  </InfoLabel>
                  <InfoValue>{displayValueOrNA(car?.data?.sunroof)}</InfoValue>
                </InfoRow>
              </InfoBox>

              <InfoBox>
                <InfoRow>
                  <InfoLabel>
                    <TranslatedTexts id="drive" />
                  </InfoLabel>
                  <InfoValue>{displayValueOrNA(car?.data?.wheel_nm)}</InfoValue>
                </InfoRow>

                {/* api 데이터 추가필요 용량 ? */}
                {/* <InfoRow>
              <InfoLabel>
                <TranslatedTexts id="capacity" defaultMessage="Capacity" />
              </InfoLabel>
              <InfoValue>{}</InfoValue>
            </InfoRow> */}

                <InfoRow>
                  <InfoLabel>
                    <TranslatedTexts id="note" />
                  </InfoLabel>
                  <InfoValue>{car?.data?.remark}</InfoValue>
                </InfoRow>
              </InfoBox>
            </Column>
          </InfoSection>

          <ActionButtonsWrapper>
            <ListButton variant="contained" onClick={handleGoToList}>
              <TranslatedTexts id="list" />
            </ListButton>
          </ActionButtonsWrapper>
        </>
      )}
    </CarDetailContainer>
  );
};

export default CertifiedCarDetail;

const CarDetailContainer = styled.div(() => ({
  margin: '10rem auto 20rem',
}));

const TopInfoBar = styled.div(({ theme }) => ({
  height: '15.5rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8rem 0rem 2rem 0rem',
  borderBottom: `1px solid ${theme.color.grey03}`,
  marginBottom: '3rem',
}));

const CarInfo = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.8rem',
  maxHeight: '5.5rem',
});

const CarTitle = styled.div(({ theme }) => ({
  fontSize: '2.4rem',
  fontWeight: theme.fontWeight.bold,
}));

const CarSpecs = styled.p(({ theme }) => ({
  fontSize: '1.4rem',
  color: theme.color.grey07,
}));

const BtnBox = styled.div({
  // width: '9rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const MediaSection = styled.div({
  marginBottom: '2rem',
});

const MainMediaContainer = styled.div({
  width: '100%',
  overflow: 'hidden',
  height: '65rem',
});

const MainImage = styled.img({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const ThumbnailContainer = styled.div({
  display: 'flex',
  overflowY: 'hidden',
  overflowX: 'auto',
});

const ThumbnailWrapper = styled.div({
  cursor: 'pointer',
});

const Thumbnail = styled.img(() => ({
  width: '9rem',
  height: '7rem',
  objectFit: 'cover',
}));

const ThumbnailVideo = styled.div({
  position: 'relative',
  width: '9rem',
  height: '7rem',
  overflow: 'hidden',

  /* 모든 자식 요소의 pointer-events를 없앰 */
  '& *': {
    pointerEvents: 'none !important',
  },
});

const InformationTitle = styled.div(({ theme }) => ({
  fontSize: '2.0rem',
  fontWeight: theme.fontWeight.bold,
  marginBottom: '2rem',
}));

const InfoSection = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '4rem',
  marginBottom: '4rem',
});

const Column = styled.div({
  width: '48%',
});

const InfoBox = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '0.8rem',
}));

const InfoRow = styled.div(() => ({
  display: 'flex',
  justifyContent: 'start',
  fontSize: '1.8rem',
  padding: '1.5rem 0',
}));

const InfoLabel = styled.span(({ theme }) => ({
  width: '40%',
  color: theme.color.grey09,
}));

const InfoValue = styled.span(() => ({
  width: '100%',
  fontSize: '1.8rem',
  whiteSpace: 'break-spaces',
}));

const ActionButtonsWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  gap: '2rem',
  margin: '17.5rem 0rem 1rem 0rem',
});

const ButtonBox = styled.div(() => ({
  display: 'flex',
  gap: '1rem',
}));

const RequestSection = styled.div(({ theme }) => ({
  height: '9.3rem',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.color.grey03}`,
}));

export const ListButton = styled(Button)(({ theme }) => ({
  color: theme.color.grey12,
  border: `1px solid ${theme.color.grey12}`,
  width: '18rem',
  height: '5.2rem',
  borderRadius: '26px',
  backgroundColor: 'transparent',

  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const Message = styled.div(({ theme }) => ({ color: theme.color.grey01, fontSize: '1.8rem' }));

// const InquiryButton = styled(Button)(({ theme }) => ({
//   border: `1px solid ${theme.color.blue01}`,
//   borderColor: theme.color.blue01,
//   width: '18rem',
//   height: '5.2rem',
//   borderRadius: '26px',
//   fontWeight: theme.fontWeight.bold,
// }));

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const PlayButton = styled.img`
  cursor: pointer;
`;

const PlayIconOverlay = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  pointer-events: none;
`;
