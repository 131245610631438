import styled from '@emotion/styled';
import { SearchBar } from '@/components/ui/input';
import { LeftSidebar, RightSidebar } from '@/components/ui/sidebar';
import { CardContainer } from '@/components/ui/container';

const Home = () => {
  return (
    <>
      <Container>
        <RightSidebar />

        <SearchBar />

        <ContentWrapper>
          <LeftSidebar />
          <CardContainer />
        </ContentWrapper>
      </Container>
    </>
  );
};

export default Home;

const Container = styled.div(() => ({
  width: '100%',
  margin: '12rem auto',
  position: 'relative',
  zIndex: '1',
}));

const ContentWrapper = styled.div(() => ({
  display: 'flex',
}));
