// useAlert.js
import { useState } from 'react';

const useAlert = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('info');

  const handleShowAlert = (message, severity = 'info') => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowAlert(false);
  };
  //
  return { showAlert, alertMessage, handleShowAlert, handleCloseAlert, alertSeverity };
};

export default useAlert;
