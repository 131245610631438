import {
  BODY_NAMES_KEY,
  BRAND_NAMES_KEY,
  CAR_COUNT_KEY,
  CAR_DETAIL_KEY,
  CAR_SEARCH_KEY,
  MODEL_DTL_NAMES_KEY,
  MODEL_NAMES_KEY,
} from '@/constants/queryKey';
import {
  fetchBodyHandler,
  fetchBrandsHandler,
  fetchCertifiedCarCountHandler,
  fetchCertifiedCarDetailHandler,
  fetchCertifiedCarSearchHandler,
  fetchCountHandler,
  fetchDetailedModelsHandler,
  fetchDetailHandler,
  fetchModelsHandler,
  fetchSearchHandler,
} from '@/utils/Functions';
import { useQuery } from 'react-query';

// NOTE: get Brand Names
export const useFetchBrands = () => {
  return useQuery([BRAND_NAMES_KEY], fetchBrandsHandler);
};

// NOTE: get Model Names
export const useFetchModels = (brandCode) => {
  return useQuery(
    [MODEL_NAMES_KEY, brandCode],
    ({ queryKey }) => fetchModelsHandler({ queryKey }),
    {
      enabled: !!brandCode,
    }
  );
};

// get Body Names
export const useFetchBodys = (brandCode, modelCode) => {
  return useQuery(
    [BODY_NAMES_KEY, brandCode, modelCode],
    ({ queryKey }) => fetchBodyHandler({ queryKey }),
    {
      enabled: !!brandCode && !!modelCode,
    }
  );
};

// get Detailed Model Names
export const useFetchDetailedModels = (brandCode, modelCode, bodyCode) => {
  return useQuery(
    [MODEL_DTL_NAMES_KEY, brandCode, modelCode, bodyCode],

    ({ queryKey }) => fetchDetailedModelsHandler({ queryKey }),
    {
      enabled: !!brandCode && !!modelCode && !!bodyCode,
    }
  );
};

// get Search List
export const useFetchSearch = (selections) => {
  return useQuery([CAR_SEARCH_KEY, selections], () => fetchSearchHandler(selections), {});
};

// get Car Count
export const useFetchCount = (selections) => {
  return useQuery([CAR_COUNT_KEY, selections], () => {
    return fetchCountHandler(selections);
  });
};

// get Car Detail
export const useFetchDetail = (inboundNo) => {
  return useQuery([CAR_DETAIL_KEY, inboundNo], fetchDetailHandler, {
    enabled: !!inboundNo,
  });
};

// get Car Filter (my page)
export const useCarFilterData = (selections) => {
  const { data: brandNames } = useFetchBrands();
  const { data: modelNames } = useFetchModels(selections.brandCode);

  return {
    apiData: { brandNames, modelNames },
  };
};

// Certified Car search
export const useFetchCertifiedCars = (selections, page, size) => {
  return useQuery(
    ['CERTIFIED_CAR', page, selections, size],
    () => fetchCertifiedCarSearchHandler(selections, page, size),
    {}
  );
};

// Certified Car Count
export const useFetchCertifiedCarCount = (selections) => {
  return useQuery(['CERTIFIED_CAR_COUNT', selections], () =>
    fetchCertifiedCarCountHandler(selections)
  );
};

// Certified Car Detail
export const useFetchCertifiedCarsDetail = (suchullo_car_key) => {
  return useQuery(['CERTIFIED_CAR_DETAIL', suchullo_car_key], fetchCertifiedCarDetailHandler, {
    enabled: !!suchullo_car_key, // suchullo_car_key가 있을 때만 요청
  });
};

export const useCarData = (selections) => {
  const { data: brandNames } = useFetchBrands();
  const { data: modelNames } = useFetchModels(selections.brandCode);
  const { data: bodyNames } = useFetchBodys(selections.brandCode, selections.modelCode);
  const { data: modelDtlNames } = useFetchDetailedModels(
    selections.brandCode,
    selections.modelCode,
    selections.bodyCode
  );

  const { data: countData } = useFetchCount(selections);

  return {
    apiData: { brandNames, modelNames, bodyNames, modelDtlNames },
    countData,
  };
};
