import styled from '@emotion/styled';
import InfoIcon from '@/assets/icons/info.svg';
import { TranslatedTexts } from '@/components/ui/texts';

const NoResultsComponent = () => {
  return (
    <NoResultsContainer>
      <div>
        <img src={InfoIcon} alt="InfoIcon" />
      </div>
      <NoResultsText>
        <TranslatedTexts id="no_vehicles_searched" />
      </NoResultsText>
    </NoResultsContainer>
  );
};

const NoResultsContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100vh',
  marginTop: '2.2rem',
}));

const NoResultsText = styled.div(({ theme }) => ({
  fontSize: '1.6rem',
  color: theme.color.grey07,
}));

export default NoResultsComponent;
