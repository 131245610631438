import React, { createContext, useContext, useState } from 'react';

const IsDirtyContext = createContext();

export const IsDirtyProvider = ({ children }) => {
  const [isDirty, setIsDirty] = useState(false);

  return (
    <IsDirtyContext.Provider value={{ isDirty, setIsDirty }}>{children}</IsDirtyContext.Provider>
  );
};

export const useIsDirty = () => {
  const context = useContext(IsDirtyContext);
  if (context === undefined) {
    throw new Error('error');
  }
  return context;
};
