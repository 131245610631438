// src/context/ModalContext.js
import React, { createContext, useState, useContext } from 'react';
import ConfirmationModal from '../components/ui/modal/Modal';

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [modalState, setModalState] = useState({
    isOpen: false,
    messageId1: '',
    messageId2: '',
    confirmButtonId: '',
    cancelButtonId: '',
    onConfirm: () => {},
    onCancel: () => {},
    isLeaveWarning: false,
  });

  const openModal = (options) => {
    setModalState({ ...options, isOpen: true });
  };

  const closeModal = () => {
    setModalState((prev) => ({ ...prev, isOpen: false }));
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      <ConfirmationModal
        open={modalState.isOpen}
        handleClose={() => {
          closeModal();
          modalState.onCancel();
        }}
        handleConfirm={() => {
          closeModal();
          modalState.onConfirm();
        }}
        messageId1={modalState.messageId1}
        messageId2={modalState.messageId2}
        confirmButtonId={modalState.confirmButtonId}
        cancelButtonId={modalState.cancelButtonId}
        isLeaveWarning={modalState.isLeaveWarning}
      />
    </ModalContext.Provider>
  );
};
