import React from 'react';
import { Container, Content } from './AgreementPage';

const PrivacyPage = () => {
  return (
    <Container>
      <Content>
        <h1>AGREEMENT</h1>
        <br />
        <p>
          privacy policy
          <br /> Korea-Carmarket Co., Ltd. (hereinafter referred to as the &ldquo;Company&rdquo;) is
          an information and communications service provider, including the Communications Secrets
          Protection Act, the Telecommunications Business Act, the Act on Promotion of Information
          and Communications Network Utilization and Information Protection, the Personal
          Information Protection Act, and the personal information protection guidelines established
          by the government. We comply with the personal information protection regulations of
          relevant laws and regulations, and do our best to protect the rights and interests of
          users by establishing a personal information handling policy in accordance with relevant
          laws and regulations.
          <br /> Korea-Carmarket Co., Ltd.&rsquo;s personal information processing policy contains
          the following contents.
        </p>
        <br />
        <p>
          1. Items and collection methods of personal information collected 2. How to collect and
          use personal information
          <br /> 3. Sharing and provision of personal information to third parties 4. Entrustment of
          handling of personal information
        </p>
        <p>
          5. Retention and use period of personal information
          <br /> 6. Personal information destruction procedure and method of use
          <br /> 7. Protection of personal information of children under 14 years of age
          <br /> 8. User rights and methods of enforcing them
          <br /> 9. Matters related to installation/operation and refusal of automatic personal
          information collection devices
          <br /> 10. Technical/managerial protection measures for personal information
          <br /> 11. Contact information of personal information manager and person in charge
          <br /> 12. Others
          <br /> 13. Duty to notify
          <br />
          <br /> 1. Items and collection methods of personal information collected
          <br /> go. Items of personal information collected The company collects the following
          personal information for membership registration, smooth customer consultation, and
          provision of various services. General Membership
        </p>
        <br />
        <p>- When registering as a member: name / mobile phone number / email address</p>
        <p>
          - When requesting service: Car name / vehicle number / basic information including car
          model / year / options / mileage / vehicle photo / accident history and details / Vehicle
          pros and cons/name/contact/region
        </p>
        <p>
          - When registering as a member: desired ID / password / contact information / name /
          company name / email / optional items: fax
          <br /> Detailed information about the user's interests may be collected through events or
          additional member information collection. Additionally, the following information may be
          generated and collected during service use or business processing. - User's browser type
          and OS, search words, service use records: statistical analysis of service use
        </p>
        <br />
        <p>
          - IP address, date and time of visit, service use record, bad use record, cookie:
          Prevention of illegal/unauthorized use
          <br /> The company collects only the minimum amount of personal information necessary to
          provide services, and sensitive information that may infringe on users' basic human
          rights. Information (race, religion, ideology, place of origin, domicile, political
          orientation, criminal record, health status, etc.) is not collected. However, an exception
          is made if the user consents to the collection. me. How we collect personal information
        </p>
        <br />
        <p>
          The company collects personal information in the following legal and fair ways. -
          Membership registration, service use, phone calls, events, customer center, etc.
          <br /> - Provided by partner companies
          <br /> 2. How to collect and use personal information
        </p>
        <p>
          go. Fulfillment of contract for service provision, settlement of fees for service
          provision, provision of content, delivery of goods or sending of invoices, etc.
          <br /> Authentication, purchase and payment, fee collection
          <br /> me. Membership management Membership service use and limited identity verification
          system based on identity verification, personal identification, prevention of unauthorized
          use by delinquent members, and security measures Preventing use of authorization,
          confirming intent to subscribe, limiting subscription and number of subscriptions,
          preserving records for dispute resolution, handling civil complaints such as complaint
          handling, and delivering notices
          <br /> all. New service development and marketing New service development and customized
          service provision, service provision and advertisement posting according to statistical
          characteristics, service effectiveness verification, provision of event and advertising
          information, identification of access frequency, statistics on users' service use,
          management team
          <br /> Evaluation of dealer members
          <br /> la. Purpose of use of collected personal information
        </p>
        <p>
          - Device ID, mobile phone number, name, email address: Used for general member login
          <br /> - Car name/vehicle number/basic information other than vehicle
          model/year/options/mileage/vehicle photo/accident history/vehicle pros and
          cons/name/contact information/area: dealer Used for member price calculation and
          transactions
          <br /> - Desired ID/Password: Used to log in as a dealer member
          <br /> - Email/address/contact information (phone number/mobile phone number)/name/company
          name/photo/fax: used for identity verification process when using membership service
        </p>

        <br />

        <p>
          Smooth communication such as delivery of notices, confirmation of one&rsquo;s intention,
          complaint handling, event information, customized information service guidance, user
          classification, etc.
          <br /> Securing communication routes
          <br /> 3. Sharing and provision of personal information to third parties
          <br /> The company uses the user's personal information within the scope notified in "2.
          Purpose of collection and use of personal information" and, in principle, does not use the
          user's personal information beyond this scope or disclose the user's personal information
          to outside parties without the user's prior consent. not. However, in the following cases
        </p>
        <p>
          There are exceptions to this. -<br /> The user has consented to disclosure in advance or
          received general consent for personal information required to fulfill a contract related
          to service provision.
          <br /> In case of significant difficulties due to economic/technical reasons
          <br /> - When it is judged in good faith that it is required by other laws (e.g. a request
          from the government/investigative agency through legal procedures in accordance with
          relevant laws and regulations)
          <br /> - Providing the user's
          <br /> personal information to a third party for the purposes necessary for using the
          service (separately confirming the user's consent)
          <br /> If the rights and obligations of the service provider are transferred or inherited
          due to transfer of all or part of the business, merger or acquisition, etc., the fact
          <br /> We will notify you in detail in advance and give you the option to withdraw your
          consent to the collection/use of personal information. Please note that personal
          information entered by users through online bulletin boards, etc. may be collected by
          third parties. The company is not responsible for any personal information that users
          provide on bulletin boards, etc. Service personal information provision details
        </p>

        <br />

        <p>
          Request an estimate for my car (general member)
          <br /> - Recipient: Dealer member for submitting vehicle quotation / Dealer member for
          whom the customer has selected a sales request
          <br /> - Personal information items: Car name / Vehicle defense / Basic information other
          than car model / Year / Options / Mileage / Vehicle photo / Accident status and history /
        </p>
        <p>
          Vehicle pros and cons/name/contact/region/email
          <br /> - Purpose of use: For the purpose of evaluating the seller's vehicle value and
          providing sales consultation over the phone - Period of use: Provided upon customer's
          sales request, deleted within 60 days from the end of service use
        </p>
        <p>
          Purchasing dealer member information service (dealer member)
          <br /> - Recipient: General member for sales request / Member who uploaded the vehicle for
          which sales request was received
          <br /> - Personal information items: Contact information / Name / Company name / Email
          <br /> - Purpose of use: Used for the purpose of requesting consultation if the dealer
          member has any questions after requesting a sale. - Period of use: Provided after the
          dealer member receives a sales request, deleted within 60 days from the end of service use
        </p>
        <p>
          Accident history inquiry
          <br /> - Recipient: Insurance Development Institute
          <br /> - Personal information items: Vehicle information
          <br /> - Purpose of use: Car information inquiry and accident history check - Period of
          use: Service provision period
        </p>
        <p>
          4. Entrustment of personal information handling
          <br /> When necessary to improve services and to provide other services, the company
          entrusts the following tasks to external parties to collect, store, process, use, provide,
          manage, and destroy users' personal information. The company operates through consignment
          contracts, etc.
          <br /> Compliance with the service provider&rsquo;s personal information protection
          instructions, confidentiality of personal information, prohibition of provision to third
          parties, and responsibility in case of an accident. We protect the rights and interests of
          users by clearly defining the terms and conditions and storing the contract details in
          writing or electronically. - Entrusted work details: System maintenance/
          <br /> repair
          <br /> - Trustee: Brickmate, Itda Co., Ltd.
          <br /> - Period of retention and use of personal information: Until withdrawal of
          membership or termination of consignment contract
          <br /> 5. Retention and use period of personal information
          <br /> The company retains and uses the user's personal information while the user uses
          the service as a member, and requests the user to withdraw membership.
          <br /> When requested, when the purpose of collection and use of personal information has
          been achieved, when the retention and use period has expired, or when reasons such as
          business closure, etc.
        </p>
        <p>
          If this occurs, the information will be destroyed without delay. However, the following
          information will be preserved for the specified period for the reasons below. is. go.
          <br /> Reasons for retaining information according to company internal policy
          <br /> - Records of fraudulent use
        </p>
        <p>
          - Reason for preservation: Prevention of unauthorized use
          <br /> - Retention period: 1 year
          <br /> me. Reasons for retaining information in accordance with relevant laws and
          regulations
          <br /> If preservation is necessary in accordance with the provisions of related laws,
          such as the Commercial Act and the Act on Consumer Protection in Electronic Commerce,
          etc., the company stores member information for a certain period of time specified in the
          relevant laws. In this case, the
          <br /> company uses the stored information only for the purpose of storage, and the
          retention period is as follows. - Records of contracts or subscription
          <br /> withdrawals, etc.
          <br /> Reason for preservation: Act on Consumer Protection in Electronic Commerce, etc.
          <br /> Retention period: 5 years
          <br /> - Records of payment and supply of goods, etc.
          <br /> Reason for preservation: Act on Consumer Protection in Electronic Commerce, etc.
          <br /> Retention period: 5 years
          <br /> - Records of consumer complaints or dispute resolution
          <br /> Reason for preservation: Act on Consumer Protection in Electronic Commerce, etc.
          <br /> Retention period: 3 years
          <br /> - Records of identity verification
          <br /> Reason for preservation: Act on Promotion of Information and Communications
          Utilization and Information Protection, etc.
          <br /> Retention period: 6 months
          <br /> - Records of visits
          <br /> Reason for preservation; Communications Secrets Protection Act
          <br /> Retention period: 3 years
          <br /> 6. Personal information destruction procedures and methods
          <br /> The purpose of collection and use of the collected user's personal information,
          unless the company is required to preserve personal information in accordance with other
          laws
          <br /> If this is achieved or the user requests withdrawal of membership, it will be
          destroyed without delay and cannot be viewed or used for any purpose in the future.
          <br /> Process it accordingly. However, there are exceptions such as &ldquo;5. Period of
          retention and use of personal information A and B.&rdquo; The company's personal
          information destruction procedures and methods are as follows. go. Destruction procedure
        </p>

        <br />

        <p>
          The information entered by the user for membership registration, etc. is transferred to a
          separate DB (in the case of paper, a separate filing cabinet) after the purpose is
          achieved, in accordance with internal policies and other information protection reasons
          under relevant laws (see 5. Retention and Use Period) ) It is stored for a certain period
          of time and then destroyed. This personal information will not be used for any purpose
          other than that for which it is retained, unless required by law. me. Destruction method
        </p>

        <br />

        <p>
          Personal information printed on paper is destroyed by shredding or incineration, and
          personal information stored in electronic files is recorded. Deleted using technical
          methods that make it unplayable. 7. Protection of personal
          <br /> information of children under 17 years of age
          <br /> Children under the age of 14 cannot register as members on sites operated by the
          company. 8. User rights and how to exercise them
        </p>

        <br />
        <p>
          Users can view or modify their registered personal information at any time and may also
          request cancellation of membership (withdrawal of consent). To view or modify the user's
          personal information, click 'Change personal information' (or 'Edit member information',
          etc.), and to cancel membership (withdraw consent), click 'Cancel membership' and go
          through the identity verification process to view it directly. , correction or withdrawal
          is possible. Alternatively, if you contact the personal information manager in writing, by
          phone or email,
          <br /> we will take action without delay. If the user requests correction of an error in
          personal information, the personal information will not be used or provided
        </p>
        <p>
          until the correction is completed.
          <br /> Do not. Additionally, if incorrect personal information has already been provided
          to a third party, the third party will be notified of the correction results without
          delay. We will ensure that a correction is made. Personal information
          <br /> canceled or deleted at the request of the user or legal representative is specified
          in &ldquo;5. Retention and use period of personal information&rdquo;.
          <br /> We process it as indicated and prevent it from being viewed or used for any other
          purpose.
        </p>
        <p>
          9. Matters related to installation/operation and refusal of automatic personal information
          collection devices
          <br /> In order to provide customized services specialized for users, the company uses
          'cookies' to store and retrieve users' information from time to time. Use
          &lsquo;(cookie)&rsquo;. Cookies are sent to the user's computer browser by the server
          (HTTP) used to operate the website.
          <br /> is a small amount of information and may be stored on the hard disk of users' PC
          computers. go. Purpose of Use of Cookies
        </p>

        <br />

        <p>
          Providing optimized information to users by identifying whether users have logged in to
          each of the company's services and websites visited Used for balls. me. Installation/
          <br /> operation and refusal of cookies
          <br /> Users have the option to install cookies. Therefore, users can use all cookies by
          setting options in their web browser.
        </p>
        <p>
          You can allow the key, confirm each time a cookie is saved, or refuse to save all cookies.
          You can refuse cookie settings by allowing all cookies or by selecting the option in the
          web browser you use.
          <br /> You can confirm each time you save a key or refuse to save all cookies. Setting
          method (e.g. for Internet Explorer): Tools
          <br /> at the top of the web browser &gt; Internet Options &gt; Personal Information
        </p>
        <p>
          However, if you refuse to store cookies, you may have difficulty using some of the
          company's services that require login. 10. Technical/administrative protection measures
          for personal information
          <br /> When handling users' personal information, the company is taking the following
          technical/administrative measures to ensure safety and prevent personal information from
          being lost, stolen, leaked, altered or damaged. go. Personal information encryption
        </p>

        <br />

        <p>
          The user's key personal information is encrypted and stored, and files and transmission
          data are encrypted to ensure that the user's personal information is not leaked even in
          the event of an accident. me. Measures against hacking, etc.
        </p>

        <br />

        <p>
          The company is doing its best to prevent users' personal information from being leaked or
          damaged by hacking or computer viruses. We regularly back up data in preparation for
          damage to
          <br /> personal information, and use the latest anti-virus program to protect users'
          personal information or personal information.
          <br /> We prevent data from being leaked or damaged, and personal information is safely
          transmitted on the network through encrypted communication.
        </p>
        <p>
          We are making it possible for you to send it. Additionally, unauthorized access from
          outside is controlled using a 24-hour intrusion prevention system. And we are trying to
          equip all possible technical devices to ensure systematic security. all. Minimization and
          training of handling staff
        </p>

        <br />

        <p>
          The company's staff handling personal information is limited to the person in charge, and
          a separate password is given for this purpose and updated regularly.
          <br /> We always emphasize compliance with the personal information handling policy
          through regular training for staff in charge. In addition, before executives and employees
          handle users' personal information, they sign a security pledge to prevent information
          leaks regarding users' personal information and manage the information in advance.
          <br /> We have established related incident regulations and are establishing internal
          procedures to monitor their implementation and compliance. la. Operation of a dedicated
          <br /> organization for personal information protection
          <br /> Problems are discovered by checking the implementation of the personal information
          handling policy and compliance by the person in charge through an in-house personal
          information protection organization, etc. If possible, we are working to correct and
          correct it immediately. However, the user's own efforts
          <br /> are fundamentally important in managing the security of personal information.
          Online service only uses ID and secrets
          <br /> Only the person who knows the number has the authority to access personal
          information, and is also responsible for maintaining and managing the password.
          <br /> It's in the person. You must create a password with information that only you know,
          and avoid using passwords that can be easily stolen by others, such as part of your
          resident registration number or phone number. Additionally, you can access the company's
          services on a shared PC.
          <br /> If you move to another site while logged in, or if you terminate use of the
          service, you must use the relevant browser.
          <br /> Please quit me. Otherwise, the user's ID, password, and resident registration
          number are entered through the browser.
          <br /> There is a risk that your personal information may be easily leaked to others. Due
          to the user's carelessness or problems with the Internet, the ID, password, and main
          information may be lost. The company is not responsible for any problems caused by leakage
          of personal information such as civil registration number. 11. Contact
          <br /> information of personal information manager and person in charge
          <br /> The company has designated a personal information manager and person in charge of
          collecting opinions and handling complaints regarding personal information. Their contact
          information is as follows.
          <br /> Personal information manager
          <br /> Name: Kim Jin-hoo
          <br /> Affiliation: General Management Team/Director
        </p>

        <p>
          E.MAIL: jstwoco@naver.com
          <br /> Phone number: 032-833-1685
          <br /> If you need to report or consult about other personal information infringements,
          please contact the organizations below. - Personal
          <br /> information protection comprehensive support portal
          <br /> www.privacy.go.kr / 02-2100-3343
          <br /> -Personal Information Infringement Reporting Center
          <br /> privacy.kisa.or.kr / (without area code) 118
          <br /> - Personal Information Protection Support Center
          <br /> privacy.nia.or.kr / 02-2131-0111
          <br /> - National Police Agency Cyber Terror Response Center
          <br /> www.ctrc.go.kr/ 02-392-0330
          <br /> 12. Others
          <br /> Please be advised that this &ldquo;Korea-Carmarket Privacy Policy&rdquo; does not
          apply to the collection of personal information by websites linked to the site operated by
          the company.
        </p>
        <p>13. Duty to notify</p>
        <br />
        <p>
          If there are additions, deletions or modifications to the current personal information
          handling policy, the reason and contents of the change will be notified through the
          'Notice' of each service at least 7 days prior to
          <br /> revision. The contents of this privacy policy may change at any time, so please
          check it each time you visit the site. - Announcement date: July 1, 2024
        </p>
        <br />
        <p>- Effective date: July 1, 2024</p>
      </Content>
    </Container>
  );
};

export default PrivacyPage;
