import React from 'react';
import CertifiedCarLeftSidebar from './CertifiedCarLeftSidebar';
import styled from '@emotion/styled';
import { RightSidebar } from '@/components/ui/sidebar';
import { SearchBar } from '@/components/ui/input';
import CertifiedCardContainer from './CertifiedCardContainer';

const CertifiedCarHome = () => {
  return (
    <Container>
      <RightSidebar />
      <SearchBar />
      <ContentWrapper>
        <CertifiedCarLeftSidebar />
        <CertifiedCardContainer />
      </ContentWrapper>
    </Container>
  );
};

export default CertifiedCarHome;

const Container = styled.div(() => ({
  width: '128rem',
  margin: '12rem auto',
  position: 'relative',
  zIndex: '1',
}));

const ContentWrapper = styled.div(() => ({
  display: 'flex',
}));
