// App.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import { IntlProvider } from 'react-intl';
import { createTheme } from '@mui/material';
import AppTheme from '@/theme';
import { Header } from './components/shared/header';
import { Footer } from './components/shared/footer';
import { IsDirtyProvider } from './context/IsDirtyContext';
import { ModalProvider } from './context/ModalContext';
import { LOCAL_STORAGE_SUCHULLO_LANGUAGE } from '@/constants/storageKey';
import koMessages from '@/utils/languages/ko.json';
import enMessages from '@/utils/languages/en.json';
import esMessages from '@/utils/languages/es.json';
import ruMessages from '@/utils/languages/ru.json';
import arMessages from '@/utils/languages/ar.json';
import ScrollToTop from './components/shared/scroll/ScrollTop';
import AuthProvider from './context/AuthContext';
import styled from '@emotion/styled';

const messages = {
  ko: koMessages,
  en: enMessages,
  es: esMessages,
  ru: ruMessages,
  ar: arMessages,
};

function App() {
  const customMuiTheme = createTheme(AppTheme);

  const locale = localStorage.getItem(LOCAL_STORAGE_SUCHULLO_LANGUAGE) || 'en';

  const handleChangeLanguage = (lang) => {
    localStorage.setItem(LOCAL_STORAGE_SUCHULLO_LANGUAGE, lang);
    window.location.reload(false);
  };

  return (
    <AuthProvider>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <ThemeProvider theme={customMuiTheme}>
          <IsDirtyProvider>
            <ModalProvider>
              <AppContainer>
                <ScrollToTop />
                <Header handleChangeLanguage={handleChangeLanguage} />
                <MainContent>
                  <Outlet />
                </MainContent>
                <Footer />
              </AppContainer>
            </ModalProvider>
          </IsDirtyProvider>
        </ThemeProvider>
      </IntlProvider>
    </AuthProvider>
  );
}

export default App;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 190rem;
  position: relative;
  overflow: auto;
`;

const MainContent = styled.main`
  flex: 1;
  width: 128rem;
  margin: 0 auto;
`;
