import { useIsDirty } from '@/context/IsDirtyContext';
import { useModal } from '@/context/ModalContext';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useBlocker, useNavigate } from 'react-router-dom';

// export const usePreventLeave = () => {
//   const navigate = useNavigate();
//   const { openModal, closeModal } = useModal();
//   const { isDirty, setIsDirty } = useIsDirty();
//   const pendingNavigationRef = useRef(null); // 경로 저장
//   const [isBlocked, setIsBlocked] = useState(false); // 네비게이션 차단 여부

//   // 경고 모달을 표시하고, 사용자의 선택에 따라 이탈을 진행하거나 취소
//   const showLeaveWarningModal = useCallback(
//     (onConfirm, onCancel) => {
//       openModal({
//         messageId1: 'leave_page_warning_1',
//         messageId2: 'leave_page_warning_2',
//         confirmButtonId: 'confirm_button',
//         cancelButtonId: 'cancel_button_lowercase',
//         // 확인
//         onConfirm: () => {
//           setIsDirty(false);
//           setIsBlocked(false);
//           closeModal();
//           onConfirm();
//         },

//         // 취소
//         onCancel: () => {
//           setIsBlocked(false);
//           closeModal();
//           onCancel();
//         },
//       });
//     },
//     [openModal, closeModal, setIsDirty]
//   );

//   const handleNavigate = useCallback(
//     (to) => {
//       if (isDirty && !isBlocked) {
//         pendingNavigationRef.current = to;
//         setIsBlocked(true); // 모달을 열 때 isBlocked를 true로 설정

//         showLeaveWarningModal(
//           () => {
//             const destination = pendingNavigationRef.current;
//             pendingNavigationRef.current = null;
//             navigate(destination);
//           },
//           () => {
//             pendingNavigationRef.current = null;
//           }
//         );
//       } else if (!isDirty) {
//         navigate(to);
//       }
//     },
//     [isDirty, isBlocked, navigate, showLeaveWarningModal]
//   );

//   // 네비게이션을 차단할 조건
//   const blocker = useBlocker(isDirty);

//   // blocker의 상태가 'blocked'로 변경될 때 실행
//   useEffect(() => {
//     // console.log('Current Pathname:', blocker.location);
//     // console.log('blocker.state:', blocker.state);

//     // 각 요청 폼에서 navigate될때 이동경로들 (모달 중복 방지하기 위해)
//     let allowedPaths = [
//       '/',
//       '/login',
//       '/mypage',
//       '/vehicle-request',
//       '/vehicle-request-list',
//       '/membership',
//     ]; // 차단을 무시할 경로

//     if (blocker.state === 'blocked' && !isBlocked) {
//       // blocker의 pathname을 사용하여 특정 경로에서 차단이 실행되지 않도록 설정
//       if (!allowedPaths.includes(blocker.location.pathname)) {
//         // 현재 경로가 allowedPaths에 포함되어 있지 않은 경우
//         setIsBlocked(true); // 모달을 열 때 isBlocked를 true로 설정
//         showLeaveWarningModal(
//           () => {
//             blocker.proceed(); // 네비게이션을 계속 진행
//             blocker.reset(); // 네비게이션 후 상태 리셋
//             setIsBlocked(false);
//             setIsDirty(false);
//           },
//           () => {
//             blocker.reset(); // 네비게이션 취소
//             setIsBlocked(false);
//           }
//         );
//       } else {
//         // 경로가 allowedPaths에 포함된 경우 차단하지 않고 네비게이션을 바로 진행
//         blocker.proceed();
//         blocker.reset(); // 네비게이션 후 상태 리셋
//         setIsBlocked(false);
//         setIsDirty(false);
//       }
//     }
//   }, [blocker, isBlocked, showLeaveWarningModal, isDirty, setIsBlocked, setIsDirty]);

//   // 브라우저 새로고침 및 탭 닫기 제어
//   useEffect(() => {
//     const handleBeforeUnload = (event) => {
//       if (isDirty) {
//         event.preventDefault();
//         event.returnValue = ''; // 이 라인이 있어야 브라우저가 경고를 표시함
//       }
//     };

//     window.addEventListener('beforeunload', handleBeforeUnload);

//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, [isDirty]);

//   return { handleNavigate };
// };

export const usePreventLeave = () => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const { isDirty, setIsDirty } = useIsDirty();
  const pendingNavigationRef = useRef(null);
  const [isBlocked, setIsBlocked] = useState(false);
  const [allowedPaths, setAllowedPaths] = useState([]); // 동적 allowedPaths 상태

  // 경고 모달을 표시하고, 사용자의 선택에 따라 이탈을 진행하거나 취소
  const showLeaveWarningModal = useCallback(
    (onConfirm, onCancel) => {
      openModal({
        messageId1: 'leave_page_warning_1',
        messageId2: 'leave_page_warning_2',
        confirmButtonId: 'confirm_button',
        cancelButtonId: 'cancel_button_lowercase',
        onConfirm: () => {
          setIsDirty(false);
          setIsBlocked(false);
          setAllowedPaths([]); // allowedPaths 초기화
          closeModal();
          onConfirm();
        },
        onCancel: () => {
          setIsBlocked(false);
          setAllowedPaths([]); // allowedPaths 초기화
          closeModal();
          onCancel();
        },
      });
    },
    [openModal, closeModal, setIsDirty]
  );
  const blocker = useBlocker(isDirty);

  const handleNavigate = useCallback(
    (to, options = {}) => {
      const fromHeaderLink = options.state?.fromHeaderLink || false;
      const isAutoNavigate = options.state?.autoNavigate || false;

      if (fromHeaderLink || isAutoNavigate) {
        // 헤더에서 클릭된 경우 allowedPaths에 추가
        setAllowedPaths((prevPaths) => [...prevPaths, to]);
        navigate(to, options);
        return;
      }

      if (isDirty && !isBlocked) {
        pendingNavigationRef.current = to;

        setIsBlocked(true);

        if (allowedPaths.includes(to)) {
          navigate(to, options);
          setIsBlocked(false);
          setIsDirty(false);
          setAllowedPaths([]); // allowedPaths 초기화
          pendingNavigationRef.current = null;
        } else {
          showLeaveWarningModal(
            () => {
              const destination = pendingNavigationRef.current;
              pendingNavigationRef.current = null;
              navigate(destination, options);
            },
            () => {
              pendingNavigationRef.current = null;
            }
          );
        }
      } else if (!isDirty) {
        navigate(to, options);
      }
    },
    [
      isDirty,
      isBlocked,
      navigate,
      showLeaveWarningModal,
      allowedPaths,
      setIsBlocked,
      setIsDirty,
      setAllowedPaths,
    ]
  );

  useEffect(() => {
    if (blocker.state === 'blocked' && !isBlocked) {
      const isAutoNavigate = blocker.location?.state?.autoNavigate || false;

      if (isAutoNavigate) {
        // 자동 네비게이션인 경우 모달 없이 바로 네비게이션을 진행
        blocker.proceed();
        setIsBlocked(false);
        setIsDirty(false);
        setAllowedPaths([]); // allowedPaths 초기화
      } else {
        // allowedPaths에 포함되지 않은 경우에만 모달을 띄움
        if (!allowedPaths.includes(blocker.location.pathname)) {
          setIsBlocked(true);
          showLeaveWarningModal(
            () => {
              blocker.proceed();
              setIsBlocked(false);
              setIsDirty(false);
              setAllowedPaths([]); // allowedPaths 초기화
            },
            () => {
              blocker.reset();

              setIsBlocked(false);
              setAllowedPaths([]); // allowedPaths 초기화
            }
          );
        } else {
          blocker.proceed();
          setIsBlocked(false);
          setIsDirty(false);
          setAllowedPaths([]); // allowedPaths 초기화
        }
      }
    }
  }, [blocker, isBlocked, showLeaveWarningModal, isDirty, setIsBlocked, setIsDirty, allowedPaths]);

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     if (isDirty) {
  //       event.preventDefault();
  //       event.returnValue = '';
  //     }
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, [isDirty]);

  return { handleNavigate };
};
