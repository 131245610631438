import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { Pagination } from '@mui/material';
import { ESTIMATE_LIST_SIZE } from '@/constants/queryKey';

const Container = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '6rem',
}));

const MyPagination = styled(Pagination)(({ theme }) => ({
  '& .Mui-selected': {
    background: `${theme.color.blue01} !important`,
    color: theme.color.white01,
  },

  '& .MuiPaginationItem-root': {
    border: `1px solid ${theme.color.grey02}`,
    borderRadius: 0,
  },

  '& .MuiPaginationItem-page, .MuiPaginationItem-ellipsis': {
    border: 'none',
  },
}));

const CustomPagination = ({ arr, page, setPage }) => {
  const currentPage = parseInt(page, 10);

  const handlePaginationChange = useCallback(
    (event, value) => {
      setPage(value);
    },
    [setPage]
  );

  return (
    <Container>
      <MyPagination
        count={Math.ceil(arr?.totalCnt / ESTIMATE_LIST_SIZE)}
        shape="rounded"
        page={currentPage}
        onChange={handlePaginationChange}
      />
    </Container>
  );
};

export default CustomPagination;
