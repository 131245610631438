import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Button, LinearProgress } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  addCommaToNumber,
  displayValueOrNA,
  formatDate,
  useTranslateObjectText,
} from '@/utils/CustomFunctions';
import { usePostCertifiedEstimate, usePostEstimate } from '@/hooks/useApiHandlers';
import ConfirmationModal from '@/components/ui/modal/Modal';
import { useForm } from 'react-hook-form';
import CustomTextInput from '@/components/ui/input/CustomTextInput';
import { TranslatedTexts } from '@/components/ui/texts';
import { API_SUCCESS, REQUEST_FAIL } from '@/constants/apiResponseCode';
import useAlert from '@/hooks/useAlert';
import { AlertComponent } from '@/components/ui/alert';
import { zodResolver } from '@hookform/resolvers/zod';
import { zodAmount } from '@/lib/zod/zodValidation';
import { NA } from '@/constants/Constants';
import { usePreventLeave } from '@/hooks/usePreventLeave';
import { useIsDirty } from '@/context/IsDirtyContext';

const EstimateRequestPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const estimateType = params.get('type') || 'normal';

  const carData = {
    inbound_no: params.get('inbound_no'),
    brand_code: params.get('brand_code'),
    model_year: params.get('model_year'),
    model: params.get('model'),
    car_no: params.get('car_no'),
    car_fuel: params.get('car_fuel'),
    car_km: params.get('car_km'),
    transmission: params.get('transmission'),
    customer_name: params.get('customer_name'),
    vin: params.get('vin'),
  };

  const { monthName } = formatDate(carData?.model_year);
  const year =
    carData.model_year && carData.model_year.length === 6
      ? carData.model_year.substring(0, 4)
      : carData.model_year || NA;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { showAlert, handleShowAlert, alertMessage, handleCloseAlert, alertSeverity } = useAlert();
  const { setIsDirty } = useIsDirty();
  const { handleNavigate } = usePreventLeave();
  // const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty: formIsDirty },
  } = useForm({
    resolver: zodResolver(zodAmount),

    defaultValues: {
      amount: '',
    },
  });

  // formState의 isDirty가 변경될 때마다 전역 상태를 업데이트
  useEffect(() => {
    setIsDirty(formIsDirty);
  }, [formIsDirty, setIsDirty]);

  const normalEstimate = usePostEstimate();
  const certifiedEstimate = usePostCertifiedEstimate();

  const { mutate: postAmountForm } = useMemo(() => {
    return estimateType === 'normal' ? normalEstimate : certifiedEstimate;
  }, [estimateType, normalEstimate, certifiedEstimate]);

  const handleToggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const onSubmit = (data) => {
    // setIsLoading(true);

    const estimateData =
      estimateType === 'normal'
        ? {
            inbound_no: Number(carData.inbound_no),
            brand_code: carData.brand_code,
            model_year: carData.model_year.slice(0, 4),
            model: carData.model,
            amount: parseInt(data.amount.replace(/,/g, '')),
            customer_name: carData.customer_name,
            vin: carData.vin,
          }
        : {
            suchullo_car_key: Number(carData.inbound_no),
            amount: parseInt(data.amount.replace(/,/g, '')),
          };

    postAmountForm(estimateData, {
      onSuccess: (response) => {
        if (response.data.code === API_SUCCESS) {
          // setIsLoading(false);
          handleToggleModal();

          handleShowAlert('request_successful', 'success');
          setTimeout(() => {
            if (estimateType === 'normal') {
              navigate('/mypage', { state: { autoNavigate: true } });
            } else {
              navigate('/certified-car', { state: { autoNavigate: true } });
            }
          }, 1000);
        }
      },

      onError: (error) => {
        console.error(' error:', error);
        // setIsLoading(false);
        // handleToggleModal();

        handleShowAlert('request_failed', 'error');

        // if (error.code !== REQUEST_FAIL) {
        //   handleShowAlert('already_requested_vehicle', 'error');
        // } else {
        //   handleShowAlert('request_failed', 'error');
        // }
      },
    });
  };

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/[^\d]/g, '');
    setValue('amount', addCommaToNumber(value));
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(handleToggleModal)}>
        <TitleBox>
          <Title>
            <TranslatedTexts id="title" />
          </Title>
        </TitleBox>
        {/* <CarInfo>
          <CarName>{`${displayValueOrNA(carData?.model)}`}</CarName>
          <CarDetails>
            {`${displayValueOrNA(carData?.car_no)} · `}
            <TranslatedTexts id={monthName} />
            {` ${year} · ${addCommaToNumber(
              displayValueOrNA(carData?.car_km)
            )}Km · ${displayValueOrNA(carData?.car_fuel)} · ${displayValueOrNA(
              carData?.transmission
            )}`}
          </CarDetails>
        </CarInfo> */}
        {estimateType === 'normal' ? (
          <>
            <CarName>{`${displayValueOrNA(carData?.model)}`}</CarName>
            <CarDetails>
              {`${displayValueOrNA(carData?.car_no)} · `}
              <TranslatedTexts id={monthName} />
              {` ${year} · ${addCommaToNumber(
                displayValueOrNA(carData?.car_km)
              )}Km · ${displayValueOrNA(carData?.car_fuel)} · ${displayValueOrNA(
                carData?.transmission
              )}`}
            </CarDetails>
          </>
        ) : (
          <>
            <CarName>{`${displayValueOrNA(carData?.model)}`}</CarName>
            <CarDetails>
              {`${displayValueOrNA(carData?.car_no)} · `}
              <TranslatedTexts id={monthName} />
              {` ${year} · ${addCommaToNumber(
                displayValueOrNA(carData?.car_km)
              )}Km · ${displayValueOrNA(carData?.car_fuel)} · ${displayValueOrNA(
                carData?.transmission
              )}`}
            </CarDetails>
          </>
        )}
        <AmountSection>
          <Label>
            <TranslatedTexts id="amount_label" />
          </Label>
          <InputWrapper>
            <CustomTextInput
              register={register}
              name="amount"
              errors={errors}
              type="text"
              onChange={handleAmountChange}
              placeholder={useTranslateObjectText('please_enter_amount')}
              maxLength={11}
            />
            <KRWAdornment>KRW</KRWAdornment>
          </InputWrapper>
        </AmountSection>
        <Message>
          <TranslatedTexts id="message" />
        </Message>
        <ButtonGroup>
          <PreviousButton variant="outlined" onClick={() => handleNavigate(-1)}>
            <TranslatedTexts id="previous_button" />
          </PreviousButton>

          <RequestButton variant="contained" type="submit">
            <TranslatedTexts id="request_button" />
          </RequestButton>
        </ButtonGroup>
      </form>

      <ConfirmationModal
        open={isModalOpen}
        handleClose={handleToggleModal}
        handleConfirm={handleSubmit(onSubmit)}
      />
      {/* {isLoading && (
        <LinearProgress
          sx={{
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1000,
            height: '6px',
            backgroundColor: 'lightgrey', // 배경색 변경
            '& .MuiLinearProgress-bar': {
              backgroundColor: 'darkblue', // 진행 바 색상 변경
            },
          }}
        />
      )} */}
      <AlertComponent
        open={showAlert}
        message={alertMessage}
        severity={alertSeverity}
        onClose={handleCloseAlert}
      />
    </Container>
  );
};

const Container = styled.div(() => ({
  maxWidth: '70rem',
  margin: '10rem auto 22rem',
  textAlign: 'center',
}));

const TitleBox = styled.div(({ theme }) => ({
  height: '19.8rem',
  fontSize: '4.55rem',
  fontWeight: theme.fontWeight.bold,
}));

const Title = styled.div(() => ({
  padding: '12rem 0rem 4rem 0rem',
}));

const CarInfo = styled.div(() => ({
  padding: '4rem 0rem',
  height: '13.5rem',
}));

const CarName = styled.h2(({ theme }) => ({
  fontSize: '2.4rem',
  fontWeight: theme.fontWeight.bold,
  marginBottom: '1rem',
}));

const CarDetails = styled.p(() => ({
  fontSize: '1.4rem',
}));

const AmountSection = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '40px',
  width: '100%',
  height: '19.9rem',
  padding: '4rem 8rem',
}));

const Label = styled.label(({ theme }) => ({
  fontSize: '1.6rem',
  fontWeight: theme.fontWeight.medium,
  marginBottom: '1rem',
  marginRight: '100%',
}));

const InputWrapper = styled.div(() => ({
  position: 'relative',
  width: '60rem',
  height: '8rem',
}));

// const Input = styled.input(({ theme }) => ({
//   width: '100%',
//   height: '5.6rem',
//   backgroundColor: theme.color.white02,
//   borderRadius: '12px',
//   padding: '2rem',
//   fontSize: '1.6rem',

//   '&::placeholder': {
//     color: theme.color.grey01,
//   },
// }));

const KRWAdornment = styled.span(({ theme }) => ({
  position: 'absolute',
  right: '2rem',
  top: '35%',
  transform: 'translateY(-50%)',
  fontSize: '1.6rem',
  color: theme.color.grey07,
}));

const Message = styled.p(({ theme }) => ({
  fontSize: '1.8rem',
  color: theme.color.grey01,
  minHeight: '4.4rem',
  marginBottom: '40px',
}));

export const ButtonGroup = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '1rem',
  // marginBottom: '22rem',
}));

export const PreviousButton = styled(Button)(({ theme }) => ({
  color: theme.color.blue01,
  borderColor: theme.color.blue01,
  width: '18rem',
  height: '5.2rem',
  borderRadius: '26px',
  fontWeight: theme.fontWeight.bold,
}));

export const RequestButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.color.blue01,
  width: '18rem',
  height: '5.2rem',
  borderRadius: '26px',
  fontWeight: theme.fontWeight.bold,
  '&:hover': {
    backgroundColor: theme.color.blue01,
    color: theme.color.white02,
  },
}));

export default EstimateRequestPage;
