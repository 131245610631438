import React from 'react';
import styled from '@emotion/styled';
import { Checkbox } from '@mui/material';

const Container = styled.div(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  lineHeight: '1.6rem',
}));

const Label = styled.label(() => ({
  cursor: 'pointer',
  lineHeight: '2rem',
}));

const StyledCheckbox = styled(Checkbox)(() => ({
  flex: '1',
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  label: {
    fontSize: '14px',
  },
}));

const CustomCheckBox = ({ checked, watch, onChange, index, register, name, label }) => {
  const handleChange = (index, value) => {
    const currentValues = watch(name) || [];

    if (value) {
      return [...currentValues, index].sort(function (a, b) {
        return a - b;
      });
    } else {
      return currentValues
        .filter((item) => item !== index)
        .sort(function (a, b) {
          return a - b;
        });
    }
  };

  return register ? (
    <Container>
      <Checkbox
        {...register(name)}
        size="small"
        sx={{
          padding: '0 0.6rem 0 0',

          '&.Mui-checked': {
            color: '#0073ea',
          },
        }}
        id={name}
      />

      <Label htmlFor={name}>{label}</Label>
    </Container>
  ) : (
    <StyledCheckbox
      size="small"
      sx={{
        '&.Mui-checked': {
          color: '#0073ea',
        },
      }}
      checked={checked}
      onChange={(e) => onChange(handleChange(index, e.target.checked))}
    />
  );
};

export default CustomCheckBox;
