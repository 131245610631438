import React from 'react';
import styled from '@emotion/styled';
import { TranslatedTexts } from '../texts';

const Container = styled.div(({ theme, customHeight }) => ({
  width: '100%',
  minHeight: customHeight ? '32.8rem' : '9.8rem',
}));

export const ErrorMessage = styled.div(({ theme }) => ({
  fontSize: '1.4rem',
  color: theme.color.red01,
  minHeight: '1.7rem',
  textAlign: 'left',
  marginTop: '0.5rem',
}));

const StyledInput = styled.input(({ theme }) => ({
  width: '100%',
  fontSize: '1.6rem',
  padding: '2rem',
  borderRadius: '12px',
  backgroundColor: theme.color.white02,
  // color: theme.color.grey01,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledTextArea = styled.textarea(({ theme }) => ({
  width: '100%',
  height: '30rem',
  padding: '20px',
  borderRadius: '12px',
  backgroundColor: theme.color.white02,
  fontSize: '1.6rem',

  resize: 'none',
  outline: 'none',

  '&::placeholder': {
    fontFamily: 'Noto Sans KR !important',
    color: theme.color.grey01,
  },
}));

const CustomTextInput = ({
  register,
  name,
  placeholder,
  type = 'text',
  errors,
  customHeight,
  multiline,
  handlePhoneInput,
  onChange,
  maxLength,
}) => {
  return (
    <Container customHeight={customHeight}>
      {multiline ? (
        <StyledTextArea {...register(name)} placeholder={placeholder} customHeight={customHeight} />
      ) : (
        <StyledInput
          // {...register(name)}
          {...register(name, { onChange: onChange })}
          type={type}
          placeholder={placeholder}
          customHeight={customHeight}
          onInput={handlePhoneInput}
          // onChange={handleInputChange}
          maxLength={maxLength}
        />
      )}
      {errors?.[name] && (
        <ErrorMessage>
          <TranslatedTexts id={errors[name]?.message} />
        </ErrorMessage>
      )}
    </Container>
  );
};

export default CustomTextInput;
