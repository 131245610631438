import koreaIcon from '@/assets/icons/korea.png';
import americaIcon from '@/assets/icons/america.png';
import arabIcon from '@/assets/icons/arab.png';
import russiaIcon from '@/assets/icons/russia.png';
import spainIcon from '@/assets/icons/spain.png';

export const COUNTIRES = [
  { name: 'korea', code: 'ko', icon: koreaIcon },
  { name: 'america', code: 'en', icon: americaIcon },
  { name: 'arab', code: 'ar', icon: arabIcon },
  { name: 'russia', code: 'ru', icon: russiaIcon },
  { name: 'spain', code: 'es', icon: spainIcon },
];

export const MILEAGE_ITEMS = [
  { code: '1', name: '10,000 Km' },
  { code: '2', name: '50,000 Km' },
  { code: '3', name: '50,000 ~ 100,000 Km' },
  { code: '4', name: '100,000 ~ 200,000 Km' },
  { code: '5', name: '200,000 Km' },
];

export const FUEL_ITEMS = [
  { code: '1', name: 'Gasoline' },
  { code: '2', name: 'Diesel' },
  { code: '3', name: 'Electric' },
  { code: '4', name: 'Hydrogen' },
  { code: '5', name: 'Hybrid' },
  { code: '6', name: 'LPG' },
  { code: '7', name: 'LNG' },
  { code: '8', name: 'CNG' },
  { code: '99', name: 'Other' }, // N/A 값 추가
];

export const CERTIFIED_FUEL_ITEMS = [
  { code: '1', name: 'Gasoline' },
  { code: '2', name: 'Diesel' },
  { code: '3', name: 'Electric' },
  { code: '4', name: 'Hydrogen' },
  { code: '5', name: 'Hybrid' },
  { code: '6', name: 'LPG' },
  { code: '7', name: 'LNG' },
  { code: '8', name: 'CNG' },
];

export const TRANSMISSION_ITEMS = [
  { code: '1', name: 'Automatic (A/T)' },
  { code: '2', name: 'Manual (M/T)' },
  { code: '99', name: 'Other' }, // N/A 값 추가
];

export const CERTIFIED_TRANSMISSION_ITEMS = [
  { code: '1', name: 'Automatic (A/T)' },
  { code: '2', name: 'Manual (M/T)' },
];

export const DRIVE_ITEMS = [
  { code: '1', name: '2 Wheel Drive' },
  { code: '2', name: '4 Wheel Drive' },
  { code: '99', name: 'Other' }, // N/A 값 추가
];

export const CERTIFIED_DRIVE_ITEMS = [
  { code: '1', name: '2 Wheel Drive' },
  { code: '2', name: '4 Wheel Drive' },
];

export const OPTION_ITEMS = [
  { code: 'smartKeyChk', name: 'Smart Key' },
  { code: 'sunRoofChk', name: 'Sunroof' },
];

export const KEY_TO_TITLE = {
  brandCode: 'manufacturer',
  modelCode: 'model',
  bodyCode: 'body_type',
  modelDtlCode: 'detailed_model',
  year: 'year',
  kmRanges: 'mileage',
  fuelCode: 'fuel',
  transmissionCode: 'transmission',
  wheelCode: 'drive',
  options: 'option',
};

export const NA = 'N/A';

export const FUELS = ['gasoline', 'diesel', 'Hybrid', 'electric', 'LPG', 'Hydrogen', 'ETC'];
export const LANGUAGES = ['Korean', 'English', 'Spanish', 'Arabic', 'Russian'];
