import React, { useMemo } from 'react';
import { image } from '@/theme';
import styled from '@emotion/styled';

import { MAX_WIDTH_CONTAINER } from '@/constants/design';
import { TranslatedTexts } from '@/components/ui/texts';
import { useFetchEstimateCount } from '@/hooks/useApiHandlers';

const Container = styled.div(({ theme }) => ({
  background: theme.color.white02,
  padding: '9.2rem 0',
}));

const Wrapper = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  maxWidth: MAX_WIDTH_CONTAINER,
  width: '100%',
  margin: '0 auto',
}));

const WelcomeText = styled.div(() => ({
  fontSize: '2.4rem',
}));

const IconContainer = styled.div(() => ({
  display: 'flex',
  gap: '9.2rem',
}));

const IconItem = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '1.6rem',
  fontSize: '1.4rem',

  b: {
    fontSize: '1.6rem',
  },
}));

const MypageTop = () => {
  const { data: estimateCount } = useFetchEstimateCount();

  const ICON_SET = useMemo(() => {
    return [
      {
        id: 1,
        name: <TranslatedTexts id="vehicle_order" />,
        image: image.mypageIcon01,
        count: estimateCount?.vehicle_order_cnt,
      },
      {
        id: 2,
        name: <TranslatedTexts id="part_order" />,
        image: image.mypageIcon02,
        count: estimateCount?.parts_order_cnt,
      },
      {
        id: 3,
        name: <TranslatedTexts id="requested" />,
        image: image.mypageIcon03,
        count: estimateCount?.vehicle_order_cnt + estimateCount?.parts_order_cnt,
      },
    ];
  }, [estimateCount?.vehicle_order_cnt, estimateCount?.parts_order_cnt]);

  return (
    <Container>
      <Wrapper>
        {/* TODO: api */}
        <WelcomeText>
          <b>{estimateCount?.member_name}</b>

          <div>
            <TranslatedTexts id="welcome_to_visit" />
          </div>
        </WelcomeText>

        <IconContainer>
          {ICON_SET.map((icon) => {
            return (
              <IconItem key={icon.id}>
                <img src={icon.image} alt={icon.name} width={40} />

                <span>{icon.name}</span>

                <b>{estimateCount ? icon.count : 0}</b>
              </IconItem>
            );
          })}
        </IconContainer>
      </Wrapper>
    </Container>
  );
};

export default MypageTop;
