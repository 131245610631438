import React from 'react';
import styled from '@emotion/styled';
import { useAccordionData } from '@/hooks/useAccordionData';
import removeBtn from '@/assets/icons/remove-btn.png';
import { findItemName, getDisplayValue } from '@/utils/CustomFunctions';
const SelectBox = () => {
  const { accordionData } = useAccordionData();

  return (
    <Container>
      {accordionData.map(({ titleId, items, onSelectItem, selectedItem, selectedItems, type }) => {
        const displayValue = getDisplayValue(type, items, selectedItem, selectedItems);

        if (!displayValue) return null;

        if (type === 'multiSelect' && selectedItems && selectedItems.length > 0) {
          return selectedItems.map((code, index) => (
            <SelectedItem key={`${titleId}-${index}`}>
              <SelectedText>{findItemName(items, code)}</SelectedText>
              <button
                onClick={() => {
                  const newSelectedItems = selectedItems.filter((_, i) => i !== index);
                  onSelectItem(newSelectedItems);
                }}
              >
                <img src={removeBtn} alt="removeBtn" />
              </button>
            </SelectedItem>
          ));
        }

        return (
          <SelectedItem key={titleId}>
            <SelectedText>{displayValue}</SelectedText>
            <button
              onClick={() => {
                onSelectItem(type === 'doubleSelect' ? [null, null] : []);
              }}
            >
              <img src={removeBtn} alt="removeBtn" />
            </button>
          </SelectedItem>
        );
      })}
    </Container>
  );
};

export default SelectBox;

const Container = styled.div(({ theme }) => ({
  height: '5.8rem',
  margin: '0px 2rem',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  padding: '1.3rem 0',
  borderBottom: `2px solid ${theme.color.grey05}`,
  gap: '1.2rem',
}));

const SelectedItem = styled.div(({ theme }) => ({
  border: `1px solid ${theme.color.grey02}`,
  padding: '1rem',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '3.2rem',
  gap: '1.8rem',
}));

const SelectedText = styled.span({
  fontSize: '1.4rem',
});
