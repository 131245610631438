import React from 'react';
import styled from '@emotion/styled';
import { Box, Button, Modal } from '@mui/material';
import { FaCircleExclamation } from 'react-icons/fa6';
import { TranslatedTexts } from '../texts';

const ConfirmationModal = ({
  open,
  handleClose,
  handleConfirm,
  messageId1 = 'request_estimate_message_1',
  messageId2 = 'request_estimate_message_2',
  confirmButtonId = 'request_button_lowercase',
  cancelButtonId = 'cancel_button_lowercase',
  isLeaveWarning = false,
}) => {
  return (
    <StyledModal open={open} onClose={handleClose} disableScrollLock>
      <ModalBox>
        <IconWrapper>
          <FaCircleExclamation />
        </IconWrapper>
        <TextBox>
          <Text>
            <TranslatedTexts id={messageId1} />
          </Text>
          <Text>
            <TranslatedTexts id={messageId2} />
          </Text>
        </TextBox>
        <ButtonGroup>
          <StyledButton variant="outlined" onClick={handleClose}>
            <TranslatedTexts id={cancelButtonId} />
          </StyledButton>
          <StyledButton isrequest={!isLeaveWarning} variant="contained" onClick={handleConfirm}>
            <TranslatedTexts id={confirmButtonId} />
          </StyledButton>
        </ButtonGroup>
      </ModalBox>
    </StyledModal>
  );
};

export default ConfirmationModal;

const StyledModal = styled(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ModalBox = styled(Box)(({ theme }) => ({
  width: '46rem',
  height: '31.4rem',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.color.white01,
  borderRadius: '12px',
  padding: '4rem 2.5rem 2.5rem 2.5rem',
  justifyContent: 'space-between',
  textAlign: 'center',
}));

const IconWrapper = styled.div(({ theme }) => ({
  marginTop: '1.5rem',
  color: theme.color.blue01,

  '& svg': {
    fontSize: '5.6rem',
  },
}));

const StyledButton = styled(Button)(({ theme, isrequest }) => ({
  width: '20rem',
  height: '5.2rem',
  borderRadius: '8px',
  fontSize: '1.6rem',
  cursor: 'pointer',
  textTransform: 'capitalize',
  backgroundColor: isrequest ? theme.color.blue01 : theme.color.white01,
  border: isrequest ? 'none' : `1px solid ${theme.color.blue01}`,
}));

const ButtonGroup = styled.div(() => ({
  display: 'flex',
  gap: '1rem',
}));

const TextBox = styled.div(() => ({
  // marginTop: '4rem',
  // marginBottom: '5rem',
}));

const Text = styled.div(() => ({
  fontSize: '2rem',
}));
