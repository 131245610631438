// export const BASE_API_URL = '/novaapi/novaparts';
export const BASE_SEARCH_API_URL = '/novaapi/suchullo';

export const SEARCH_API_URL = {
  BRAND_LIST: `${BASE_SEARCH_API_URL}/brandlist`,
  MODEL_LIST: `${BASE_SEARCH_API_URL}/modellist`,
  BODY_LIST: `${BASE_SEARCH_API_URL}/bodylist`,
  DETAILED_MODEL_LIST: `${BASE_SEARCH_API_URL}/modeldtl_list`,
  SEARCH_LIST: `${BASE_SEARCH_API_URL}/car_search`,
  CAR_COUNT: `${BASE_SEARCH_API_URL}/car_count`,
  CAR_DETAIL: `${BASE_SEARCH_API_URL}/car_detail`,
};

export const POST_SIGN_UP_FORM_URL = '/api/v1/member/sign-up';
export const POST_SIGN_IN_FORM_URL = '/api/v1/member/sign-in';
export const POST_VEHICLE_REQUEST_URL = '/api/v1/request/vehicle';

export const REFRESH_TOKEN_API_URL = '/api/v1/member/token-refresh';
export const GET_ESTIMATE_COUNT_URL = '/api/v1/estimate/cnt';
export const GET_ESTIMATE_LIST_URL = '/api/v1/estimate';

// Certified Car
export const GET_CAR_LIST_URL = '/api/v1/car';
export const GET_CAR_COUNT_URL = '/api/v1/car/cnt';
export const POST_BID_CAR_LIST_URL = '/api/v1/car/bid';
