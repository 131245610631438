import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import { usePostSignUpForm } from '@/hooks/useApiHandlers';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormControlLabel, FormGroup } from '@mui/material';

import CountrySelector from '@/components/ui/selectbox/CountrySelector';
import { CustomCheckBox, CustomSelect, CustomTextInput } from '@/components/ui/input';
import { ErrorMessage } from '@/components/ui/input/CustomTextInput';
import { zodSignup } from '@/lib/zod/zodValidation';
import { TranslatedTexts } from '@/components/ui/texts';
import { useIntl } from 'react-intl';
import { useTranslateObjectText } from '@/utils/CustomFunctions';
import ConfirmationModal from '@/components/ui/modal/Modal';
import { useIsDirty } from '@/context/IsDirtyContext';

const Membership = () => {
  const intl = useIntl();

  const EMAIL_DOMAINS = [
    'gmail.com',
    'naver.com',
    'hanmail.net',
    useTranslateObjectText('direct_input'),
  ];
  const LANGUAGES = ['Korean', 'English', 'Spanish', 'Arabic', 'Russian'];
  const { setIsDirty } = useIsDirty();
  const {
    register,
    handleSubmit,
    watch,
    control,
    setError,
    formState: { errors, isDirty: formIsDirty },
  } = useForm({
    mode: 'onChange', // 입력값이 변경될때마다 유효성 검사
    resolver: zodResolver(zodSignup),
    // 초기값 설정
    defaultValues: {
      member_name: '',
      company_name: '',
      phone: '',
      email: '',
      domain: '',
      language: [],
      country: '',
      domainText: '',
    },
  });

  // formState의 isDirty가 변경될 때마다 전역 상태를 업데이트
  useEffect(() => {
    setIsDirty(formIsDirty);
  }, [formIsDirty, setIsDirty]);

  const { mutate: postVocForm } = usePostSignUpForm(setError);

  const handleFormSubmit = useCallback(
    (formData) => {
      const finalDomain = formData?.domainText ? formData?.domainText : formData?.domain;

      const finalData = {
        ...formData,
        email: formData?.email + '@' + finalDomain,
      };

      postVocForm(finalData);
    },
    [postVocForm]
  );

  return (
    <MembershipContainer>
      <Title>
        <TranslatedTexts id="membership" />
      </Title>

      <MembershipBox>
        <Subtitle>
          <TranslatedTexts id="membership_title" />
        </Subtitle>

        <Description>
          <TranslatedTexts id="membership_description" />
        </Description>

        <Form onSubmit={handleSubmit(handleFormSubmit)}>
          <FormSection>
            <Label>
              <TranslatedTexts id="requester_name_country" />
            </Label>

            <InputWrapper>
              <CustomTextInput
                register={register} // 초기값 연동
                name="member_name"
                placeholder={useTranslateObjectText('member_name_placeholder')}
                errors={errors}
              />

              <Slash>/</Slash>

              {/* 국가 선택 라이브러리 */}
              <SelectWrapper>
                <Controller
                  name="country"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <CountrySelector
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      // ref={ref}
                    />
                  )}
                  control={control}
                />

                {errors?.country && (
                  <ErrorMessage>
                    <TranslatedTexts id={errors?.country?.message} />
                  </ErrorMessage>
                )}
              </SelectWrapper>
            </InputWrapper>
          </FormSection>

          <FormSection>
            <Label>
              <TranslatedTexts id="company_name_label" />
            </Label>

            <CustomTextInput
              register={register}
              name="company_name"
              placeholder={useTranslateObjectText('company_name_placeholder')}
              errors={errors}
            />
          </FormSection>

          <FormSection>
            <Label>
              <TranslatedTexts id="phone_number_to_be_contacted" />
            </Label>

            <CustomTextInput
              register={register}
              name="phone"
              type="number"
              placeholder={useTranslateObjectText('phone_number_placeholder')}
              errors={errors}
              handlePhoneInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, '');
              }}
            />
          </FormSection>

          <FormSection>
            <Label>
              <TranslatedTexts id="email" />
            </Label>

            <InputWrapper>
              <CustomTextInput
                register={register}
                name="email"
                placeholder={useTranslateObjectText('email_placeholder')}
                errors={errors}
              />

              <Slash>@</Slash>

              <CustomSelect
                register={register}
                name="domain"
                arr={EMAIL_DOMAINS}
                errors={errors}
                emptyText={<TranslatedTexts id="select_email_address" />}
              />
            </InputWrapper>

            <div>
              {watch('domain') === useTranslateObjectText('direct_input') && (
                <CustomTextInput
                  register={register}
                  name="domainText"
                  placeholder={intl.formatMessage({
                    id: 'direct_input_placeholder',
                  })}
                  errors={errors}
                />
              )}
            </div>
          </FormSection>

          <FormSection>
            <LanguageContainer>
              <OptionLabel>
                <TranslatedTexts id="language" />
                <span>
                  (<TranslatedTexts id="language_label_description" />)
                </span>
              </OptionLabel>

              <Options>
                {LANGUAGES.map((label, index) => (
                  <Controller
                    key={label}
                    name="language"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        control={
                          <CustomCheckBox
                            checked={value?.includes(index) || false}
                            onChange={onChange}
                            index={index}
                            watch={watch}
                            name="language"
                          />
                        }
                        label={<TranslatedTexts id={label} />}
                      />
                    )}
                  />
                ))}
              </Options>

              {errors?.language && (
                <ErrorMessage>
                  <TranslatedTexts id={errors?.language?.message} />
                </ErrorMessage>
              )}
            </LanguageContainer>
          </FormSection>

          <FooterText>
            <TranslatedTexts id="sign_up_description" />
          </FooterText>

          <BtnBox>
            <SignUpButton type="submit">
              <TranslatedTexts id="sign_up" />
            </SignUpButton>
          </BtnBox>
        </Form>
      </MembershipBox>
    </MembershipContainer>
  );
};

export default Membership;

const MembershipContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '10rem auto 20rem',
  width: '100rem',
}));

const MembershipBox = styled.div(() => ({
  textAlign: 'center',
}));

export const Title = styled.h1(() => ({
  fontSize: '4.55rem',
  height: '20.7rem',
  padding: '12rem 0rem 4rem 0rem',
}));

export const Subtitle = styled.h2(() => ({
  fontSize: '2.4rem',
  lineHeight: '2.63',
}));

export const Description = styled.div(() => ({
  lineHeight: '1.63',
  textAlign: 'center',
}));

export const Form = styled.form(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4rem',
  textAlign: 'left',
  margin: '8rem auto 0',
  maxWidth: '60rem',
  minWidth: '60rem',
  width: '100%',
}));

export const FormSection = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  width: '100%',
  maxWidth: '60rem',
}));

export const Label = styled.label(({ theme }) => ({
  color: theme.color.grey07,
}));

export const InputWrapper = styled.div(() => ({
  display: 'flex',
  gap: '1rem',
}));

export const Slash = styled.span(() => ({
  width: '3rem',
  textAlign: 'center',
  marginTop: '2rem',
}));

export const SelectWrapper = styled.div(() => ({
  width: '100%',
  minHeight: '8rem',
}));

export const LanguageContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
}));

export const OptionLabel = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.4rem',
  marginBottom: '1rem',
  fontSize: '1.6rem',
  color: theme.color.grey07,

  span: {
    color: theme.color.grey09,
  },
}));

export const Options = styled(FormGroup)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  flexDirection: 'row',
}));

export const FooterText = styled.p(({ theme }) => ({
  fontSize: '1.8rem',
  color: theme.color.grey01,
  textAlign: 'center',
  marginTop: '10rem',
}));

export const BtnBox = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const SignUpButton = styled.button(({ theme }) => ({
  backgroundColor: theme.color.blue01,
  width: '18rem',
  height: '5.2rem',
  marginTop: '4rem',
  color: theme.color.white01,
  padding: '1,8rem 5.8rem',
  borderRadius: '26px',
  fontWeight: theme.fontWeight.bold,
  textTransform: 'uppercase',
}));
