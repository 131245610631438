import {
  CERTIFIED_DRIVE_ITEMS,
  CERTIFIED_FUEL_ITEMS,
  CERTIFIED_TRANSMISSION_ITEMS,
  DRIVE_ITEMS,
  FUEL_ITEMS,
  MILEAGE_ITEMS,
  OPTION_ITEMS,
  TRANSMISSION_ITEMS,
} from '@/constants/Constants';

export const accordionConfig = [
  {
    key: 'brandCode',
    itemsKey: 'brandNames',
    type: 'select',
    countKey: 'brandCount',
  },
  {
    key: 'modelCode',
    itemsKey: 'modelNames',
    type: 'select',
    countKey: 'modelCount',
    dependency: 'brandCode',
  },
  {
    key: 'bodyCode',
    itemsKey: 'bodyNames',
    type: 'select',
    countKey: 'bodyTypeCount',
    dependency: 'modelCode',
  },
  {
    key: 'modelDtlCode',
    itemsKey: 'modelDtlNames',
    type: 'select',
    countKey: 'modelDtlCount',
    dependency: 'bodyCode',
  },
  { key: 'year', type: 'doubleSelect' },

  { key: 'kmRanges', items: MILEAGE_ITEMS, type: 'multiSelect', countKey: 'kmCount' },

  { key: 'fuelCode', items: FUEL_ITEMS, type: 'multiSelect', countKey: 'fuelCount' },

  {
    key: 'transmissionCode',
    items: TRANSMISSION_ITEMS,
    type: 'multiSelect',
    countKey: 'transmissionCount',
  },

  { key: 'wheelCode', items: DRIVE_ITEMS, type: 'multiSelect', countKey: 'wheelCount' },

  { key: 'options', items: OPTION_ITEMS, type: 'multiSelect' },
];

export const dependencyMap = {
  modelCode: 'brandCode',
  bodyCode: 'modelCode',
  modelDtlCode: 'bodyCode',
};

// certified-car
export const accordionConfigs = [
  {
    key: 'brandCode',
    itemsKey: 'brandNames',
    type: 'select',
    countKey: 'brand',
  },
  {
    key: 'modelCode',
    itemsKey: 'modelNames',
    type: 'select',
    countKey: 'model',
    dependency: 'brandCode',
  },
  {
    key: 'bodyCode',
    itemsKey: 'bodyNames',
    type: 'select',
    countKey: 'body',
    dependency: 'modelCode',
  },
  {
    key: 'modelDtlCode',
    itemsKey: 'modelDtlNames',
    type: 'select',
    countKey: 'model_dtl',
    dependency: 'bodyCode',
  },
  { key: 'year', type: 'doubleSelect' },

  { key: 'kmRanges', items: MILEAGE_ITEMS, type: 'multiSelect', countKey: 'km' },

  { key: 'fuelCode', items: CERTIFIED_FUEL_ITEMS, type: 'multiSelect', countKey: 'fuel' },

  {
    key: 'transmissionCode',
    items: CERTIFIED_TRANSMISSION_ITEMS,
    type: 'multiSelect',
    countKey: 'transmission',
  },

  { key: 'wheelCode', items: CERTIFIED_DRIVE_ITEMS, type: 'multiSelect', countKey: 'wheel' },

  { key: 'options', items: OPTION_ITEMS, type: 'multiSelect' },
];
