import axios from 'axios';
import {
  GET_CAR_COUNT_URL,
  GET_CAR_LIST_URL,
  GET_ESTIMATE_COUNT_URL,
  GET_ESTIMATE_LIST_URL,
  POST_BID_CAR_LIST_URL,
  POST_SIGN_IN_FORM_URL,
  POST_SIGN_UP_FORM_URL,
  POST_VEHICLE_REQUEST_URL,
  SEARCH_API_URL,
} from '@/constants/apiUrls';
import Interceptor from '@/lib/axios/AxiosInterceptor';
import { ESTIMATE_LIST_SIZE } from '@/constants/queryKey';

const API_KEY = process.env.REACT_APP_SUCHULLO_KEY;

// NOTE : 브랜드 목록 API 호출 //
export const fetchBrandsHandler = async () => {
  const headers = {
    apikey: API_KEY,
  };

  const response = await axios.get(SEARCH_API_URL.BRAND_LIST, {
    headers,
  });

  return response.data;
};

// NOTE : 모델 목록 API 호출
export const fetchModelsHandler = async ({ queryKey }) => {
  const [, brandCode] = queryKey;
  const headers = {
    apikey: API_KEY,
  };

  const response = await axios.get(SEARCH_API_URL.MODEL_LIST, {
    headers,
    params: { brandCode },
  });

  return response.data;
};

// NOTE : 바디 목록 API 호출
export const fetchBodyHandler = async ({ queryKey }) => {
  const [, brandCode, modelCode] = queryKey;
  const headers = {
    apikey: API_KEY,
  };

  const response = await axios.get(SEARCH_API_URL.BODY_LIST, {
    headers,
    params: { brandCode, modelCode },
  });

  return response.data;
};

// NOTE : 디테일 모델 목록 API 호출
export const fetchDetailedModelsHandler = async ({ queryKey }) => {
  const [, brandCode, modelCode, bodyCode] = queryKey;
  const headers = {
    apikey: API_KEY,
  };

  const response = await axios.get(SEARCH_API_URL.DETAILED_MODEL_LIST, {
    headers,
    params: { brandCode, modelCode, bodyCode },
  });

  return response.data;
};

// NOTE : 상세정보 API 호출
export const fetchDetailHandler = async ({ queryKey }) => {
  const [, inboundNo] = queryKey;
  const headers = {
    apikey: API_KEY,
  };

  const response = await axios.get(SEARCH_API_URL.CAR_DETAIL, {
    headers,
    params: {
      inboundNo,
    },
  });
  return response.data;
};

// NOTE : 검색 API 호출
export const fetchSearchHandler = async (selections) => {
  const headers = {
    apikey: API_KEY,
  };

  let params = {};

  params = {
    brandCode: selections.brandCode || '',
    modelCode: selections.modelCode || '',
    bodyCode: selections.bodyCode || '',
    modelDtlCode: selections.modelDtlCode || '',
    ageMin: selections.ageMin || '',
    ageMax: selections.ageMax || '',
    kmRanges: selections.kmRanges || '',
    fuelCode: selections.fuelCode || '',
    transmissionCode: selections.transmissionCode || '',
    wheelCode: selections.wheelCode || '',
    smartKeyChk: selections.options?.includes('smartKeyChk') ? 'Y' : '',
    sunRoofChk: selections.options?.includes('sunRoofChk') ? 'Y' : '',
    page: selections.page || '1',
  };

  try {
    const response = await axios.get(SEARCH_API_URL.SEARCH_LIST, { headers, params });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// NOTE : 개수 요청
export const fetchCountHandler = async (selections) => {
  const headers = {
    apikey: API_KEY,
  };

  const params = {
    brandCode: selections.brandCode || '',
    modelCode: selections.modelCode || '',
    bodyCode: selections.bodyCode || '',
    modelDtlCode: selections.modelDtlCode || '',
    ageMin: selections.ageMin || '',
    ageMax: selections.ageMax || '',
    kmRanges: Array.isArray(selections.kmRanges)
      ? selections.kmRanges.join(',')
      : selections.kmRanges || '',
    fuelCode: Array.isArray(selections.fuelCode)
      ? selections.fuelCode.join(',')
      : selections.fuelCode || '',
    transmissionCode: Array.isArray(selections.transmissionCode)
      ? selections.transmissionCode.join(',')
      : selections.transmissionCode || '',
    wheelCode: Array.isArray(selections.wheelCode)
      ? selections.wheelCode.join(',')
      : selections.wheelCode || '',

    smartKeyChk: selections.options?.includes('smartKeyChk') ? 'Y' : '',
    sunRoofChk: selections.options?.includes('sunRoofChk') ? 'Y' : '',
  };

  try {
    const response = await axios.get(SEARCH_API_URL.CAR_COUNT, { headers, params });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// NOTE : 견적 요청
export const postEstimateRequest = async (estimateData) => {
  return await Interceptor.post(GET_ESTIMATE_LIST_URL, estimateData);
};

// NOTE: sign up
export const postSignUpForm = async (formData) => await axios.post(POST_SIGN_UP_FORM_URL, formData);

// NOTE: sign in
export const postSignInForm = async (formData) => await axios.post(POST_SIGN_IN_FORM_URL, formData);

// NOTE: 결제 내역
export const getEstimateCount = async () => await Interceptor.get(GET_ESTIMATE_COUNT_URL);

// NOTE: 견적 요청 목록
export const getEstimateList = async (page, status, filter) => {
  const { brandCode, modelCode, minYear, maxYear } = filter;
  return await Interceptor.get(
    `${GET_ESTIMATE_LIST_URL}?page=${page}&size=${ESTIMATE_LIST_SIZE}&bid_status=${status}&brand_code=${brandCode}&model_code=${modelCode}&min_year=${minYear}&max_year=${maxYear}`
  );
};

// NOTE: 차량 요청
export const postVehicleRequest = async (formData, isAuthenticated) => {
  if (isAuthenticated) {
    return await Interceptor.post(POST_VEHICLE_REQUEST_URL, formData);
  } else {
    return await axios.post(POST_VEHICLE_REQUEST_URL, formData);
  }
};

// NOTE: 차량 요청 목록
export const getVehicleRequestList = async (page, isAuthenticated, model) => {
  const url = `${POST_VEHICLE_REQUEST_URL}?page=${page}&size=${ESTIMATE_LIST_SIZE}${
    model ? `&model=${model}` : ''
  }`;

  if (isAuthenticated) {
    const { data } = await Interceptor.get(url);
    return data;
  } else {
    const { data } = await axios.get(url);
    return data;
  }
};

export const getVehicleRequestDetail = async (requestKey) =>
  await Interceptor.get(`${POST_VEHICLE_REQUEST_URL}/${requestKey}`);

// NOTE: 견적 요청 상태
export const getEstimateStatus = async (inboundNo) => {
  const response = await Interceptor.get(`${GET_ESTIMATE_LIST_URL}/${inboundNo}`);
  return response.data;
};

// Certified Car 검색 핸들러
export const fetchCertifiedCarSearchHandler = async (selections, page, size) => {
  const headers = {
    apikey: API_KEY,
  };

  const body = {
    brand_code: selections.brandCode || '',
    model_code: selections.modelCode || '',
    body_code: selections.bodyCode || '',
    model_dtl_code: selections.modelDtlCode || '',
    min_age: selections.ageMin || '',
    max_age: selections.ageMax || '',

    km_range: Array.isArray(selections.kmRanges)
      ? selections.kmRanges
      : selections.kmRanges
      ? selections.kmRanges.split(',')
      : '',
    fuel_code: Array.isArray(selections.fuelCode)
      ? selections.fuelCode
      : selections.fuelCode
      ? selections.fuelCode.split(',')
      : '',
    transmission_code: Array.isArray(selections.transmissionCode)
      ? selections.transmissionCode
      : selections.transmissionCode
      ? selections.transmissionCode.split(',')
      : '',
    wheel_code: Array.isArray(selections.wheelCode)
      ? selections.wheelCode
      : selections.wheelCode
      ? selections.wheelCode.split(',')
      : '',

    smartkey: selections.options?.includes('smartKeyChk') ? 'Y' : '',
    sunroof: selections.options?.includes('sunRoofChk') ? 'Y' : '',
    page: selections.page || '1',
    size: size.toString(),
  };

  try {
    const response = await axios.post(GET_CAR_LIST_URL, body, { headers });
    return response.data;
  } catch (error) {
    console.error('Certified Car 검색 오류:', error);
    throw error;
  }
};

// Certified Car 개수 요청
export const fetchCertifiedCarCountHandler = async (selections) => {
  const headers = {
    apikey: API_KEY,
  };

  const body = {
    brand_code: selections.brandCode || '',
    model_code: selections.modelCode || '',
    body_code: selections.bodyCode || '',
    model_dtl_code: selections.modelDtlCode || '',
    ...(selections.kmRanges && selections.kmRanges.length > 0 && { km_range: selections.kmRanges }),
    ...(selections.fuelCode &&
      selections.fuelCode.length > 0 && { fuel_code: selections.fuelCode }),
    ...(selections.transmissionCode &&
      selections.transmissionCode.length > 0 && { transmission_code: selections.transmissionCode }),
  };

  try {
    const response = await axios.post(GET_CAR_COUNT_URL, body, { headers });
    return response.data;
  } catch (error) {
    console.error('Certified Car 개수 요청 오류:', error);
    throw error;
  }
};

// Certified Car 상세정보
export const fetchCertifiedCarDetailHandler = async ({ queryKey }) => {
  const [, suchullo_car_key] = queryKey;
  const headers = {
    apikey: API_KEY,
  };

  const response = await axios.get(`${GET_CAR_LIST_URL}/${suchullo_car_key}`, {
    headers,
    params: {
      suchullo_car_key,
    },
  });
  return response.data;
};

// Certified Car 견적 요청
export const postCertifiedEstimateRequest = async (suchullo_car_key) => {
  return await Interceptor.post(POST_BID_CAR_LIST_URL, suchullo_car_key);
};

// NOTE: Certified Car 견적 요청 상태
export const getCertifiedCarEstimateStatus = async (suchullo_car_key) => {
  const response = await Interceptor.get(`${POST_BID_CAR_LIST_URL}/${suchullo_car_key}`);
  return response.data;
};
