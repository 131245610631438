import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { MypageBottom, MypageTop } from '@/_auth/pages/user/mypage/index';
import { useIsDirty } from '@/context/IsDirtyContext';
import { useBlocker } from 'react-router-dom';

const Container = styled.div(() => ({
  margin: '20rem 0 20rem 0',
}));

const MyPage = () => {
  return (
    <Container>
      {/* <MypageTop /> */}

      <MypageBottom />
    </Container>
  );
};

export default MyPage;
