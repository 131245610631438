import React from 'react';
import { Controller } from 'react-hook-form';
import { Dropdown, Select } from '@/components/ui/input/SearchBar';
import styled from '@emotion/styled';
import DatePicker from 'react-datepicker';

const StyledDatePicker = styled(DatePicker)(({ theme, isYearPicker, selected }) => ({
  border: isYearPicker ? 'none' : `1px solid ${theme.color.grey04}`,
  width: isYearPicker ? '24rem' : '9.6rem',
  height: isYearPicker ? '5.6rem' : '4.2rem',
  padding: '0px 1rem',
  fontSize: isYearPicker ? '1.6rem' : '1.4rem',
  textAlign: 'center',
  cursor: 'pointer',
  // color: theme.color.grey09,

  color: selected ? 'black' : theme.color.grey09, // selected가 있으면 색상을 검정으로

  caretColor: 'transparent',
  borderRadius: '8px',
  backgroundColor: isYearPicker ? theme.color.white02 : theme.color.white01,
  minHeight: isYearPicker ? '5.6rem' : 'none',
}));

export const CustomMiniSelect = ({ control, name, arr, emptyLabel, keyText, valueText }) => {
  return (
    <Controller
      name={name}
      render={({ field: { onChange, onBlur } }) => (
        <Dropdown onChange={onChange} onBlur={onBlur}>
          <Select>
            <option value="">{emptyLabel}</option>
            {arr?.map((item) => {
              return (
                <option value={item[keyText]} key={item[keyText]}>
                  {item[valueText]}
                </option>
              );
            })}
          </Select>
        </Dropdown>
      )}
      control={control}
    />
  );
};

export const CustomMiniYearSelect = ({
  name,
  control,
  placeholder,
  minDate,
  maxDate,
  isYearPicker,
}) => {
  return (
    <Controller
      name={name}
      render={({ field: { onChange, value } }) => (
        <StyledDatePicker
          showYearPicker
          dateFormat="yyyy"
          placeholderText={placeholder}
          selected={value ? new Date(Number(value), 0, 1) : ''}
          maxDate={maxDate ? maxDate : new Date()}
          minDate={minDate ? minDate : undefined}
          onChange={(e) => onChange(e.getFullYear())}
          isYearPicker={isYearPicker}
          onChangeRaw={(event) => event.preventDefault()} // 직접 입력 방지
          onInputClick={() => {}} // 클릭 시 달력 열기 유지
        />
      )}
      control={control}
    />
  );
};
