import styled from '@emotion/styled';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAccordionData } from '@/hooks/useAccordionData';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getItemCode, getItemName } from '@/utils/CustomFunctions';
import YearSelection from './YearSelection';
import { TranslatedTexts } from '../texts';

const renderContent = (
  type,
  items = [],
  selectedItems = [],
  onSelectItem,
  selectName,
  dependency
) => {
  switch (type) {
    case 'select':
      // 모델 목록이 없을 때 selectName을 "No models available"로 변경
      const effectiveSelectName =
        dependency && items.length === 0 ? (
          <TranslatedTexts id="no_models_available" />
        ) : (
          selectName
        );

      return (
        <div>
          <Dropdown>
            <Select onChange={(e) => onSelectItem(e.target.value)} value={selectedItems}>
              <option value="">{effectiveSelectName}</option>
              {/* 의존성이 충족되지 않으면 목록을 비움 */}
              {dependency
                ? items.map((item, index) => {
                    const itemCode = getItemCode(item);
                    return (
                      <option key={`${itemCode}-${index}`} value={itemCode}>
                        {getItemName(item)}
                      </option>
                    );
                  })
                : null}
            </Select>
          </Dropdown>
        </div>
      );

    default:
      return null;
  }
};

const SearchBar = () => {
  const { accordionData, selections, updateSelection } = useAccordionData();

  const brandData = accordionData.find((item) => item.titleId === 'brandCode');
  const modelData = accordionData.find((item) => item.titleId === 'modelCode');
  const yearData = accordionData.find((item) => item.titleId === 'year');

  return (
    <SearchContainer>
      <SearchWrapper>
        <Title>
          <span>
            <FormattedMessage id="easy_vehicle_search" defaultMessage="Easy vehicle search" />
          </span>

          <span>|</span>

          <span>
            <FormattedMessage
              id="search_subtitle"
              defaultMessage="Search for a vehicle quickly and easily."
            />
          </span>
        </Title>
        <InputBox>
          {renderContent(
            'select',
            brandData?.items,
            selections.brandCode,
            (value) => updateSelection('brandCode', value),
            <TranslatedTexts id="manufacturer" />,
            true // 브랜드 선택은 항상 가능하므로 의존성을 항상 true로
          )}
          {renderContent(
            'select',
            modelData?.items,
            selections.modelCode,
            (value) => updateSelection('modelCode', value),
            // 모델 바꿔줘야함
            <TranslatedTexts id="model" />,
            !!selections.brandCode // 브랜드가 선택되었을 때만 모델 목록을 보여줌
          )}
          <YearSelection
            selectedItems={yearData.selectedItems}
            onSelectItem={yearData.onSelectItem}
          />
        </InputBox>
      </SearchWrapper>
    </SearchContainer>
  );
};

export default SearchBar;

const SearchContainer = styled.div(() => ({
  width: '100%',
  height: '20rem',
  padding: '3rem 6rem 3rem 30rem',
  marginTop: '2rem',
}));

const SearchWrapper = styled.div(({ theme }) => ({
  width: '92rem',
  height: '14rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  borderRadius: '8px',
  border: `1px solid ${theme.color.grey06}`,
  boxShadow: '0px 8px 18px 0 rgba(0, 0, 0, 0.05)',
}));

const Title = styled.div(({ theme }) => ({
  color: theme.color.blue01,
  fontSize: '1.5rem',
  display: 'flex',
  gap: '0.5rem',

  '> span:nth-of-type(1)': {
    color: theme.color.blue01,
    fontWeight: theme.fontWeight.bold,
  },
  '> span:nth-of-type(2)': {
    color: theme.color.grey02,
    fontWeight: theme.fontWeight.medium,
  },
  '> span:nth-of-type(3)': {
    color: theme.color.black01,
    fontWeight: theme.fontWeight.medium,
  },
}));

const InputBox = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '4.2rem',
  gap: '2rem',
  color: theme.color.grey09,
}));

export const Select = styled.select(() => ({
  width: '100%',
  cursor: 'pointer',
}));

export const Dropdown = styled.div(({ theme, padding }) => ({
  padding: '1.1rem 1.2rem',
  border: `1px solid ${theme.color.grey04}`,
  borderRadius: '0.8rem',
  fontSize: '1.2rem',
  position: 'relative',
  height: '4.2rem',
  width: '18rem',
  backgroundColor: theme.color.white01,
}));
