import styled from '@emotion/styled';
import arrowDown from '@/assets/icons/arrow-down.svg';
import arrowTop from '@/assets/icons/arrow-up.svg';
import { RiCloseCircleFill } from 'react-icons/ri';
import { KEY_TO_TITLE } from '@/constants/Constants';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { findItemName, getDisplayValue, getItemCode, getItemName } from '@/utils/CustomFunctions';
import { TranslatedTexts } from '../texts';
import YearSelection from '../input/YearSelection';
import useAlert from '@/hooks/useAlert';
import { AlertComponent } from '../alert';

const Accordion = ({
  titleid,
  items = [],
  selectedItems = [],
  onSelectItem,
  selectedItem,
  disabled,
  type = '',
  countData = {},
  isOpen, // 외부에서 전달되는 isOpen 상태
  setIsOpen, // 외부에서 전달되는 setIsOpen 함수
}) => {
  // const [isOpen, setIsOpen] = useState(false);
  const displayTitle = KEY_TO_TITLE[titleid];
  const { showAlert, alertMessage, handleShowAlert, handleCloseAlert } = useAlert();

  const toggleAccordion = () => {
    if (!disabled && (!selectedItem || type === 'multiSelect')) {
      setIsOpen(!isOpen);
    } else if (disabled === true) {
      handleShowAlert('select_previous_item');
    }
  };

  // 드롭다운 가능
  // const toggleAccordion = () => {
  //   if (!disabled) {
  //     setIsOpen(!isOpen);
  //   } else if (disabled) {
  //     handleShowAlert('이전 항목을 선택해주세요.');
  //   }
  // };

  const selectItemHandler = (itemCode) => {
    if (type === 'multiSelect') {
      onSelectItem(
        selectedItems.includes(itemCode) // 중복 코드있으면 제거 추가
          ? selectedItems.filter((code) => code !== itemCode)
          : [...selectedItems, itemCode]
      );

      // setIsOpen(false);
    } else if (type === 'select') {
      onSelectItem(itemCode);
      setIsOpen(false);
    }
  };

  const renderContent = () => {
    switch (type) {
      case 'select':
      case 'multiSelect':
        return (
          <ul>
            {items.length === 0 ? (
              <List disabled>
                <TranslatedTexts id="no_models_available" />
              </List>
            ) : (
              items.map((item) => {
                const itemCode = getItemCode(item);
                let itemCount;

                if (itemCode !== 'smartKeyChk' && itemCode !== 'sunRoofChk') {
                  if (countData && Array.isArray(countData)) {
                    const countItem = countData.find((count) => {
                      const countCode =
                        count.brand_code ||
                        count.model_code ||
                        count.body_code ||
                        count.model_dtl_code ||
                        count.transmission ||
                        count.fuel ||
                        count.km_range ||
                        count.wheel ||
                        '';
                      return String(countCode) === itemCode;
                    });

                    itemCount = countItem ? countItem.cnt : 0;
                  } else if (countData && typeof countData === 'object') {
                    itemCount = countData[itemCode] || 0;
                  }
                }

                ///
                // else {
                //   itemCount = countData[itemCode] || 0;
                // }

                return (
                  <List key={itemCode} onClick={() => selectItemHandler(itemCode)}>
                    <ItemCheckbox>
                      {type === 'multiSelect' && (
                        <input
                          type="checkbox"
                          checked={selectedItems.includes(itemCode)}
                          onChange={() => selectItemHandler(itemCode)}
                        />
                      )}

                      <ListItem>
                        <div>{getItemName(item)}</div>
                        <div>{itemCount}</div>
                      </ListItem>
                    </ItemCheckbox>
                  </List>
                );
              })
            )}
          </ul>
        );

      case 'doubleSelect':
        return (
          <YearSelection
            selectedItems={[selectedItems[0], selectedItems[1]]}
            onSelectItem={onSelectItem}
          />
        );

      default:
        return null;
    }
  };

  const renderSelectedValues = () => {
    const displayValue = getDisplayValue(type, items, selectedItem, selectedItems, true);
    if (!displayValue) return null;

    // 다중선택 선택값 표시여부
    if (type === 'multiSelect') return null;

    return (
      <SelectedItem>
        <span>{displayValue}</span>

        {type === 'select' && (
          <RemoveButton
            onClick={() => {
              onSelectItem();
            }}
          >
            <RiCloseCircleFill />
          </RemoveButton>
        )}
        {type === 'doubleSelect' && (
          <RemoveButton
            onClick={() => {
              onSelectItem([null, null]); // doubleSelect의 경우 빈 배열을 전달
            }}
          >
            <RiCloseCircleFill />
          </RemoveButton>
        )}
      </SelectedItem>
    );
  };

  return (
    <AccordionContainer>
      {/* 아코디언 제목 */}
      {/* // disabled이 true일때, 클릭 하면 알럿창을띄우기 */}
      <AccordionHeader onClick={() => toggleAccordion()}>
        <TranslatedTexts id={displayTitle} />

        <img src={isOpen ? arrowDown : arrowTop} alt={isOpen ? 'Close' : 'Open'} />
      </AccordionHeader>

      {/* 선택한 값 렌더링 */}
      {renderSelectedValues()}

      {/* 타입에 따른 목록 렌더링 */}
      <AccordionContent isOpen={isOpen}>{renderContent()}</AccordionContent>
      <AlertComponent
        open={showAlert}
        message={alertMessage}
        severity="warning"
        onClose={handleCloseAlert}
      />
    </AccordionContainer>
  );
};
export default Accordion;

const AccordionContainer = styled.div(() => ({
  width: '100%',
}));

const AccordionHeader = styled.div(({ theme }) => ({
  cursor: 'pointer',
  width: '100%',
  height: '5.6rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '2.2rem 1.4rem 2.2rem',
  color: theme.color.black01,
  borderTop: `1px solid ${theme.color.grey02}`,
}));

const AccordionContent = styled.div(({ isOpen, theme }) => ({
  display: isOpen ? 'block' : 'none',
  maxHeight: '25rem',
  overflowX: 'hidden',
  overflowY: 'auto',
  lineHeight: '2.43',
  fontSize: '1.4rem',
  borderTop: `1px solid ${theme.color.grey02}`,
  color: theme.color.grey09,
}));

const SelectedItem = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: theme.color.grey10,
  padding: '1.2rem 1.4rem 1.2rem 1.4rem',
  fontSize: '1.4rem',
  height: '3.8rem',
  width: '100%',
}));

const RemoveButton = styled.button(({ theme }) => ({
  color: theme.color.grey11,
  width: '1.4rem',
  height: '1.4rem',
  fontSize: '1.6rem',
  borderRadius: '10px',
  paddingRight: '1.9rem',
}));

const ItemCheckbox = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
}));

export const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  border: `1px solid ${theme.color.grey04}`,
  width: '9.6rem',
  height: '3.8rem',
  padding: '0px 1rem',
  fontSize: '1.4rem',
  textAlign: 'center',
  cursor: 'pointer',
  color: theme.color.grey09,
  caretColor: 'transparent',
  borderRadius: '8px',
}));

const List = styled.li(({ theme, disabled }) => ({
  // cursor: 'pointer',
  padding: '0.5rem 1.4rem',
  color: theme.color.grey09,
  cursor: disabled ? 'not-allowed' : 'pointer',

  '&:hover': {
    backgroundColor: theme.color.grey08,
  },
}));

const ListItem = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}));

// // titleid: 섹션의 제목 ID.(다국어)
// // items: 섹션에 표시될 항목들.
// // selectedItems: 사용자가 선택한 항목들 (다중, 더블 선택 아이템들)
// // onSelectItem: 항목을 선택했을 때 호출되는 콜백 함수. (코드)
// // selectedItem: 선택된 단일 항목 (단일 선택 아이템).
// // disabled: 섹션이 비활성화 상태인지 여부.
// // type: 섹션의 타입 (단일 선택, 다중 선택, 더블 선택)
