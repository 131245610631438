import React, { useState } from 'react';
import styled from '@emotion/styled';
import { CustomPagination } from '@/components/ui/pagination';
import LockIcon from '@/assets/icons/lock.svg';
import { useGetVehicleList } from '@/hooks/useApiHandlers';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUserContext } from '@/context/AuthContext';
import { AlertComponent } from '@/components/ui/alert';
import { TranslatedTexts } from '@/components/ui/texts';
import { useTranslateObjectText } from '@/utils/CustomFunctions';
import { LoadingComponent, NoResultsComponent } from '@/components/shared/status';

const VehicleRequestListPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState('');

  const page = searchParams.get('page') || '1';
  const searchQuery = searchParams.get('model') || '';

  const { isAuthenticated } = useUserContext();
  const navigate = useNavigate();

  const { data: vehicleList, isLoading } = useGetVehicleList(page, searchQuery);
  const placeholderText = useTranslateObjectText('search_term_placeholder');

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearchParams({ model: searchTerm, page: '1' });
  };

  const handlePageChange = (newPage) => {
    setSearchParams((prev) => {
      // 현재 URL의 모든 검색 파라미터를 객체로 변환
      const currentParams = Object.fromEntries(prev);
      // 새로운 페이지 번호를 추가하거나 업데이트
      return { ...currentParams, page: newPage.toString() };
    });
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleItemClick = (item, event) => {
    if (item.view_yn === 0 || !isAuthenticated) {
      event.preventDefault();
      setAlertMessage('접근 권한이 없습니다');
      setAlertOpen(true);
    } else {
      // 접근 권한이 있는 경우 페이지 이동
      navigate(`/vehicle-detail_request/${item.request_key}`);
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <PageContainer>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <TitleBox>
            <Title>
              <TranslatedTexts id="vehicle_request_list_title" />
            </Title>

            <Subtitle>
              <TranslatedTexts id="vehicle_request_list_subtitle" />
            </Subtitle>
          </TitleBox>

          <form onSubmit={handleSubmit}>
            <SearchContainer>
              <Dropdown>
                <Select>
                  <option value="">Title</option>
                  <TranslatedTexts id="search_by_title" />
                </Select>
              </Dropdown>

              <SearchInput
                type="text"
                placeholder={placeholderText}
                value={searchTerm}
                onChange={handleSearchInputChange}
              />

              <SearchButton type="submit">
                <TranslatedTexts id="search_button" />
              </SearchButton>
            </SearchContainer>
          </form>

          <TotalCountBox>
            <TranslatedTexts id="total_count_label" />
            <TotalCount>{vehicleList?.totalCnt || 0}</TotalCount>
            <TranslatedTexts id="total_count_lists" />
          </TotalCountBox>

          <ListWrapper>
            {vehicleList?.data ? (
              vehicleList.data.length > 0 ? (
                vehicleList.data.map((item) => (
                  <ListItem
                    key={item.request_key}
                    onClick={(e) => handleItemClick(item, e)}
                    disabled={item.view_yn === 0 || !isAuthenticated}
                  >
                    <NoColumn>{item['@num := @num + 1']}</NoColumn>

                    <ModelColumn>
                      {item.view_yn === 0 && <Lock src={LockIcon} alt="LockIcon" />}
                      <Model>
                        <span>{item.brand_code_nm}</span>
                        <TranslatedTexts id="vehicle_requesting" />
                      </Model>
                    </ModelColumn>

                    <DateColumn>{item.ins_date}</DateColumn>
                    <WriterColumn>{item.requestor_name}</WriterColumn>
                  </ListItem>
                ))
              ) : (
                <NoResultsComponent />
              )
            ) : (
              <NoResultsComponent />
            )}
          </ListWrapper>

          <CustomPagination
            arr={vehicleList}
            page={page}
            shape="rounded"
            setPage={handlePageChange}
          />

          <AlertComponent
            open={alertOpen}
            message={alertMessage}
            severity="warning"
            onClose={handleAlertClose}
          />
        </>
      )}
    </PageContainer>
  );
};
export default VehicleRequestListPage;

const Dropdown = styled.div(({ theme }) => ({
  borderRadius: '0.8rem',
  position: 'relative',
  height: '4.8rem',
  width: '24rem',
  padding: '1.3rem 2rem',
  backgroundColor: theme.color.white01,
  border: `1px solid ${theme.color.grey03}`,
}));

export const Select = styled.select(() => ({
  width: '100%',
  cursor: 'pointer',
  fontSize: '1.6rem',
}));

export const PageContainer = styled.div(() => ({
  maxWidth: '120rem',
  margin: '0 auto',
  marginTop: '10rem',
  marginBottom: '20rem',
}));

export const TitleBox = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const Title = styled.div(() => ({
  textAlign: 'center',
  fontSize: '4.55rem',
  marginTop: '12rem',
  marginBottom: '4rem',
  fontWeight: 'bold',
}));

export const Subtitle = styled.p(() => ({
  textAlign: 'center',
  fontSize: '1.6rem',
  marginBottom: '6rem',
}));

export const SearchContainer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.color.grey13,
  padding: '2rem 8rem',
  borderRadius: '8px',
  width: '100%',
  height: '8.6rem',
  gap: '10px',
  marginBottom: '8rem',
}));

// export const Select = styled.select(({ theme }) => ({
//   border: `1px solid ${theme.color.grey03}`,
//   borderRadius: '8px',
//   width: '24rem',
//   height: '4.8rem',
//   padding: '0 1rem',
// }));

export const SearchInput = styled.input(({ theme }) => ({
  flexGrow: 1,
  margin: '0 0.1rem',
  padding: '1rem',
  border: `1px solid ${theme.color.grey03}`,
  borderRadius: '5px',
  height: '4.8rem',
}));

export const SearchButton = styled.button(({ theme }) => ({
  padding: '1rem 2rem',
  backgroundColor: theme.color.blue01,
  color: 'white',
  border: 'none',
  borderRadius: '8px',
  cursor: 'pointer',
  width: '12rem',
  height: '4.8rem',
}));

export const TotalCountBox = styled.div(() => ({
  marginBottom: '1rem',
  display: 'flex',
  width: '10.6rem',
  gap: '0.2rem',
}));

export const TotalCount = styled.p(({ theme }) => ({
  color: theme.color.blue01,
  fontWeight: theme.fontWeight.bold,
}));

export const Lock = styled.img(() => ({
  marginRight: '1.2rem',
  width: '2.4rem',
  height: '2.4rem',
}));

export const Model = styled.div(({ theme }) => ({
  // marginTop: '0.6rem',
  '& span': {
    fontWeight: theme.fontWeight.bold,
  },
}));

export const ListWrapper = styled.div(() => ({
  width: '100%',
  minHeight: '86rem',

  //   borderBottom: '1px solid',
  borderTop: '2px solid',
}));

export const ListItem = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '8.7rem',
  borderBottom: `1px solid ${theme.color.grey02}`,
  cursor: 'pointer',
  '&:last-child': {
    borderBottom: '1px solid', // 마지막 아이템에 2px 보더 적용
  },
}));

export const NoColumn = styled.div(() => ({
  width: '8rem',
  textAlign: 'center',
}));

export const ModelColumn = styled.div(() => ({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

export const DateColumn = styled.div(() => ({
  width: '14rem',
  textAlign: 'center',
}));

export const WriterColumn = styled.div(() => ({
  width: '10rem',
  textAlign: 'center',
  overflow: 'hidden',
  textOverflow: 'ellipsis', // ... 말줄임표
  WebkitBoxOrient: 'vertical', // ... 말줄임표
}));
