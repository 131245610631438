import React from 'react';
import styled from '@emotion/styled';

const StyledButton = styled.button(({ theme }) => ({
  padding: '0 3rem',
  background: theme.color.blue01,
  borderRadius: '0.8rem',
  color: theme.color.white01,
  fontSize: '1.2rem',
  fontWeight: theme.fontWeight.bold,
  lineHeight: '1.2rem',
  height: '100%',
  maxHeight: '4.2rem',
  minHeight: '4.2rem',
}));

const SmallButton = ({ children, onClick, type = 'button' }) => {
  return (
    <StyledButton type={type} onClick={onClick}>
      {children}
    </StyledButton>
  );
};

export default SmallButton;
