// NOTE: 공통 이미지
export const image = {
  // * images
  mypageIcon01: require('./assets/images/mypage-icon-01.png'),
  mypageIcon02: require('./assets/images/mypage-icon-02.png'),
  mypageIcon03: require('./assets/images/mypage-icon-03.png'),

  // * icons
  paginationArrow: require('./assets/icons/pagination-arrow.svg'),
};

// NOTE: 공통 컬러
export const color = {
  primary: '#CB88CB',
  grey01: '#999999',
  grey02: '#dbdbdb',
  grey03: '#cccccc',
  grey04: '#e8e8e8',
  grey05: '#ececec',
  grey06: '#fbfbfc',
  grey07: '#555555',
  grey08: '#f5f6f5',
  grey09: '#909090',
  grey10: '#fafafa',
  grey11: '#929292',
  grey12: '#666666',
  grey13: '#f8f8f8',
  black01: '#222222',
  red01: '#ff2347',
  white01: '#ffffff',
  white02: '#f5f5f5',
  blue01: '#0073ea',
};

export const AppTheme = {
  image,
  color,
  fontFamily: {
    // NOTE: 공통 폰트 스타일
  },
  fontWeight: {
    // NOTE: 공통 폰트 굵기
    thin: 100,
    extraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
  shadow: {},
  radius: {},
  typography: {
    htmlFontSize: 10,
  },
};

export default AppTheme;
