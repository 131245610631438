import { TranslatedTexts } from '@/components/ui/texts';
import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';

const LoadingComponent = () => {
  return (
    <LoadingContainer>
      <CircularProgress />
      <LoadingText>
        <TranslatedTexts id="loading" />
      </LoadingText>
    </LoadingContainer>
  );
};

const LoadingContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100vh',
}));

const LoadingText = styled.p(() => ({
  marginTop: '1rem',
  fontSize: '1.2rem',
}));

export default LoadingComponent;
