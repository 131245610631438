import styled from '@emotion/styled';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useFetchCertifiedCars } from '@/hooks/useCarData';
import { useCallback, useState } from 'react';
import { LoadingComponent, NoResultsComponent } from '@/components/shared/status';
import { SelectBox } from '@/components/ui/selectbox';
import CertifiedCarCard from './CertifiedCarCard';
import { MainPagenation } from '@/components/ui/pagination';
import { ITEMS_PER_PAGE } from '@/constants/queryKey';

const CertifiedCardContainer = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const selections = Object.fromEntries(searchParams);

  const [page, setPage] = useState(1);

  const { data: carsData, isLoading } = useFetchCertifiedCars(selections, page, ITEMS_PER_PAGE);

  const location = useLocation();

  const navigateToDetailHandler = (carNo) => {
    navigate(`/certified-car-detail/${carNo}`, {
      state: { from: `${location.pathname}${location.search}` },
    });
  };

  const updateSelection = useCallback(
    (key, value) => {
      setSearchParams((prevParams) => {
        if (value === null || value === undefined) {
          prevParams.delete(key);
        } else {
          prevParams.set(key, value);
        }
        return prevParams;
      });
    },
    [setSearchParams]
  );

  return (
    <Container>
      <SelectBox />
      {isLoading ? (
        <LoadingComponent />
      ) : !carsData || carsData.data.length === 0 ? (
        <NoResultsComponent />
      ) : (
        <>
          <Wrapper>
            {carsData.data.map((car) => (
              <CertifiedCarCard
                key={car.suchullo_car_key}
                car={car}
                onClick={() => navigateToDetailHandler(car.suchullo_car_key)}
              />
            ))}
          </Wrapper>

          <MainPagenation
            arr={carsData}
            page={page}
            setPage={setPage}
            updateSelection={updateSelection}
          />
        </>
      )}
    </Container>
  );
};

export default CertifiedCardContainer;

const Container = styled.div(() => ({
  width: '100%',
  maxWidth: '104rem',
  margin: '0 auto',
}));

const Wrapper = styled.div(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: '2rem',
  padding: '2.2rem 2rem',
}));
