import React, { useState } from 'react';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import styled from '@emotion/styled';
import { AlertComponent } from '../alert';
import useAlert from '@/hooks/useAlert';
import { useTranslateObjectText } from '@/utils/CustomFunctions';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  width: '100%',
  height: '6.4rem',
  backgroundColor: theme.color.white02,
  borderRadius: '8px',
  '& .MuiToggleButtonGroup-grouped': {
    margin: 7,
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: '8px',
    },
    '&:first-of-type': {
      borderRadius: '8px',
    },
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  width: '50%',
  textTransform: 'none',
  fontSize: '16px',
  color: theme.color.grey01,
  '&.Mui-selected': {
    backgroundColor: theme.color.white01,
    color: theme.color.blue01,
    '&:hover': {
      backgroundColor: theme.color.white01,
    },
  },
}));

const Toggle = () => {
  const [alignment, setAlignment] = useState('vehicle');
  const { showAlert, alertMessage, handleShowAlert, handleCloseAlert } = useAlert();

  const handleChange = (event, newAlignment) => {
    if (newAlignment === 'part') {
      handleShowAlert('alert_preparing');
    } else if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  return (
    <>
      <StyledToggleButtonGroup
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="Request Type"
      >
        <StyledToggleButton value="vehicle">
          {' '}
          {useTranslateObjectText('vehicle_request')}
        </StyledToggleButton>
        <StyledToggleButton value="part">
          {' '}
          {useTranslateObjectText('part_request')}
        </StyledToggleButton>
      </StyledToggleButtonGroup>
      <AlertComponent
        open={showAlert}
        message={alertMessage}
        severity="info"
        onClose={handleCloseAlert}
      />
    </>
  );
};

export default Toggle;
