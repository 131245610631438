import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import dayjs from 'dayjs';

import { LOCAL_STORAGE_AUTO_LOGIN, LOCAL_STORAGE_SUCHULLO_TOKEN } from '@/constants/storageKey';
import { REFRESH_TOKEN_API_URL } from '@/constants/apiUrls';
import { useUserContext } from '@/context/AuthContext';
import { useQueryClient } from 'react-query';

function LogoutUser() {
  const queryClient = useQueryClient();
  const { setIsAuthenticated } = useUserContext(); // 인증상태 설정 함수

  queryClient.removeQueries('userData');

  // 인증상태 설정
  setIsAuthenticated(false);

  localStorage.removeItem(LOCAL_STORAGE_SUCHULLO_TOKEN);
  sessionStorage.removeItem(LOCAL_STORAGE_SUCHULLO_TOKEN);
}

// 모든 Axios API 요청 전에 useRefreshToken 함수가 실행되도록 사용할 수 있음.
const useRefreshToken = async (config) => {
  // 자동 로그인 설정 여부 확인
  const isAutoLogin =
    localStorage.getItem(LOCAL_STORAGE_AUTO_LOGIN) &&
    localStorage.getItem(LOCAL_STORAGE_AUTO_LOGIN) === 'true';

  // 자동 로그인 설정에 따라 로컬 스토리지 또는 세션 스토리지에서 리프레시 토큰 가져오기
  const refreshToken = isAutoLogin
    ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_SUCHULLO_TOKEN)).refresh_token // 자동로그인 true => localStorage에서 refresh_token
    : JSON.parse(sessionStorage.getItem(LOCAL_STORAGE_SUCHULLO_TOKEN)).refresh_token; // 자동로그인 false => sessionStorage에서 refresh_token

  let token = isAutoLogin
    ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_SUCHULLO_TOKEN)).access_token // 자동로그인 true => localStorage에서 access_token
    : JSON.parse(sessionStorage.getItem(LOCAL_STORAGE_SUCHULLO_TOKEN)).access_token; // 자동로그인 false => sessionStorage에서 access_token

  const expireAt = jwtDecode(token);

  // 토큰이 만료되었고, refreshToken 이 저장되어 있을 때
  if (dayjs.unix(expireAt.exp).diff(dayjs()) < 1) {
    try {
      const res = await axios.post(REFRESH_TOKEN_API_URL, {
        refresh_token: refreshToken,
      });

      if (res.status === 400) {
        LogoutUser();

        return;
      }

      token = res.data.data;

      if (isAutoLogin) {
        localStorage.setItem(LOCAL_STORAGE_AUTO_LOGIN, 'true');

        localStorage.setItem(
          LOCAL_STORAGE_SUCHULLO_TOKEN,
          JSON.stringify({
            refresh_token: refreshToken,
            access_token: token,
          })
        );
      } else {
        localStorage.setItem(LOCAL_STORAGE_AUTO_LOGIN, 'false');

        sessionStorage.setItem(
          LOCAL_STORAGE_SUCHULLO_TOKEN,
          JSON.stringify({
            refresh_token: refreshToken,
            access_token: token,
          })
        );
      }
    } catch (error) {
      console.log(error);

      localStorage.removeItem(LOCAL_STORAGE_SUCHULLO_TOKEN);
      sessionStorage.removeItem(LOCAL_STORAGE_SUCHULLO_TOKEN);

      window.location.reload();
    }
  }

  config.headers.Authorization = `Bearer ${token}`;

  return config;
};

const useRefreshErrorHandle = () => {
  const { logoutUser } = useUserContext();

  logoutUser();
};

export { useRefreshToken, useRefreshErrorHandle };
