import { Snackbar, Alert, LinearProgress } from '@mui/material';
import { TranslatedTexts } from '../texts';

const AlertComponent = ({ open, message, severity = 'info', onClose, duration }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{ mt: '100px', mr: '80px' }}
      open={open}
      autoHideDuration={duration}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
        <TranslatedTexts id={message} />
      </Alert>
    </Snackbar>
  );
};

export default AlertComponent;
