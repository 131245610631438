export const ESTIMATE_COUNT_KEY = 'estimateCountKey';
export const ESTIMATE_LIST_KEY = 'estimateListKey';
export const VEHICLE_REQUEST_LIST = 'vehicleList';
export const VEHICLE_REQUEST_DETAIL = 'vehiclerequestdetail';
export const ESTIMATE_STATUS = 'estimateStatus';

export const BRAND_NAMES_KEY = 'brandNames';
export const MODEL_NAMES_KEY = 'modelNames';
export const BODY_NAMES_KEY = 'bodyNames';
export const MODEL_DTL_NAMES_KEY = 'modelDtlNames';
export const CAR_SEARCH_KEY = 'carSearch';
export const CAR_COUNT_KEY = 'carCount';
export const CAR_DETAIL_KEY = 'carDetail';
export const CAR_KEY = 'car';

// pagination size
export const ESTIMATE_LIST_SIZE = 10;

export const ITEMS_PER_PAGE = 20;
