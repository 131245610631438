import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useCarData } from './useCarData';
import { ensureArray } from '@/utils/CustomFunctions'; // 배열로 변경
import { accordionConfig } from '@/constants/accordionData';

// 초기 selections 상태를 설정하는 함수
const getInitialSelections = (searchParams) => ({
  brandCode: searchParams.get('brandCode') || '',
  modelCode: searchParams.get('modelCode') || '',
  bodyCode: searchParams.get('bodyCode') || '',
  modelDtlCode: searchParams.get('modelDtlCode') || '',
  ageMin: searchParams.get('ageMin') || '',
  ageMax: searchParams.get('ageMax') || '',
  kmRanges: ensureArray(searchParams.get('kmRanges')),
  fuelCode: ensureArray(searchParams.get('fuelCode')),
  transmissionCode: ensureArray(searchParams.get('transmissionCode')),
  wheelCode: ensureArray(searchParams.get('wheelCode')),
  options: ensureArray(searchParams.get('options')),
  page: parseInt(searchParams.get('page'), 10) || 1,
});

export const useAccordionData = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [openAccordions, setOpenAccordions] = useState({});
  const [selections, setSelections] = useState(() => getInitialSelections(searchParams));
  const closeAllAccordions = useCallback(() => setOpenAccordions({}), []);

  // 브랜드,모델,바디,상세,카운트 데이터
  const { apiData, countData } = useCarData(selections);

  // 파라미터값 get -> selections값 업데이트
  useEffect(() => {
    setSelections(getInitialSelections(searchParams));
  }, [searchParams]);

  const updateSelection = (key, value) => {
    let newSelections = { ...selections };

    if (key === 'year') {
      const [ageMin, ageMax] = value;
      if (ageMin === '') {
        delete newSelections.ageMin;
      } else {
        newSelections.ageMin = ageMin;
      }
      if (ageMax === '') {
        delete newSelections.ageMax;
      } else {
        newSelections.ageMax = ageMax;
      }
    } else {
      if (value === '' || (Array.isArray(value) && value.length === 0)) {
        delete newSelections[key];
      } else {
        newSelections[key] = value;
      }

      if (key === 'brandCode') {
        delete newSelections.modelCode;
        delete newSelections.bodyCode;
        delete newSelections.modelDtlCode;
        closeAllAccordions();
      } else if (key === 'modelCode') {
        delete newSelections.bodyCode;
        delete newSelections.modelDtlCode;
        closeAllAccordions();
      } else if (key === 'bodyCode') {
        delete newSelections.modelDtlCode;
        closeAllAccordions();
      }
    }

    // if (key !== 'page') {
    //   newSelections.page = 1;
    // }
    if (key !== 'page') {
      delete newSelections.page; // 페이지가 아닌 다른 선택사항이 변경되면 페이지 정보를 제거
    }

    setSelections(newSelections);

    const newSearchParams = new URLSearchParams();
    Object.entries(newSelections).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        const filteredValue = value.filter((item) => item !== '');
        if (filteredValue.length > 0) {
          newSearchParams.set(key, filteredValue.join(','));
        }
      } else if (value && value !== '') {
        newSearchParams.set(key, value);
      }
    });

    setSearchParams(newSearchParams);
  };

  const accordionData = accordionConfig.map((config) => {
    return {
      titleId: config.key,
      displayName: config.displayName,
      items: config.items || apiData[config.itemsKey], // 아코디언에 표시될 항목들  {modelCode: 16, modelName: '마이바흐 GLS클래스' 등}
      selectedItem: config.type === 'select' ? selections[config.key] : undefined, // 현재 선택된 항목
      selectedItems: ['multiSelect', 'doubleSelect'].includes(config.type) // 현재 선택된 항목들의 배열
        ? config.key === 'year'
          ? [selections.ageMin, selections.ageMax]
          : selections[config.key]
        : undefined,
      onSelectItem: (value) => {
        if (config.key === 'year') {
          updateSelection('year', value);
        } else {
          updateSelection(config.key, value);
        }
      },
      disabled: config.dependency ? !selections[config.dependency] : false, // 아코디언 섹션의 활성화/비활성화 상태를 결정
      type: config.type,
      countData: countData ? countData[config.countKey] : {},
      isOpen: openAccordions[config.key],
      setIsOpen: (isOpen) => setOpenAccordions((prev) => ({ ...prev, [config.key]: isOpen })),
    };
  });
  return { accordionData, selections, updateSelection, closeAllAccordions };
};
