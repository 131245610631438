import styled from '@emotion/styled';
import Accordion from './Accordion';
import { GrPowerReset } from 'react-icons/gr';
import { useAccordionData } from '@/hooks/useAccordionData';
import { useNavigate } from 'react-router-dom';
import { TranslatedTexts } from '../texts';

const LeftSidebar = () => {
  const navigate = useNavigate();
  // const [searchParams, setSearchParams] = useSearchParams();
  const { accordionData, updateSelection, closeAllAccordions } = useAccordionData();

  const handleReset = () => {
    navigate('/', { replace: true }); // URL 파라미터 초기화
    closeAllAccordions(); // 모든 아코디언을 닫음
  };

  const handleSelectItem = (key, value) => {
    updateSelection(key, value);
  };

  return (
    <SidebarContainer>
      <SidebarHeader>
        <Title>
          <TranslatedTexts id="search" />
        </Title>
        <ResetButton onClick={handleReset}>
          <div>
            <GrPowerReset />
          </div>
          <div>
            <TranslatedTexts id="reset" />
          </div>
        </ResetButton>
      </SidebarHeader>

      {/* 아코디언 객체 각각 전달 */}
      {accordionData.map(
        ({
          titleId, // 아코디언 섹션의 고유 ID (예: 'brandCode', 'modelCode' 등)
          items, // 해당 섹션에 표시될 항목들의 배열 (API에서 가져온 데이터 또는 상수 데이터)
          selectedItem, // 단일 선택 아코디언의 경우, 현재 선택된 항목 (예: 선택된 브랜드 코드)
          selectedItems, // 다중 선택 아코디언의 경우, 현재 선택된 항목들의 배열 (멀티,더블)
          onSelectItem, // 항목 선택 시 호출될 함수 (updateSelection)
          disabled, // 아코디언 섹션의 비활성화 여부 (의존성에 따라 결정됨)
          type, // 아코디언 유형 ('select', 'multiSelect', 'doubleSelect' 중 하나)
          countData, // 각 항목별 개수 데이터
          toggleAccordion,
          isOpen,
          setIsOpen,
        }) => (
          <Accordion
            key={titleId}
            titleid={titleId}
            items={items}
            selectedItem={selectedItem}
            selectedItems={selectedItems}
            onSelectItem={(value) => handleSelectItem(titleId, value)}
            disabled={disabled}
            type={type}
            countData={countData}
            toggleAccordion={toggleAccordion}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        )
      )}
    </SidebarContainer>
  );
};

export default LeftSidebar;

const SidebarContainer = styled.div(({ theme }) => ({
  width: '24rem',
  height: '100%',
  border: `1px solid ${theme.color.grey02}`,
}));

const SidebarHeader = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '22px 14px 22px',
  flex: '1',
}));

const Title = styled.div(({ theme }) => ({
  color: theme.color.blue01,
  fontWeight: theme.fontWeight.bold,
  fontSize: '18px',
}));

const ResetButton = styled.button(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '8px',
  fontSize: '12px',
}));
