import React from 'react';
import styled from '@emotion/styled';
import defaultCarImage from '@/assets/images/default.png';
import { addCommaToNumber, displayValueOrNA } from '@/utils/CustomFunctions';

const CertifiedCarCard = ({ car, onClick }) => {
  return (
    <CarCardWrapper onClick={() => onClick(car.suchullo_car_key)}>
      <CarImageWrapper>
        <CarImage
          src={car.img_url ? car.img_url : defaultCarImage}
          alt={`${car.brandName} ${car.modelName}`}
        />
      </CarImageWrapper>

      <CarInfoWrapper>
        <CarTitleText>{`${car.brand_name} ${car.model_dtl_name}`}</CarTitleText>

        <CarDescriptionWrapper>
          <CarDescriptionText>
            {` ${car.age} · ${displayValueOrNA(car.fuel_nm)} · ${displayValueOrNA(
              car.transmission_nm
            )}`}
          </CarDescriptionText>

          <CarDescriptionText>
            {`${addCommaToNumber(displayValueOrNA(car?.km))}Km`}
          </CarDescriptionText>
        </CarDescriptionWrapper>
      </CarInfoWrapper>
    </CarCardWrapper>
  );
};

export default CertifiedCarCard;

const CarCardWrapper = styled.button({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '23.5rem',
  height: '34rem',
  padding: 0,
});

const CarImageWrapper = styled.div({
  width: '100%',
  height: '18rem',
  borderRadius: '0.8rem',
});

const CarImage = styled.img(({ isDefaultImage }) => ({
  width: '100%',
  height: '18rem',
  objectFit: 'cover',
  borderRadius: '0.8rem',
  // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.01)',

  // boxShadow: isDefaultImage
  //   ? '0 4px 8px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.01)' // 그림자 추가
  //   : 'none', // 기본 이미지는 그림자 없음
}));

const CarInfoWrapper = styled.div({
  width: '100%',
  height: '18rem',
  padding: '1.4rem 1rem 0rem 1rem',
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
});

const CarTitleText = styled.div(({ theme }) => ({
  fontWeight: theme.fontWeight.medium,
  minHeight: '3.8rem',
  height: '3.8rem',
  overflow: 'hidden',
  // textOverflow: 'ellipsis', // ... 말줄임표
  // '-webkit-box-orient': 'vertical', // ... 말줄임표
}));

const CarDescriptionWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.2rem',
  minHeight: '3.4rem',
  height: '3.4rem',
}));

const CarDescriptionText = styled.div(({ theme }) => ({
  color: theme.color.grey07,
  fontSize: '1.4rem',
  height: '100%',
  overflow: 'hidden',
  // textOverflow: 'ellipsis', // ... 말줄임표
  // '-webkit-box-orient': 'vertical', // ... 말줄임표
}));
