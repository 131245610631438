import { NA } from '@/constants/Constants';
import { useIntl } from 'react-intl';

// 숫자 콤마표기
export function addCommaToNumber(number) {
  if (number === 0) {
    return '0';
  }

  if (number) {
    // 문자열인 경우 숫자로 변환
    const num = typeof number === 'string' ? parseFloat(number) : number;
    return isNaN(num) ? number : num.toLocaleString();
  }

  return 0;
}

// 언어 텍스트 추출
export function useTranslateObjectText(id) {
  const intl = useIntl();

  return intl.formatMessage({
    id: id,
  });
}

// 각 아이템의 고유 코드,이름 추출
export const getItemCode = (item) => {
  const code = item.code || item.brandCode || item.modelCode || item.bodyCode || item.modelDtlCode;
  return String(code); // 문자열로 변환하여 반환
};

export const getItemName = (item) =>
  item.name || item.brandName || item.modelName || item.bodyName || item.modelDtlName;

export const findItemName = (items, code) => {
  const item = items?.find((item) => getItemCode(item) === code);
  return item ? getItemName(item) : '';
};

// NOTE: 선택 값 view
export const getDisplayValue = (type, items, selectedItem, selectedItems) => {
  switch (type) {
    case 'select':
      return selectedItem ? findItemName(items, selectedItem) : '';

    case 'doubleSelect':
      if (selectedItems && selectedItems.length > 0) {
        if (selectedItems.length === 2 && selectedItems[0]) {
          return `${selectedItems[0]} ~ ${selectedItems[1]}`;
        }
        return selectedItems.filter(Boolean).join(', ');
      }
      return '';

    case 'multiSelect':
      if (selectedItems && selectedItems.length > 0) {
        return selectedItems
          .filter(Boolean)
          .map((code) => findItemName(items, code))
          .join(', ');
      }
      return '';

    default:
      return '';
  }
};

// // NOTE: 년도 형식 지정
export const formatDate = (dateString) => {
  // 6자리 문자열 (YYYYMM 형식)인지 검사
  if (/^\d{6}$/.test(dateString)) {
    const monthIndex = parseInt(dateString.substring(4, 6)) - 1; // 월 인덱스 계산

    // 월 이름 추출
    const monthName = new Date(0, monthIndex).toLocaleString('en-US', { month: 'long' });

    // 월 이름만 반환
    return { monthName };
  }

  return { monthName: 'blank' };
};

// NOTE: 배열로 변경 (selections 값)
export const ensureArray = (value) => {
  if (!value) return [];
  if (Array.isArray(value)) return value;
  return value.split(',').filter(Boolean); // 빈 문자열 제거
};

export const displayValueOrNA = (value) => value || NA;
