import React from 'react';
import Dropdown from '@/assets/icons/dropdown.png';
import styled from '@emotion/styled';
import { Select } from '@mui/material';
import { ErrorMessage } from '@/components/ui/input/CustomTextInput';
import { TranslatedTexts } from '../texts';

const Container = styled.div(() => ({
  minHeight: '8rem',
  position: 'relative',
  width: '100%',
}));

const commonStyles = (theme) => ({
  width: '100%',
  borderRadius: '12px',
  color: theme.color.grey01,
  fontSize: '16px',
  backgroundColor: theme.color.white02,
  appearance: 'none',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 20px center',
  border: 'none',
  paddingLeft: '0.6rem !important',
});

const StyledSelect = styled(Select)(({ theme }) => ({
  ...commonStyles(theme),
  '.MuiNativeSelect-icon': {
    right: '2rem',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

const CustomSelect = ({
  register,
  value,
  onChange,
  name,
  arr,
  errors,
  emptyText,
  isYearPicker,
  onBlur,
  ...props
}) => {
  return (
    <Container>
      <StyledSelect
        {...register(name)}
        native
        IconComponent={(props) => <img src={Dropdown} alt="dropdown" {...props} />}
      >
        <option value="" disabled>
          {emptyText}
        </option>

        {arr.map((domain) => (
          <option key={domain} value={domain}>
            {domain}
          </option>
        ))}
      </StyledSelect>

      {errors?.[name] && (
        <ErrorMessage>
          <TranslatedTexts id={errors[name]?.message} />
        </ErrorMessage>
      )}
    </Container>
  );
};

export default CustomSelect;
