import { COUNTIRES } from '@/constants/Constants';
import styled from '@emotion/styled';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import headerLogo from '@/assets/icons/header-logo.png';
import { useUserContext } from '@/context/AuthContext';
import { TranslatedTexts } from '@/components/ui/texts';
import { AlertComponent } from '@/components/ui/alert';
import useAlert from '@/hooks/useAlert';
import { useHandleNavigate, usePreventLeave } from '@/hooks/usePreventLeave';

function Header({ handleChangeLanguage }) {
  const { isAuthenticated, logoutUser, loginUser } = useUserContext();
  const { showAlert, handleShowAlert, handleCloseAlert } = useAlert();
  const { handleNavigate } = usePreventLeave();
  const navigate = useNavigate();
  const updateLanguageHandler = (code) => {
    handleChangeLanguage(code);
  };

  const handleHeaderNavigate = (path) => {
    handleNavigate(path, { state: { fromHeaderLink: true } });
  };

  return (
    <HeaderContainer>
      <HeaderWrapper>
        <HeaderLeft>
          <Logo onClick={() => handleHeaderNavigate('/')}>
            <HeaderLogo src={headerLogo} alt="header logo" />
          </Logo>

          <Nav>
            <button onClick={() => handleHeaderNavigate('/')}>
              <TranslatedTexts id="search" />
            </button>
            <button onClick={() => handleHeaderNavigate('/vehicle-request')}>
              <TranslatedTexts id="request" />
            </button>

            <button onClick={handleShowAlert}>
              <TranslatedTexts id="part" />
            </button>

            <button onClick={() => handleHeaderNavigate('/certified-car')}>
              <TranslatedTexts id="certified-car" />
            </button>
          </Nav>

          <AlertComponent
            open={showAlert}
            message="alert_preparing"
            severity="info"
            onClose={handleCloseAlert}
          />
        </HeaderLeft>

        <HeaderRight>
          <AccountNav>
            {isAuthenticated ? (
              <button onClick={logoutUser}>
                <TranslatedTexts id="logout" />
              </button>
            ) : (
              <button onClick={() => handleHeaderNavigate('/login')}>
                <TranslatedTexts id="login" />
              </button>
            )}

            {!isAuthenticated && (
              <>
                <span>|</span>

                <button onClick={() => handleHeaderNavigate('/membership')}>
                  <TranslatedTexts id="membership" />
                </button>
              </>
            )}

            {isAuthenticated && (
              <>
                <span>|</span>

                <button onClick={() => handleHeaderNavigate('/mypage')}>
                  <TranslatedTexts id="my_page" />
                </button>
              </>
            )}
          </AccountNav>

          <LanguageSelector>
            {COUNTIRES.map((country) => (
              <button key={country.name} onClick={() => updateLanguageHandler(country.code)}>
                <img src={country.icon} alt={country.name} />
              </button>
            ))}
          </LanguageSelector>
        </HeaderRight>
      </HeaderWrapper>
    </HeaderContainer>
  );
}

export default Header;

const HeaderContainer = styled.header(({ theme }) => ({
  position: 'fixed',
  width: '192rem',
  height: '10rem',
  top: 0,
  zIndex: '2',
  borderBottom: `1px solid ${theme.color.grey03}`,
  padding: '3.6rem 9rem 3.4rem 32rem',
  backgroundColor: `${theme.color.white01}`,
}));

const HeaderWrapper = styled.header(() => ({
  maxWidth: '147.8rem',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const HeaderLeft = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const Logo = styled.button(() => ({
  width: '16rem',
  height: '2.8rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const HeaderLogo = styled.img(() => ({
  height: '2.2rem',
}));

const Nav = styled.div(({ theme }) => ({
  width: '100%',
  height: '1.7rem',
  display: 'flex',
  fontWeight: `${theme.fontWeight.bold}`,
  marginLeft: '5rem',
  gap: '4rem',
  button: {
    '&:hover': {
      color: `${theme.color.red01}`,
    },
  },
}));

const HeaderRight = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '3rem',
}));

const AccountNav = styled.div(({ theme }) => ({
  height: '1.6rem',
  display: 'flex',
  gap: '0.8rem',
  justifyContent: 'space-between',
  fontSize: '1.5rem',
  color: `${theme.color.black01}`,
  fontWeight: theme.fontWeight.medium,

  span: {
    color: `${theme.color.grey03}`,
  },
}));

const LanguageSelector = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
