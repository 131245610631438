import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import Lock from '@/assets/icons/lock.svg';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { zodLogin } from '@/lib/zod/zodValidation';
import { CustomCheckBox } from '@/components/ui/input';
import { ErrorMessage } from '@/components/ui/input/CustomTextInput';
import { usePostSignInForm } from '@/hooks/useApiHandlers';
import { LOCAL_STORAGE_AUTO_LOGIN } from '@/constants/storageKey';
import { TranslatedTexts } from '@/components/ui/texts';
import { useTranslateObjectText } from '@/utils/CustomFunctions';

const RememberMeContainer = styled.div(() => ({
  margin: '2rem 0',
}));

const Login = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(zodLogin),
    defaultValues: {
      member_id: '',
      member_pw: '',
      rememberMe: false,
    },
  });

  const { mutate: postVocForm, isLoading } = usePostSignInForm(setError);

  const handleLoginFormSubmit = useCallback(
    (data) => {
      postVocForm(data);

      if (data.rememberMe) {
        localStorage.setItem(LOCAL_STORAGE_AUTO_LOGIN, 'true');
      } else {
        localStorage.setItem(LOCAL_STORAGE_AUTO_LOGIN, 'false');
      }
    },
    [postVocForm]
  );

  return (
    <LoginContainer>
      <LoginBox>
        <LockIcon>
          <img src={Lock} alt="LockIcon" />
        </LockIcon>

        <LoginTitleBox>
          <Title>
            <TranslatedTexts id="login" />
          </Title>

          <Subtitle>
            <TranslatedTexts id="login_description" />
          </Subtitle>
        </LoginTitleBox>

        <form onSubmit={handleSubmit(handleLoginFormSubmit)}>
          <InputBox>
            <Input
              {...register('member_id')}
              type="text"
              placeholder={useTranslateObjectText('login_id')}
            />

            {errors?.['member_id'] && (
              <ErrorMessage>
                <TranslatedTexts id={errors['member_id']?.message} />
              </ErrorMessage>
            )}
          </InputBox>

          <InputBox>
            <Input
              {...register('member_pw')}
              autoComplete="off"
              type="password"
              placeholder={useTranslateObjectText('login_password')}
            />

            {errors?.['member_pw'] && (
              <ErrorMessage>
                <TranslatedTexts id={errors['member_pw']?.message} />
              </ErrorMessage>
            )}
          </InputBox>

          <RememberMeContainer>
            <CustomCheckBox
              register={register}
              name="rememberMe"
              label={<TranslatedTexts id="maintaining_login_status" />}
            />
          </RememberMeContainer>

          <LoginButton type="submit" disabled={isLoading}>
            <TranslatedTexts id="login" />
          </LoginButton>
        </form>
      </LoginBox>
    </LoginContainer>
  );
};

export default Login;

const LoginContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
}));

const LoginBox = styled.div(() => ({
  textAlign: 'center',
  width: '42rem',
}));

const LoginTitleBox = styled.div(() => ({
  height: '5.4rem',
  margin: '0 1.8rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginBottom: '4rem',
}));

const LockIcon = styled.div(() => ({
  marginBottom: '3.2rem',
  width: '100%',
}));

const Title = styled.h1(() => ({
  fontSize: '2.4rem',
  letterSpacing: ' 0.48px',
  textTransform: 'uppercase',
}));

const Subtitle = styled.p(({ theme }) => ({
  fontSize: '1.4rem',
  color: theme.color.grey07,
  letterSpacing: '0.72px',
}));

const InputBox = styled.div(() => ({
  height: '8rem',
}));

const Input = styled.input(({ theme }) => ({
  width: '100%',
  height: '5.6rem',
  padding: '2rem',
  border: `1px solid ${theme.color.grey02}`,
  color: theme.color.grey09,
  // marginBottom: '1rem',
}));

const LoginButton = styled.button(({ theme, disabled }) => ({
  width: '100%',
  backgroundColor: disabled ? theme.color.grey09 : theme.color.blue01,
  height: '5.6rem',
  padding: '1rem',
  color: theme.color.white01,
}));
