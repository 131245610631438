import {
  getCertifiedCarEstimateStatus,
  getEstimateCount,
  getEstimateList,
  getEstimateStatus,
  getVehicleRequestDetail,
  getVehicleRequestList,
  postCertifiedEstimateRequest,
  postEstimateRequest,
  postSignInForm,
  postSignUpForm,
  postVehicleRequest,
} from '@/utils/Functions';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useNavigate } from 'react-router-dom';
import {
  ALREADY_EXIST_PHONE_NUMBER,
  API_SUCCESS,
  LOGIN_FAIL,
  REQUEST_FAIL,
} from '@/constants/apiResponseCode';
import { LOCAL_STORAGE_AUTO_LOGIN, LOCAL_STORAGE_SUCHULLO_TOKEN } from '@/constants/storageKey';
import {
  ESTIMATE_COUNT_KEY,
  ESTIMATE_LIST_KEY,
  VEHICLE_REQUEST_LIST,
  VEHICLE_REQUEST_DETAIL,
  ESTIMATE_STATUS,
} from '@/constants/queryKey';
import { useUserContext } from '@/context/AuthContext';
import { usePreventLeave } from './usePreventLeave';
import { TranslatedTexts } from '@/components/ui/texts';
import { useIntl } from 'react-intl';
import useAlert from './useAlert';

// NOTE: sign up
export function usePostSignUpForm(setError) {
  const navigate = useNavigate();
  const { handleNavigate } = usePreventLeave(); // usePreventLeave에서 handleNavigate 가져오기

  return useMutation({
    mutationFn: postSignUpForm,
    // onSuccess: (data) => {
    //   if (data.data?.code === API_SUCCESS) navigate('/login');
    // },

    onSuccess: (data) => {
      if (data.data?.code === API_SUCCESS) {
        // 로그인 페이지로 이동할 때 autoNavigate 플래그를 설정
        handleNavigate('/login', { state: { autoNavigate: true } });
      }
    },

    onError: (error) => {
      if (error.response.data.code === ALREADY_EXIST_PHONE_NUMBER) {
        setError('phone', {
          type: 'manual',
          message: '이미 가입된 전화번호입니다.',
        });
      }
    },
  });
}

// NOTE: sign in
export function usePostSignInForm(setError) {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useUserContext();
  const intl = useIntl(); // useIntl을 통해 메시지 포맷팅

  return useMutation({
    mutationFn: postSignInForm,
    onSuccess: (data) => {
      if (data?.data?.code === API_SUCCESS) {
        if (localStorage.getItem(LOCAL_STORAGE_AUTO_LOGIN) === 'true') {
          localStorage.setItem(
            LOCAL_STORAGE_SUCHULLO_TOKEN,
            JSON.stringify({
              refresh_token: data.data.data.refresh_token,
              access_token: data.data.data.access_token,
            })
          );
        } else {
          sessionStorage.setItem(
            LOCAL_STORAGE_SUCHULLO_TOKEN,
            JSON.stringify({
              refresh_token: data.data.data.refresh_token,
              access_token: data.data.data.access_token,
            })
          );
        }
        setIsAuthenticated(true);
        // 저장된 경로로 리다이렉트
        const redirectTo = sessionStorage.getItem('redirectAfterLogin') || '/';
        sessionStorage.removeItem('redirectAfterLogin'); // 사용 후 경로 삭제
        navigate(redirectTo);
        // navigate('/');
      }
    },
    // onError: (error) => {
    //   if (error.response.data?.code === LOGIN_FAIL) {
    //     setError('member_id', { message: '일치하는 아이디 또는 비밀번호가 없습니다.' });
    //   }
    // },

    onError: (error) => {
      if (error.response.data?.code === LOGIN_FAIL) {
        setError('member_id', {
          message: intl.formatMessage({ id: 'authError' }), // TranslatedTexts 대신 formatMessage 사용
        });
      }
    },
  });
}

// NOTE: get Estimate Count
export const useFetchEstimateCount = () => {
  return useQuery([ESTIMATE_COUNT_KEY], getEstimateCount, {
    select: (data) => data.data.data,
  });
};

// NOTE: get Estimate List
export const useFetchEstimateList = (page, status, filter) => {
  return useQuery(
    [ESTIMATE_LIST_KEY, page, status, filter],
    () => getEstimateList(page, status, filter),
    {
      select: (data) => data.data,
    }
  );
};

// NOTE: post Estimate Request
export const usePostEstimate = () => {
  const queryClient = useQueryClient();

  return useMutation(postEstimateRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ESTIMATE_COUNT_KEY });
      queryClient.invalidateQueries({ queryKey: ESTIMATE_LIST_KEY });
      queryClient.invalidateQueries({ queryKey: ESTIMATE_STATUS });
    },
  });
};

// NOTE: post Vehicle Request
export const usePostVehicle = () => {
  const { isAuthenticated } = useUserContext();
  const queryClient = useQueryClient();

  return useMutation((formData) => postVehicleRequest(formData, isAuthenticated), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: VEHICLE_REQUEST_LIST });
      queryClient.invalidateQueries(ESTIMATE_COUNT_KEY);
    },
  });
};

// NOTE: get Vehicle Request List
export const useGetVehicleList = (page, model) => {
  const { isAuthenticated } = useUserContext();

  return useQuery([VEHICLE_REQUEST_LIST, page, isAuthenticated, model], () =>
    getVehicleRequestList(page, isAuthenticated, model)
  );
};

// NOTE: get Vehicle Request Detail
export const useGetVehicleRequestDetail = (requestKey) => {
  return useQuery([VEHICLE_REQUEST_DETAIL, requestKey], () => getVehicleRequestDetail(requestKey));
};

export const useEstimateStatus = (inboundNo) => {
  return useQuery([ESTIMATE_STATUS, inboundNo], () => getEstimateStatus(inboundNo), {
    enabled: !!inboundNo,
    retry: false,
  });
};

export const useCertifiedCarEstimateStatus = (suchullo_car_key) => {
  return useQuery(
    ['CERTIFIED_CAR_ESTIMATE_STATUSS', suchullo_car_key],
    () => getCertifiedCarEstimateStatus(suchullo_car_key),
    {
      enabled: !!suchullo_car_key,
      retry: false,
    }
  );
};

// NOTE: post Certified Car Estimate Request
export const usePostCertifiedEstimate = () => {
  const queryClient = useQueryClient();

  return useMutation(postCertifiedEstimateRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ESTIMATE_COUNT_KEY });
      queryClient.invalidateQueries({ queryKey: ESTIMATE_LIST_KEY });
    },
  });
};
