import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUserContext } from '@/context/AuthContext';

const AuthLayout = () => {
  const { isAuthenticated } = useUserContext();

  return (
    <>
      {!isAuthenticated ? (
        <Navigate to="/login" />
      ) : (
        <div>
          <Outlet />
        </div>
      )}
    </>
  );
};

export default AuthLayout;
