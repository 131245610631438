import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { useSearchParams } from 'react-router-dom';
import { ITEMS_PER_PAGE } from '@/constants/queryKey';

const PaginationContainer = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '2rem',
}));

const PageButton = styled.button(({ active, theme, isNext }) => ({
  background: 'none',
  margin: '0 2px',
  padding: isNext ? '0.5rem 1rem 0.5rem 0.5rem' : '0.5rem',
  cursor: 'pointer',
  color: active ? theme.color.blue01 : theme.color.grey09,
  border: active ? `1px solid ${theme.color.blue01}` : 'none',
  borderRadius: '0',
  fontSize: '1.4rem',
  minWidth: '3rem',
  height: '3rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '&:disabled': {
    cursor: 'not-allowed',
    color: theme.color.grey04,
  },
}));

const NavigationButton = styled(PageButton)(({ isPrev, isDoubleArrow }) => ({
  fontSize: '14px',

  fontWeight: 'normal',
  '&::before': {
    content: isPrev ? (isDoubleArrow ? '"<<"' : '"<"') : '""',
    marginRight: isPrev ? '5px' : '0',
  },
  '&::after': {
    content: isPrev ? '""' : isDoubleArrow ? '">>"' : '" >"',
    marginLeft: isPrev ? '0' : '5px',
  },
}));

const MainPagenation = ({ arr, setPage, updateSelection }) => {
  // totalPages: 메인페이지 or certified 페이지 총 페이지 수

  const [searchParams] = useSearchParams();
  const page = parseInt(searchParams.get('page') || '1', 10);

  const totalItems = arr?.totalCnt || 0;

  const totalPages = arr?.totPage || Math.ceil(totalItems / ITEMS_PER_PAGE);

  const handlePaginationChange = useCallback(
    (newPage) => {
      if (newPage !== page) {
        setPage(newPage);
        updateSelection('page', newPage);
      }
    },
    [page, setPage, updateSelection]
  );

  const handleJumpPages = useCallback(
    (direction) => {
      const jump = direction * 10;
      const newPage = page + jump;

      if (newPage >= 1 && newPage <= totalPages) {
        handlePaginationChange(newPage);
      } else if (newPage > totalPages) {
        handlePaginationChange(totalPages);
      } else if (newPage < 1) {
        handlePaginationChange(1);
      }
    },
    [page, totalPages, handlePaginationChange]
  );

  // const totalPages = arr?.totPage || 0;
  const maxPagesToShow = 10;
  const currentPageGroup = Math.floor((page - 1) / maxPagesToShow);
  const startPage = currentPageGroup * maxPagesToShow + 1;
  const endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <PageButton key={i} active={i === page} onClick={() => handlePaginationChange(i)}>
          {i}
        </PageButton>
      );
    }
    return pageNumbers;
  };

  return (
    <PaginationContainer>
      <NavigationButton
        onClick={() => handleJumpPages(-1)}
        disabled={page <= 10}
        isPrev
        isDoubleArrow
      ></NavigationButton>

      <NavigationButton
        onClick={() => handlePaginationChange(page - 1)}
        disabled={page === 1}
        isPrev
        isNavigationButton
      >
        Previous
      </NavigationButton>
      {renderPageNumbers()}
      <NavigationButton
        onClick={() => handlePaginationChange(page + 1)}
        disabled={page === totalPages}
        isNavigationButton
      >
        Next
      </NavigationButton>
      <NavigationButton
        onClick={() => handleJumpPages(1)}
        disabled={page > totalPages - 10}
        isDoubleArrow
      ></NavigationButton>
    </PaginationContainer>
  );
};

export default MainPagenation;
