import styled from '@emotion/styled';
import React from 'react';
import footerLogo from '@/assets/icons/footer-logo.png';
// import kakaoIcon from '@/assets/icons/kakao-btn.png';

import googleIcon from '@/assets/icons/google.png';
import appleIcon from '@/assets/icons/apple.png';
// import { AlertComponent } from '@/components/ui/alert';
// import useAlert from '@/hooks/useAlert';
import whatsappIcon from '@/assets/icons/whatsapp-black.png';
import { TranslatedTexts } from '@/components/ui/texts';
import { useNavigate } from 'react-router-dom';
import useAlert from '@/hooks/useAlert';
import { AlertComponent } from '@/components/ui/alert';
import { usePreventLeave } from '@/hooks/usePreventLeave';

function Footer() {
  const { showAlert, handleShowAlert, handleCloseAlert } = useAlert();
  const navigate = useNavigate();
  const { handleNavigate } = usePreventLeave();

  const handleHeaderNavigate = (path) => {
    handleNavigate(path, { state: { fromHeaderLink: true } });
  };

  return (
    <>
      <FooterContainer>
        <FooterTop>
          <FooterNav>
            <FooterLeft>
              <FooterLogo src={footerLogo} alt="footer logo"></FooterLogo>

              <FooterLink>
                <button onClick={() => handleHeaderNavigate('/')}>
                  <TranslatedTexts id="home" />
                </button>

                <button onClick={() => handleHeaderNavigate('/agreement')}>
                  <TranslatedTexts id="agreement" />
                </button>

                <button onClick={() => handleHeaderNavigate('/privacy')}>
                  <TranslatedTexts id="privacy" />
                </button>
              </FooterLink>
            </FooterLeft>

            <FooterRight>
              {/* <button onClick={handleShowAlert}>
                <img src={kakaoIcon} alt="kakao icon" />
              </button>

              <AlertComponent
                open={showAlert}
                message="준비중입니다."
                severity="info"
                onClose={handleCloseAlert}
              /> */}

              <a
                href="https://api.whatsapp.com/send?phone=821091602804"
                target="_blank"
                rel="noreferrer"
              >
                <img src={whatsappIcon} alt="whatsappIcon" />
              </a>
            </FooterRight>
          </FooterNav>
        </FooterTop>

        <FooterBottom>
          <FooterSection>
            <FooterTitle>
              <TranslatedTexts id="information" />
            </FooterTitle>

            <BottomLink>
              <button onClick={() => handleHeaderNavigate('/')}>
                <TranslatedTexts id="search" />
              </button>

              <button onClick={() => handleHeaderNavigate('/vehicle-request')}>
                <TranslatedTexts id="car_request" />
              </button>

              <button onClick={handleShowAlert}>
                <TranslatedTexts id="part_order" />
                <AlertComponent
                  open={showAlert}
                  message="alert_preparing"
                  severity="info"
                  onClose={handleCloseAlert}
                />
              </button>
            </BottomLink>
          </FooterSection>

          <FooterSection>
            <FooterTitle>
              <TranslatedTexts id="download" />
            </FooterTitle>

            <BottomLink>
              <a
                href="https://play.google.com/store/apps/details?id=com.scroad.seller"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button>
                  <img src={googleIcon} alt="google icon" />
                  <TranslatedTexts id="android_google_play" />
                </button>
              </a>

              <a
                href="https://apps.apple.com/kr/app/%EC%88%98%EC%B6%9C%EB%A1%9C/id1542920378"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button>
                  <img src={appleIcon} alt="apple icon" />
                  <TranslatedTexts id="ios_app_store" />
                </button>
              </a>
            </BottomLink>
          </FooterSection>

          <Company>
            <FooterTitle>
              <TranslatedTexts id="company" />
            </FooterTitle>

            <ContactInfo>
              <TranslatedTexts id="company_name" />
              <Span>|</Span>
              <HighlightText>
                <TranslatedTexts id="ceo" />
              </HighlightText>
              Kim Chul
              <HighlightText>
                <Span>|</Span>

                <TranslatedTexts id="cpo" />
              </HighlightText>
              Kim Jinhoo
            </ContactInfo>

            <ContactInfo>
              <HighlightText>
                <TranslatedTexts id="registration" />
              </HighlightText>
              224-86-01875
              <HighlightText>
                <Span>|</Span>

                <TranslatedTexts id="email" />
              </HighlightText>
              suchullo@naver.com
              <HighlightText>
                <Span>|</Span>

                <TranslatedTexts id="tel" />
              </HighlightText>
              1522-3107
            </ContactInfo>

            <ContactInfo>
              <HighlightText>
                <TranslatedTexts id="fax" />
              </HighlightText>
              032-83-1264
              <HighlightText>
                <Span>|</Span>

                <TranslatedTexts id="address" />
              </HighlightText>
              <TranslatedTexts id="address_full" />
            </ContactInfo>

            <Copyright>
              <TranslatedTexts id="copyright" />
            </Copyright>
          </Company>
        </FooterBottom>
      </FooterContainer>
    </>
  );
}

export default Footer;

const FooterContainer = styled.footer(({ theme }) => ({
  width: '100%',
  fontSize: '1.4rem',
  zIndex: '3',
  position: 'relative',
  bottom: '0',

  backgroundColor: `${theme.color.black01}`,
}));

const FooterTop = styled.div(() => ({
  height: '8rem',
  display: 'flex',
  borderBottom: '1px solid #444',
}));

const FooterNav = styled.nav(() => ({
  width: '100%',
  maxWidth: '120rem',
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const FooterLeft = styled.div(() => ({
  width: '43.5rem',
  display: 'flex',
  justifyContent: 'space-between',
}));

const FooterLogo = styled.img(() => ({
  height: '2.2rem',
}));

const FooterLink = styled.div(({ theme }) => ({
  width: '22.9rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: `${theme.color.grey02}`,
  fontSize: '1.4rem',
  fontWeight: `${theme.fontWeight.regular}`,
}));

const FooterRight = styled.div(() => ({
  width: '9rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const FooterBottom = styled.div(({ theme }) => ({
  maxWidth: '120rem',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0 auto',
  padding: '6rem 0rem',
  lineHeight: '1.43',
  fontWeight: theme.fontWeight.regular,
}));

const Span = styled.span(() => ({
  color: '#6d6060',
  margin: '0 0.4rem',
}));

const BottomLink = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: `${theme.color.grey01}`,

  button: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.8rem',
  },
}));

const FooterSection = styled.div(() => ({
  minWidth: '30rem',
  // width: '100%',
  display: 'flex',
  flexDirection: 'column',
  lineHeight: '2.29',
}));

const Company = styled.div(() => ({
  height: '100%',
  width: '100%',
}));

const FooterTitle = styled.div(({ theme }) => ({
  color: `${theme.color.grey02}`,
  fontWeight: `${theme.fontWeight.bold}`,
  fontSize: '1.6rem',
  lineHeight: '2.5',
}));

const ContactInfo = styled.div(({ theme }) => ({
  color: `${theme.color.grey01}`,
}));

const Copyright = styled.div(({ theme }) => ({
  color: `${theme.color.grey01}`,
  marginTop: '2rem',
}));

const HighlightText = styled.span(({ theme }) => ({
  color: `${theme.color.grey02}`,
  marginRight: '0.2rem',
}));
