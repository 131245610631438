import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LOCAL_STORAGE_AUTO_LOGIN, LOCAL_STORAGE_SUCHULLO_TOKEN } from '@/constants/storageKey';
import { useQueryClient } from 'react-query';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem(LOCAL_STORAGE_SUCHULLO_TOKEN) ||
      !!sessionStorage.getItem(LOCAL_STORAGE_SUCHULLO_TOKEN)
  );

  useEffect(() => {
    if (
      localStorage.getItem(LOCAL_STORAGE_SUCHULLO_TOKEN) ||
      sessionStorage.getItem(LOCAL_STORAGE_SUCHULLO_TOKEN)
    ) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [navigate]);

  // NOTE: 로그아웃
  const logoutUser = useCallback(() => {
    setIsAuthenticated(false);

    localStorage.removeItem(LOCAL_STORAGE_SUCHULLO_TOKEN);
    sessionStorage.removeItem(LOCAL_STORAGE_SUCHULLO_TOKEN);

    localStorage.removeItem(LOCAL_STORAGE_AUTO_LOGIN);

    queryClient.removeQueries();

    navigate('/login');
  }, [navigate, queryClient]);

  const value = {
    isAuthenticated,
    setIsAuthenticated,
    logoutUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useUserContext = () => useContext(AuthContext);
