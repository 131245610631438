import { z } from 'zod';

// 공통 스키마 정의
const commonSchema = {
  phone: z
    .string()
    .min(1, { message: 'zod_enter_phone_number' })
    .regex(/^[0-9]+$/, { message: 'zod_check_phone_format' }),
  // email: z.string().min(1, { message: 'zod_enter_email' }),
  email: z
    .string()
    .min(1, { message: 'zod_enter_email' })
    .regex(/^[^\s@.]+$/, { message: 'zod_invalid_email' }),

  domain: z.string().min(1, { message: 'zod_select_domain' }),
  domainText: z.string().nullable(),
  language: z.array(z.number()).nonempty({
    message: 'zod_select_language',
  }),
};

const parseIntWithCommas = (value) => parseInt(value.replace(/,/g, ''), 10);

const validDomains = ['직접 입력', 'Прямой ввод', 'Direct Input', 'Entrada directa', 'إدخال مباشر'];

// zodVehicleRequest 스키마
export const zodVehicleRequest = z
  .object({
    model: z.string().min(1, { message: 'zod_enter_model' }),
    brand_code: z.string().min(1, { message: 'zod_select_manufacturer' }),
    min_year: z.preprocess(
      (val) => (val === null || val === undefined ? '' : String(val)),
      z.string().min(1, { message: 'zod_select_min_year' })
    ),
    max_year: z.preprocess(
      (val) => (val === null || val === undefined ? '' : String(val)),
      z.string().min(1, { message: 'zod_select_max_year' })
    ),
    fuel: z.array(z.number()).nonempty({ message: 'zod_select_fuel' }),
    min_purchase: z.coerce.string().min(1, { message: 'zod_enter_min_purchase' }),

    max_purchase: z.coerce
      .string()
      .min(1, { message: 'zod_enter_max_purchase' })
      .refine(
        (val) => {
          const cleanedPurchase = val.replace(/,/g, ''); // 쉼표 제거
          return cleanedPurchase.length <= 6; // 최대 길이 검증
        },
        { message: 'zod_max_length_6' }
      ),
    request: z.string().min(1, { message: 'zod_enter_request_details' }),
    requestor_name: z.string().min(1, { message: 'zod_enter_requester_name' }),
    requestor_country: z.string().refine((val) => val && val.trim() !== '', {
      message: 'zod_select_requester_country',
    }),
    ...commonSchema,
  })

  .refine(
    (data) => {
      if (validDomains.includes(data.domain)) {
        return !!data.domainText;
      }
      return true;
    },
    {
      message: 'zod_enter_domain_or_select',
      path: ['domainText'],
    }
  )
  .refine(
    (data) => {
      if (data.domainText) {
        // 간단한 도메인 형식 검사 (예: xxx.xxx)
        return /^[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/.test(data.domainText);
      }
      return true;
    },
    {
      message: 'zod_invalid_domain_format',
      path: ['domainText'],
    }
  )

  .refine(
    (data) => {
      if (data.min_purchase && data.max_purchase) {
        const minValue = parseIntWithCommas(data.min_purchase);
        const maxValue = parseIntWithCommas(data.max_purchase);
        return minValue <= maxValue;
      }
      return true;
    },
    {
      message: 'zod_min_purchase_less_than_max',
      path: ['max_purchase'],
    }
  )
  .refine(
    (data) => {
      if (data.min_purchase && data.max_purchase) {
        const minValue = parseIntWithCommas(data.min_purchase);
        const maxValue = parseIntWithCommas(data.max_purchase);
        return minValue <= maxValue;
      }
      return true;
    },
    {
      message: 'zod_max_purchase_greater_than_min',
      path: ['min_purchase'],
    }
  );

// zodSignup 스키마
export const zodSignup = z
  .object({
    country: z.string().min(1, { message: 'zod_select_country' }),
    member_name: z.string().min(1, { message: 'zod_enter_name' }),
    company_name: z.string().min(1, { message: 'zod_enter_company_name' }),
    ...commonSchema,
  })
  .refine(
    (data) => {
      if (validDomains.includes(data.domain)) {
        return !!data.domainText;
      }
      return true;
    },
    {
      message: 'zod_enter_domain_or_select',
      path: ['domainText'],
    }
  )
  .refine(
    (data) => {
      if (data.domainText) {
        // 간단한 도메인 형식 검사 (예: xxx.xxx)
        return /^[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/.test(data.domainText);
      }
      return true;
    },
    {
      message: 'zod_invalid_domain_format',
      path: ['domainText'],
    }
  );

// zodLogin 스키마
export const zodLogin = z.object({
  member_id: z.string().min(1, { message: 'zod_enter_id' }),
  member_pw: z.string().min(1, { message: 'zod_enter_password' }),
  rememberMe: z.boolean().nullable(),
});

// zodAmount 스키마
export const zodAmount = z.object({
  amount: z
    .string()
    .min(1, { message: 'zod_enter_amount' })
    .refine(
      (val) => {
        const rawAmount = val.replace(/,/g, ''); // 쉼표 제거
        return rawAmount.length <= 9; // 최대 길이 검증
      },
      { message: 'zod_max_length_9' }
    )
    .refine(
      (val) => {
        const num = parseInt(val.replace(/,/g, ''));
        return num > 0;
      },
      { message: 'amount_must_be_greater_than_0' }
    ),
});
