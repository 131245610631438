import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { MAX_WIDTH_CONTAINER } from '@/constants/design';
import { Purchase } from '@/_auth/pages/user/mypage/index';
import { addCommaToNumber } from '@/utils/CustomFunctions';
import { useFetchEstimateCount } from '@/hooks/useApiHandlers';
import { TranslatedTexts } from '@/components/ui/texts';
import LoadingComponent from '@/components/shared/status/LoadingComponent';
import useAlert from '@/hooks/useAlert';
import { AlertComponent } from '@/components/ui/alert';
import { useNavigate } from 'react-router-dom';

const Container = styled.header(() => ({
  maxWidth: MAX_WIDTH_CONTAINER,
  margin: '0 auto',
}));

const PageTitle = styled.header(({ theme }) => ({
  marginBottom: '2rem',
  fontSize: '3.6rem',
  fontWeight: theme.fontWeight.bold,
}));

const Wrapper = styled.div(() => ({
  display: 'flex',
  gap: '3rem',
}));

const LeftSection = styled.aside(({ theme }) => ({
  maxWidth: '18rem',
  width: '100%',
  minWidth: '18rem',
  padding: '4rem 1.6rem 6rem',
  border: `1px solid ${theme.color.grey04}`,
  fontSize: '1.4rem',
  color: theme.color.grey07,
  height: '55rem',

  '&>div': {
    marginBottom: '5rem',
  },

  header: {
    color: theme.color.black01,
    fontSize: '1.6rem',
    fontWeight: theme.fontWeight.black,
    marginBottom: '2rem',
  },
}));

const Customer = styled.ul(({ theme }) => ({
  color: theme.color.grey07,

  '&>div': {
    marginBottom: '2rem',
  },
}));

const MenuList = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem 0',

  '& > button': {
    textAlign: 'left',
  },
}));

const MiniTitle = styled.div(({ theme }) => ({
  color: theme.color.black01,
  fontWeight: theme.fontWeight.bold,
}));

const RightSection = styled.div(({ theme }) => ({
  // width: '100%',

  header: {
    display: 'flex',
    alignItems: 'center',
    gap: '2rem',
    padding: '1.6rem 0',
    fontSize: '1.6rem',
    color: theme.color.grey09,
    borderBottom: `0.2rem solid ${theme.color.black01}`,
  },
}));

const SectionTitle = styled.div(({ theme }) => ({
  fontSize: '2.4rem',
  fontWeight: theme.fontWeight.bold,
  color: theme.color.black01,
}));

const PurchaseTotal = styled.ul(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '105rem',

  li: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem 0',
    padding: '2.5rem',
  },

  span: {
    fontSize: '1.6rem',
    color: theme.color.grey09,
  },

  strong: {
    fontSize: '3.6rem',
    color: theme.color.black01,
  },
}));

const MypageBottom = () => {
  const { data: estimateCount, isLoading } = useFetchEstimateCount();
  const { showAlert, handleShowAlert, handleCloseAlert } = useAlert();
  const navigate = useNavigate();
  const purchaseTotal = useMemo(() => {
    if (estimateCount) {
      return [
        {
          id: 1,
          name: <TranslatedTexts id="total_estimate" />,
          value: estimateCount?.total_estimate_cnt,
        },
        {
          id: 2,
          name: <TranslatedTexts id="transaction" />,
          value: estimateCount?.transaction_cnt,
        },
        { id: 3, name: <TranslatedTexts id="completed" />, value: estimateCount?.completed_cnt },
        {
          id: 4,
          name: <TranslatedTexts id="vehicle_receivable" />,
          value: estimateCount?.vehicle_receivable_cnt,
        },
        {
          id: 5,
          name: <TranslatedTexts id="payment_amount" />,
          value: estimateCount?.payment_amount,
        },
      ];
    }
  }, [estimateCount]);

  return (
    <Container>
      <PageTitle>
        <TranslatedTexts id="my_page" />
      </PageTitle>

      <Wrapper>
        {/* NOTE: My service & Customer */}
        <LeftSection>
          <div>
            <header>
              <TranslatedTexts id="my_service" />
            </header>

            <MenuList>
              {/* <button>
                <TranslatedTexts id="total_quotation" />
              </button> */}

              <button onClick={() => navigate('/mypage')}>
                <TranslatedTexts id="vehicle_quotation" />
              </button>

              {/* <li>
                <TranslatedTexts id="part_quotation" />
              </li> */}
              <button onClick={handleShowAlert}>
                <TranslatedTexts id="part_quotation" />
                <AlertComponent
                  open={showAlert}
                  message="alert_preparing"
                  severity="info"
                  onClose={handleCloseAlert}
                />
              </button>
              <button onClick={() => navigate('/vehicle-request-list')}>
                <TranslatedTexts id="requested_list" />
              </button>
            </MenuList>
          </div>

          <Customer>
            <header>
              <TranslatedTexts id="customer" />
            </header>

            <div>
              <MiniTitle>
                <TranslatedTexts id="tel" />
              </MiniTitle>

              <a href="tel:+82-32-1522-3107">+82-32-1522-3107</a>
            </div>

            <div>
              <MiniTitle>
                <TranslatedTexts id="email" />
              </MiniTitle>

              <a href="mailto:suchullo@naver.com">suchullo@naver.com</a>
            </div>

            <div>
              <MiniTitle>
                <TranslatedTexts id="bank_info" />
              </MiniTitle>

              <ul>
                <li>신한(shinhan)</li>

                <li>140-013-030909</li>

                <li>(주) 수출로 (Suchullo)</li>
              </ul>
            </div>
          </Customer>
        </LeftSection>

        {/* NOTE: Purchase/Quote */}
        <RightSection>
          <header>
            <SectionTitle>
              <TranslatedTexts id="purchase_quote" />
            </SectionTitle>

            <span>
              <TranslatedTexts id="purchase_quote_description" />
            </span>
          </header>

          {isLoading ? (
            <LoadingComponent />
          ) : (
            <div>
              <PurchaseTotal>
                {purchaseTotal?.map((purchase) => {
                  return (
                    <li key={purchase.id}>
                      <span>{purchase.name}</span>

                      <strong>{addCommaToNumber(purchase.value)}</strong>
                    </li>
                  );
                })}
              </PurchaseTotal>
            </div>
          )}

          <Purchase />
        </RightSection>
      </Wrapper>
    </Container>
  );
};

export default MypageBottom;
