import React from 'react';
import styled from '@emotion/styled';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslateObjectText } from '@/utils/CustomFunctions';

const YearSelection = ({ selectedItems, onSelectItem }) => {
  const translatedLabels = {
    from: useTranslateObjectText('from_label'),
    to: useTranslateObjectText('to_label'),
  };

  const YEAR = [translatedLabels.from, translatedLabels.to];

  return (
    <DoubleSelectContainer>
      {YEAR.map((label, index) => (
        <React.Fragment key={label}>
          <CustomDatePickerWrapper>
            <StyledDatePicker
              selected={selectedItems[index] ? new Date(selectedItems[index], 0, 1) : null}
              onChange={(date) => {
                const updatedItems = [...selectedItems];
                updatedItems[index] = date ? date.getFullYear().toString() : '';
                onSelectItem(updatedItems);
              }}
              showYearPicker
              dateFormat="yyyy"
              maxDate={
                index === 0
                  ? selectedItems[1]
                    ? new Date(selectedItems[1], 0, 1)
                    : new Date()
                  : new Date()
              }
              minDate={
                index === 1
                  ? selectedItems[0]
                    ? new Date(selectedItems[0], 0, 1)
                    : undefined
                  : undefined
              }
              placeholderText={label}
              portalId="root-portal"
              onChangeRaw={(event) => event.preventDefault()} // 직접 입력 방지
              onInputClick={() => {}} // 클릭 시 달력 열기 유지
            />
          </CustomDatePickerWrapper>
          {index === 0 && <div>~</div>}
        </React.Fragment>
      ))}
    </DoubleSelectContainer>
  );
};

export default YearSelection;

const DoubleSelectContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '0.6rem',
  alignItems: 'center',
  padding: '1.2rem',
}));

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  border: `1px solid ${theme.color.grey04}`,
  width: '9.6rem',
  height: '3.8rem',
  padding: '0px 1rem',
  fontSize: '1.4rem',
  textAlign: 'center',
  cursor: 'pointer',
  color: theme.color.grey09,
  caretColor: 'transparent',
  borderRadius: '8px',
}));

const CustomDatePickerWrapper = styled.div(() => ({
  position: 'relative',
  width: '100%',
}));
