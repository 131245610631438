import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useFetchEstimateList } from '@/hooks/useApiHandlers';
import { CustomPagination } from '@/components/ui/pagination';
import { TranslatedTexts } from '@/components/ui/texts';
import { addCommaToNumber } from '@/utils/CustomFunctions';
import { Link } from 'react-router-dom';
import { LoadingComponent, NoResultsComponent } from '@/components/shared/status';

const TableContainer = styled.div(() => ({
  minHeight: '50rem',
}));

const TableHeader = styled.div(() => ({
  display: 'flex',
}));

const HeaderColumn = styled.div(({ flex, theme }) => ({
  flex: flex,
  padding: '1.4rem',
  textAlign: 'center',
  background: theme.color.white02,
  border: `1px solid ${theme.color.grey04}`,
  color: theme.color.grey07,
}));

const TableRow = styled.div(() => ({
  display: 'flex',
}));

const BodyColumn = styled.div(({ flex, theme, align }) => ({
  padding: '1.4rem',
  textAlign: align ? align : 'center',
  flex: flex,
  border: `1px solid ${theme.color.grey04}`,
}));

const DetailLink = styled(Link)(({ theme }) => ({
  display: 'inline',
  color: theme.color.grey09,
  borderBottom: `1px solid ${theme.color.grey09}`,
  cursor: 'pointer',
}));

const PurchaseTable = ({ statusCode, filter }) => {
  const [page, setPage] = useState(1);

  const { data: estimateList, isFetching } = useFetchEstimateList(page, statusCode, filter);

  const PURCHASE_TABLE_HEADER = [
    { id: 1, name: <TranslatedTexts id="estimate_date" />, flex: 2 },
    { id: 2, name: <TranslatedTexts id="year" />, flex: 1 },
    { id: 3, name: <TranslatedTexts id="model_detailed_model" />, flex: 4 },
    { id: 4, name: <TranslatedTexts id="view_details" />, flex: 2 },
    { id: 5, name: <TranslatedTexts id="estimated_amount" />, flex: 2 },
    // { id: 6, name: <TranslatedTexts id="status" />, flex: 2 },
  ];

  return (
    <div>
      <TableHeader>
        {PURCHASE_TABLE_HEADER.map((header) => {
          return (
            <HeaderColumn key={header.id} flex={header.flex}>
              {header.name}
            </HeaderColumn>
          );
        })}
      </TableHeader>

      {isFetching ? (
        <LoadingComponent />
      ) : estimateList?.data?.length === 0 ? (
        <NoResultsComponent />
      ) : (
        <>
          <TableContainer>
            {estimateList?.data?.map((contents) => {
              return (
                <TableRow key={contents.inbound_no}>
                  <BodyColumn flex={2}>{contents.ins_date}</BodyColumn>
                  <BodyColumn flex={1}>{contents.model_year}</BodyColumn>
                  <BodyColumn flex={4}>{contents.model}</BodyColumn>

                  <BodyColumn flex={2}>
                    <DetailLink to={`/car/${contents.inbound_no}`}>
                      <TranslatedTexts id="view" />
                    </DetailLink>
                  </BodyColumn>
                  <BodyColumn flex={2} align="right">
                    <b>{addCommaToNumber(contents.amount)} KRW</b>
                  </BodyColumn>
                  {/* <BodyColumn flex={2}>
                    {contents.bid_status_nm} ({contents.bid_status})
                  </BodyColumn> */}
                </TableRow>
              );
            })}
          </TableContainer>

          <CustomPagination arr={estimateList} page={page} setPage={setPage} shape="rounded" />
        </>
      )}
    </div>
  );
};

export default PurchaseTable;
