import React from 'react';
import styled from '@emotion/styled';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetVehicleRequestDetail } from '@/hooks/useApiHandlers';
import { TranslatedTexts } from '@/components/ui/texts';
import { FormControlLabel, FormGroup } from '@mui/material';
import { CustomCheckBox } from '@/components/ui/input';
import {
  FormSection,
  InputWrapper,
  LanguageContainer,
  OptionLabel,
  Slash,
} from '../user/Membership';

import BackgroundImg from '@/assets/images/background-img.png';
import { ListButton } from './CarDetailPage';
import { LoadingComponent, NoResultsComponent } from '@/components/shared/status';
import {
  CheckboxWrapper,
  FormContainer,
  LanguagesOptions,
  OptionsLanguageWrapper,
  OptionsWrapper,
} from '../request/VehicleRequestPage';
import { FUELS, LANGUAGES } from '@/constants/Constants';

const VehicleRequestDetail = () => {
  const { requestKey } = useParams();
  const { data: vehicleDetail, isLoading } = useGetVehicleRequestDetail(requestKey);
  const detail = vehicleDetail?.data.data;
  const email = detail?.email || ''; // detail이 null이거나 undefined일 때를 대비
  const [emailLocalPart, emailDomainPart] = email.split('@');

  const navigate = useNavigate();

  const handleGoToList = () => {
    navigate('/vehicle-request-list');
  };

  return (
    <PageWrapper>
      {isLoading ? (
        <LoadingComponent />
      ) : detail?.detail?.length === 0 ? (
        <NoResultsComponent />
      ) : (
        <>
          <HeroSection>
            <h1>
              <TranslatedTexts id="hero_section_title" />
            </h1>
            <p>
              <TranslatedTexts id="hero_section_paragraph" />
            </p>
          </HeroSection>
          <Container>
            <FormContainer>
              <Information>
                <TranslatedTexts id="container_information" />
              </Information>

              <Form>
                <FormSection>
                  <TranslatedTexts id="model" />
                  <CustomTextInput value={detail?.model} readOnly />
                </FormSection>

                <FormSection>
                  <TranslatedTexts id="manufacturer" />
                  <CustomTextInput value={detail?.brand_code_nm} readOnly />
                </FormSection>

                <FormSection>
                  <TranslatedTexts id="year" />

                  <SelectorWrapper>
                    <CustomTextInput value={detail?.min_year} readOnly />
                    <div>Min</div>
                    <div>~</div>
                    <CustomTextInput value={detail?.max_year} readOnly />
                    <div>Max</div>
                  </SelectorWrapper>
                </FormSection>

                <FormSection>
                  <OptionLabel>
                    <TranslatedTexts id="fuel" />
                  </OptionLabel>

                  <OptionsWrapper>
                    <Options>
                      {FUELS.map((label) => (
                        <LanguageView key={label}>
                          <CheckboxWrapper>
                            <FormControlLabel
                              control={
                                <CustomCheckBox
                                  checked={detail?.fuel.some(
                                    (f) => f.fuel_nm.toLowerCase() === label.toLowerCase()
                                  )}
                                  readOnly
                                />
                              }
                              label={<TranslatedTexts id={label} />}
                            />
                          </CheckboxWrapper>
                        </LanguageView>
                      ))}
                    </Options>
                  </OptionsWrapper>
                </FormSection>

                <FormSection>
                  <TranslatedTexts id="number_of_purchases" />
                  <SelectorWrapper>
                    <CustomTextInput value={detail?.min_purchase} readOnly />
                    <div>Min</div>
                    <div>~</div>
                    <CustomTextInput value={detail?.max_purchase} readOnly />
                    <div>Max</div>
                  </SelectorWrapper>
                </FormSection>

                <FormSection>
                  <TranslatedTexts id="request" />
                  <RequestInput rows={3} readOnly>
                    <RequestMessage>{detail?.request}</RequestMessage>
                  </RequestInput>
                </FormSection>

                <FormSection>
                  <TranslatedTexts id="requester_name_country" />
                  <InputWrapper>
                    <CustomTextInput value={detail?.requestor_name} readOnly />
                    <Slash>/</Slash>
                    <CustomTextInput value={detail?.requestor_country} readOnly />
                  </InputWrapper>
                </FormSection>
                <FormSection>
                  <TranslatedTexts id="phone_number_to_be_contacted" />
                  <CustomTextInput value={detail?.phone} readOnly />
                </FormSection>

                <FormSection>
                  <TranslatedTexts id="email" />
                  <EmailInputContainer>
                    <CustomTextInput value={emailLocalPart} readOnly />
                    <EmailSymbol>@</EmailSymbol>
                    <CustomTextInput value={emailDomainPart} readOnly />
                  </EmailInputContainer>
                </FormSection>

                <FormSection>
                  <LanguageContainer>
                    <OptionLabel>
                      <TranslatedTexts id="language" />
                    </OptionLabel>
                    <OptionsLanguageWrapper>
                      <LanguagesOptions>
                        {LANGUAGES.map((label) => (
                          <LanguageView key={label}>
                            <FormControlLabel
                              control={
                                <CustomCheckBox
                                  checked={detail?.language.some((l) => l.language_nm === label)}
                                  readOnly
                                  disableRipple
                                />
                              }
                              label={<TranslatedTexts id={label} />}
                            />
                          </LanguageView>
                        ))}
                      </LanguagesOptions>
                    </OptionsLanguageWrapper>
                  </LanguageContainer>
                </FormSection>
                <ListButton variant="contained" onClick={handleGoToList}>
                  <TranslatedTexts id="list" />
                </ListButton>
              </Form>
            </FormContainer>
          </Container>
        </>
      )}
    </PageWrapper>
  );
};

export default VehicleRequestDetail;

export const Form = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '5rem',
  margin: '6rem auto 0',
  width: '100%',
  paddingBottom: '10rem',
}));

const Information = styled.h2(({ theme }) => ({
  fontSize: '2.4rem',
  marginTop: '10rem',
}));

const CustomTextInput = styled.input(({ theme }) => ({
  width: '100%',
  fontSize: '1.6rem',
  padding: '2rem',
  borderRadius: '12px',
  border: `1px solid ${theme.color.grey04}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const PageWrapper = styled.div`
  background-color: #f8f8f8;
  min-height: 100vh;
  padding-bottom: 30px;
  margin-top: 100px;
`;

const Container = styled.div(({ theme }) => ({
  maxWidth: '108rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: ' -130px auto 0',
  boxShadow: '0 0 24px 0 rgba(69, 67, 67, 0.08)',
  borderRadius: '16px',
  marginBottom: '20rem',
  backgroundColor: theme.color.white01,
}));

const HeroSection = styled.div(({ theme }) => ({
  backgroundImage: `url(${BackgroundImg})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: theme.color.white01,
  height: '40rem',
  marginTop: '10rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  'h1, p': {
    position: 'relative',
    zIndex: 1,
  },

  h1: {
    fontSize: '4.9rem',
    margin: '12rem 0 3rem 0',
  },

  p: {
    fontSize: '1.6rem',
  },
}));

export const SelectorWrapper = styled.div(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '10px',
  maxWidth: '60rem',
}));

export const Options = styled(FormGroup)(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
}));

export const RequestInput = styled.div(({ theme }) => ({
  height: '30rem',
  width: '100%',
  fontSize: '1.6rem',
  borderRadius: '12px',
  border: `1px solid ${theme.color.grey04}`,
  padding: '2rem',
  overflowWrap: 'break-word',
  overflow: 'scroll',
}));

export const LanguageView = styled.div(() => ({
  pointerEvents: 'none',
  userSelect: 'none',
}));

const EmailInputContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const EmailSymbol = styled.span({
  margin: '0 8px',
  fontSize: '1.6rem',
});

const RequestMessage = styled.div(({ theme }) => ({
  overflow: 'auto',
  whiteSpace: 'break-spaces',
}));
