import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Controller, useForm } from 'react-hook-form';
import ConfirmationModal from '@/components/ui/modal/Modal';
import {
  Description,
  InputWrapper,
  LanguageContainer,
  OptionLabel,
  SelectWrapper,
  Slash,
  Subtitle,
  Title,
} from '../user/Membership';
import { TranslatedTexts } from '@/components/ui/texts';
import { CustomCheckBox, CustomSelect, CustomTextInput } from '@/components/ui/input';
import { FormControlLabel, FormGroup } from '@mui/material';
import { ErrorMessage } from '@/components/ui/input/CustomTextInput';
import CountrySelector from '@/components/ui/selectbox/CountrySelector';
import { addCommaToNumber, useTranslateObjectText } from '@/utils/CustomFunctions';
import { ButtonGroup, PreviousButton, RequestButton } from './EstimateRequestPage';
import { useFetchBrands } from '@/hooks/useCarData';
import Toggle from '@/components/ui/toggle/Toggle';
import { useIntl } from 'react-intl';
import { zodResolver } from '@hookform/resolvers/zod';
import { usePostVehicle } from '@/hooks/useApiHandlers';
import { API_SUCCESS } from '@/constants/apiResponseCode';
import { useNavigate } from 'react-router-dom';
import { CustomMiniYearSelect } from '@/components/ui/input/CustomMiniSelect';
import { AlertComponent } from '@/components/ui/alert';
import useAlert from '@/hooks/useAlert';
import { zodVehicleRequest } from '@/lib/zod/zodValidation';
import { FUELS, LANGUAGES } from '@/constants/Constants';
import { useIsDirty } from '@/context/IsDirtyContext';
import { usePreventLeave } from '@/hooks/usePreventLeave';

const VehicleRequestPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: brands } = useFetchBrands();
  const intl = useIntl();
  const { showAlert, handleShowAlert, alertMessage, handleCloseAlert, alertSeverity } = useAlert();
  const { setIsDirty } = useIsDirty();
  const { handleNavigate } = usePreventLeave();

  const EMAIL_DOMAINS = [
    'gmail.com',
    'naver.com',
    'hanmail.net',
    useTranslateObjectText('direct_input'),
  ];

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isDirty: formIsDirty },
    setValue,
  } = useForm({
    mode: 'onChange',
    resolver: zodResolver(zodVehicleRequest),

    defaultValues: {
      model: '',
      brand_code: '',
      min_year: '',
      max_year: '',
      fuel: [],
      min_purchase: '',
      max_purchase: '',
      request: '',
      requestor_name: '',
      requestor_country: '',
      phone: '',
      email: '',
      domain: '',
      domainText: '',
      language: [],
    },
  });

  // formState의 isDirty가 변경될 때마다 전역 상태를 업데이트
  useEffect(() => {
    setIsDirty(formIsDirty);
  }, [formIsDirty, setIsDirty]);

  const brandOptions = brands ? brands.map((brand) => brand.brandName) : [];

  const handleAmountChange = (fieldName) => (e) => {
    const value = e.target.value.replace(/[^\d]/g, '');
    setValue(fieldName, addCommaToNumber(value));
  };

  const handleToggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const { mutate: postVehicleForm } = usePostVehicle();

  const onSubmit = (data) => {
    const selectedBrand = brands.find((brand) => brand.brandName === data.brand_code);
    const finalDomain = data?.domainText ? data?.domainText : data?.domain;
    const formattedData = {
      ...data,
      fuel: data.fuel.map(String),
      language: data.language.map(String), // 문자열변환
      min_purchase: parseInt(data.min_purchase.replace(/,/g, '')),
      max_purchase: parseInt(data.max_purchase.replace(/,/g, '')),
      email: data?.email + '@' + finalDomain,
      // brand_code: selectedBrand ? selectedBrand.brandCode : data.brand_code, // 브랜드 코드로 변환
      brand_code: selectedBrand ? String(selectedBrand.brandCode) : String(data.brand_code), // 문자열로 변환
    };

    postVehicleForm(formattedData, {
      onSuccess: (response) => {
        if (response.data.code === API_SUCCESS) {
          handleToggleModal();
          handleShowAlert('request_successful', 'success');
          setTimeout(() => {
            // navigate('/vehicle-request-list');
            handleNavigate('/vehicle-request-list', { state: { autoNavigate: true } });
          }, 1000);
        }
      },
      onError: (error) => {
        console.error(' error:', error);
        handleShowAlert('request_failed', 'error');
      },
    });
  };

  return (
    <Container>
      <FormContainer>
        <Title>{useTranslateObjectText('vehicle_request_title')}</Title>

        <Subtitle>{useTranslateObjectText('vehicle_request_subtitle')}</Subtitle>

        <Description>{useTranslateObjectText('vehicle_request_description_1')}</Description>
        <Description>{useTranslateObjectText('vehicle_request_description_2')}</Description>

        <Form onSubmit={handleSubmit(handleToggleModal)}>
          {/* 토글 */}
          <FormSection>
            <Toggle />
          </FormSection>

          {/* 모델인풋 텍스트 */}
          <FormSection>
            <TranslatedTexts id="model" />
            <CustomTextInput
              register={register}
              name="model"
              placeholder={useTranslateObjectText('model_name_placeholder')}
              errors={errors}
            />
          </FormSection>

          {/* 제조사 셀렉트 */}
          <FormSection>
            <TranslatedTexts id="manufacturer" />
            <CustomSelect
              register={register}
              name="brand_code"
              arr={brandOptions}
              errors={errors}
              emptyText={<TranslatedTexts id="manufacturer_name_placeholder" />}
            />
          </FormSection>

          {/* 년도선택 셀렉트 (datePicker) */}
          <FormSection>
            <TranslatedTexts id="year" />
            <SelectorWrapper>
              <SelectorBox>
                <YearBox>
                  <CustomMiniYearSelect
                    control={control}
                    name="min_year"
                    placeholder="2000"
                    isYearPicker
                    maxDate={
                      watch('max_year') ? new Date(Number(watch('max_year')), 0, 1) : new Date()
                    }
                  />
                  {errors?.min_year && (
                    <ErrorMessage>
                      <TranslatedTexts id={errors.min_year.message} />
                    </ErrorMessage>
                  )}
                </YearBox>

                <Label>
                  <TranslatedTexts id="min_placeholder" />
                </Label>
                <Separator>~</Separator>

                <YearBox>
                  <CustomMiniYearSelect
                    control={control}
                    name="max_year"
                    placeholder="2024"
                    isYearPicker
                    minDate={
                      watch('min_year') ? new Date(Number(watch('min_year')), 0, 1) : undefined
                    }
                  />
                  {errors?.max_year && (
                    <ErrorMessage>
                      <TranslatedTexts id={errors.max_year.message} />
                    </ErrorMessage>
                  )}
                </YearBox>

                <Label>
                  <TranslatedTexts id="max_placeholder" />
                </Label>
              </SelectorBox>
            </SelectorWrapper>
          </FormSection>

          {/* fuel 체크박스 */}
          <FormSection>
            <OptionLabel>
              <TranslatedTexts id="fuel" />
              <span>
                (<TranslatedTexts id="Check all that apply" />)
              </span>
            </OptionLabel>

            <OptionsWrapper>
              <Options>
                {FUELS.map((label, index) => (
                  <CheckboxWrapper key={label}>
                    <Controller
                      name="fuel"
                      control={control}
                      render={({ field: { onChange, value, name } }) => (
                        <FormControlLabel
                          control={
                            <CustomCheckBox
                              checked={value?.includes(index) || false}
                              onChange={onChange}
                              index={index}
                              watch={watch}
                              name={name}
                            />
                          }
                          label={<TranslatedTexts id={label} />}
                        />
                      )}
                    />
                  </CheckboxWrapper>
                ))}
              </Options>

              <div>
                {errors?.fuel && (
                  <ErrorMessage>
                    <TranslatedTexts id={errors.fuel.message} />
                  </ErrorMessage>
                )}
              </div>
            </OptionsWrapper>
          </FormSection>

          {/* 부품개수 인풋  */}
          <FormSection>
            <TranslatedTexts id="number_of_purchases" />

            <SelectorWrapper>
              <CustomTextInputWapper>
                <CustomTextInput
                  register={register}
                  name="min_purchase"
                  type="text"
                  placeholder={useTranslateObjectText('min_placeholder')}
                  errors={errors}
                  onChange={handleAmountChange('min_purchase')}
                />
              </CustomTextInputWapper>

              <Label>
                <TranslatedTexts id="min_placeholder" />
              </Label>

              <Separator>~</Separator>

              <CustomTextInput
                register={register}
                name="max_purchase"
                type="text"
                placeholder={useTranslateObjectText('max_placeholder')}
                errors={errors}
                onChange={handleAmountChange('max_purchase')}
              />

              <Label>
                <TranslatedTexts id="max_placeholder" />
              </Label>
            </SelectorWrapper>
          </FormSection>
          {/* 요청넣는곳 */}
          <FormSection>
            <TranslatedTexts id="request" />

            <CustomTextInput
              register={register}
              name="request"
              placeholder={useTranslateObjectText('request_placeholder')}
              errors={errors}
              customHeight="30rem"
              multiline
            />
          </FormSection>

          {/* 이름, 국가선택 */}
          <FormSection>
            <TranslatedTexts id="requester_name_country" />
            <InputWrapper>
              <CustomTextInput
                register={register}
                name="requestor_name"
                placeholder={useTranslateObjectText('name_placeholder')}
                errors={errors}
              />
              <Slash>/</Slash>
              {/* 국가 선택 라이브러리 */}

              <SelectWrapper>
                <Controller
                  name="requestor_country"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <CountrySelector onChange={onChange} onBlur={onBlur} value={value} />
                  )}
                  control={control}
                />

                {errors?.requestor_country && (
                  <ErrorMessage>
                    <TranslatedTexts id={errors?.requestor_country?.message} />
                  </ErrorMessage>
                )}
              </SelectWrapper>
            </InputWrapper>
          </FormSection>

          {/* 핸드폰번호 입력 */}
          <FormSection>
            <TranslatedTexts id="phone_number_to_be_contacted" />
            <CustomTextInput
              register={register}
              name="phone"
              type="number"
              placeholder={useTranslateObjectText('phone_number_placeholder')}
              errors={errors}
              handlePhoneInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, '');
              }}
            />
          </FormSection>

          {/* 이메일 */}
          <FormSection>
            <Label>
              <TranslatedTexts id="email" />
            </Label>

            <InputWrapper>
              <CustomTextInput
                register={register}
                name="email"
                placeholder={useTranslateObjectText('email_placeholder')}
                errors={errors}
              />

              <Slash>@</Slash>

              <CustomSelect
                register={register}
                name="domain"
                arr={EMAIL_DOMAINS}
                errors={errors}
                emptyText={<TranslatedTexts id="select_email_address" />}
              />
            </InputWrapper>

            <div>
              {watch('domain') === useTranslateObjectText('direct_input') && (
                <CustomTextInput
                  register={register}
                  name="domainText"
                  placeholder={intl.formatMessage({
                    id: 'direct_input_placeholder',
                  })}
                  errors={errors}
                />
              )}
            </div>
          </FormSection>

          {/* 랭귀지 체크박스  */}
          <FormSection>
            <LanguageContainer>
              <OptionLabel>
                <TranslatedTexts id="language" />
                <span>
                  (<TranslatedTexts id="language_label_description" />)
                </span>
              </OptionLabel>

              <OptionsLanguageWrapper>
                <LanguagesOptions>
                  {LANGUAGES.map((label, index) => (
                    <Controller
                      key={label}
                      name="language"
                      control={control}
                      render={({ field: { onChange, value, name } }) => (
                        <FormControlLabel
                          control={
                            <CustomCheckBox
                              checked={value?.includes(index) || false}
                              onChange={onChange}
                              index={index}
                              watch={watch}
                              name={name}
                            />
                          }
                          label={<TranslatedTexts id={label} />}
                        />
                      )}
                    />
                  ))}
                </LanguagesOptions>

                {errors?.language && (
                  <ErrorMessage>
                    <TranslatedTexts id={errors?.language?.message} />
                  </ErrorMessage>
                )}
              </OptionsLanguageWrapper>
            </LanguageContainer>
          </FormSection>

          <FooterText>
            <TranslatedTexts id="vehicle_quote_message" />
          </FooterText>

          {/* 폼제출하는 버튼 */}
          <ButtonGroup>
            <PreviousButton variant="outlined" type="button" onClick={() => handleNavigate(-1)}>
              <TranslatedTexts id="previous_button" />
            </PreviousButton>

            <RequestButton variant="contained" type="submit">
              <TranslatedTexts id="request_button" />
            </RequestButton>
          </ButtonGroup>
        </Form>
      </FormContainer>

      <ConfirmationModal
        open={isModalOpen}
        handleClose={handleToggleModal}
        handleConfirm={handleSubmit(onSubmit)}
      />

      <AlertComponent
        open={showAlert}
        message={alertMessage}
        severity={alertSeverity}
        onClose={handleCloseAlert}
      />

      {/* 페이지 이탈 경고 모달 */}
      {/* <ConfirmationModal
        open={showModal}
        handleClose={handleCancel}
        handleConfirm={handleConfirm}
        messageId1="leave_page_warning_1"
        messageId2="leave_page_warning_2"
        confirmButtonId="confirm_button"
      /> */}
    </Container>
  );
};

export default VehicleRequestPage;

export const Container = styled.div(() => ({
  maxWidth: '70.4rem',
  margin: '10rem auto 20rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const FormContainer = styled.div(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const SelectorWrapper = styled.div(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  // alignItems: 'center',
  gap: '10px',
  maxWidth: '60rem',
}));

const Label = styled.div(() => ({
  transform: 'translateY(20%)',
}));

export const Separator = styled.div(() => ({
  transform: 'translateY(20%)',
}));

export const YearBox = styled.div(() => ({
  minHeight: '8rem',
}));

export const Form = styled.form(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '5rem',
  textAlign: 'left',
  margin: '8rem auto 0',

  // maxWidth: '60rem',
  width: '100%',
}));

export const LanguagesOptions = styled(FormGroup)(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

const Options = styled(FormGroup)(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  minHeight: '8rem',
}));

const FooterText = styled.p(({ theme }) => ({
  fontSize: '1.8rem',
  color: theme.color.grey01,
  marginTop: '5rem',
  whiteSpace: 'nowrap',
}));

export const CheckboxWrapper = styled.div(() => ({
  width: '12rem',
}));

const FormSection = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  width: '100%',
  maxWidth: '60rem',
}));

const SelectorBox = styled.div(() => ({
  display: 'flex',
  // alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  minHeight: '7.8rem',
}));

export const OptionsWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '10.2rem',
  width: '100%',
}));

export const OptionsLanguageWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  minHeight: '6rem',
}));

const CustomTextInputWapper = styled.div(() => ({
  display: 'flex',
  width: '100%',
  // minHeight: '9.6rem',
}));
